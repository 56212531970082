<template>
	<div>
		<div class="queryBox mb20 ">
			<div class="mb20">
				<el-button size="mini" type="primary" @click="newCard('new')" class="mr20">新增</el-button>
				<span class="mr20">共{{payTypeInfoData.total_count}}个</span>
				<span class="mr20">在线{{payTypeInfoData.online_count}}个</span>
				<span class="mr20">停用{{payTypeInfoData.disable_count}}个</span>
				<span class="mr20">掉线{{payTypeInfoData.offline_count}}个</span>

			</div>
			<div class="flexX flexcenter">
				<span>在线状态：</span>
				<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择在线状态">
					<el-option v-for="(item,index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<span>银行：</span>
				<el-select size="mini" class="mr20" v-model="bank" clearable placeholder="请选择银行">
					<el-option v-for="(item,index) in bankarray" :key="item.key" :label="item.name" :value="item.key">
					</el-option>
				</el-select>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"
					@click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" border="" stripe style="width: 100%" v-loading="loading"
				size="mini" :show-overflow-tooltip="true">
				<el-table-column type="index" label="序号" align="center" width="120px"></el-table-column>
				<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="card" label="卡号" align="center"></el-table-column>
				<el-table-column label="开户行" align="center">
					<template slot-scope="scope">
						{{bankMap[scope.row.bank]}}
					</template>
				</el-table-column>
				<el-table-column label="在线状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.is_online == 1?'csuccess':scope.row.is_online == 2?'cwarning':'cdanger'">{{scope.row.is_online == 1?'在线':scope.row.is_online == 2?'掉线':'离线'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="启停状态" align="center">
					<template slot-scope="scope">
						<span
							:class="scope.row.state == 0?'csuccess':'cdanger'">{{scope.row.state == 0?'启用':'停用'}}</span>

					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-link type="primary" class="mr20" @click="showInfo(scope.row)">查看</el-link>
						<el-link type="primary" class="mr20" @click="edit(scope.row)">编辑</el-link>
						<br>
						<el-link type="primary" class="mr20" v-if="scope.row.is_online == 1&&scope.row.pay_check == 0" @click="testOrderIn(scope.row)">充值测试</el-link>
						<el-link type="primary" class="mr20" v-if="(scope.row.is_online == 1 && scope.row.repay_check == 0) && ((scope.row.phone_type == 2 && scope.row.telephone_is_online == 1)||scope.row.phone_type == 1)" @click="testOrderOut(scope.row)">代付测试</el-link>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="page" :page-sizes="pageSizesArr"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close" width="30%"
		 :close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="textCenter" v-if="dialogType == 'new'||dialogType == 'show'">
					<div class="flexX flexcenter mb20">
						<span class="dataKey">姓名：</span>
						<span v-if="!isEdit">{{cardInfo.true_name}}</span>
						<el-input size="mini" v-if="isEdit" placeholder="请输入姓名" style="width: 220px;" v-model="cardInfo.true_name"></el-input>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">卡号：</span>
						<span v-if="!isEdit">{{cardInfo.card}}</span>
						<el-input size="mini" v-if="isEdit" placeholder="请输入卡号" style="width: 220px;" v-model="cardInfo.card"></el-input>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">开户行：</span>
						<span v-if="!isEdit">{{bankMap[cardInfo.bank]}}</span>
						<el-select v-if="isEdit" size="mini" class="mr20" v-model="cardInfo.bank" placeholder="请选择银行">
							<el-option v-for="(item,index) in bankarray" :key="item.key" :label="item.name" :value="item.key">
							</el-option>
						</el-select>
						<!-- <el-input v-if="isEdit" placeholder="请输入卡号" style="width: 200px;" v-model="cardInfo.bank"></el-input> -->
					</div>
					<div class="flexX flexcenter mb20" v-if="isEdit">
						<span class="dataKey">银行登录密码：</span>
						<el-input size="mini" placeholder="请输入银行登录密码" style="width: 220px;" v-model="cardInfo.login_password"></el-input>
					</div>
					<div class="flexX flexcenter mb20" v-if="isEdit">
						<span class="dataKey">银行交易密码：</span>
						<el-input size="mini" placeholder="请输入银行交易密码" style="width: 220px;" v-model="cardInfo.pay_password"></el-input>
					</div>
					
					<div class="flexX flexcenter mb20">
						<span class="dataKey">支行信息：</span>
						<span v-if="!isEdit">{{cardInfo.account_branch}}</span>
						<el-input size="mini" v-if="isEdit" placeholder="请输入支行信息" style="width: 220px;" v-model="cardInfo.account_branch"></el-input>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">手机号码：</span>
						<span v-if="!isEdit">{{cardInfo.telephone}}</span>
						<el-input size="mini" v-if="isEdit" placeholder="请输入手机号码" style="width: 220px;" v-model="cardInfo.telephone"></el-input>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">账号地域：</span>
						<el-select :disabled="!isEdit" size="mini" class="mr20" v-model="cardInfo.province" placeholder="请选择省" @change="changePro">
							<el-option v-for="(item,index) in region" :key="item.region_id" :label="item.region_name" :value="item.region_id"></el-option>
						</el-select>
						<el-select :disabled="!isEdit" size="mini" class="mr20" v-model="cardInfo.city" placeholder="请选择市">
							<el-option v-for="(item,index) in city" :key="item.region_id" :label="item.region_name" :value="item.region_id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="textCenter" v-if="dialogType == 'testIn'|| dialogType == 'testOut'">
					<div class="flexX flexcenter mb20">
						<span class="dataKey">姓名：</span>
						<span>{{item.true_name}}</span>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">银行：</span>
						<span>{{bankMap[item.bank]}}</span>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">卡号：</span>
						<span>{{item.card}}</span>
					</div>
					<div v-if="dialogType == 'testOut'" class="flexX flexcenter mb20">
						<span class="dataKey">转出姓名：</span>
						<el-input size="mini"  placeholder="请输入转出姓名" style="width: 220px;" v-model="testOut.name"></el-input>
					</div>
					<div v-if="dialogType == 'testOut'" class="flexX flexcenter mb20">
						<span class="dataKey">转出银行：</span>
						<el-input size="mini"  placeholder="请输入转出银行" style="width: 220px;" v-model="testOut.bank"></el-input>
					</div>
					<div v-if="dialogType == 'testOut'" class="flexX flexcenter mb20">
						<span class="dataKey">转出卡号：</span>
						<el-input size="mini"  placeholder="请输入转出卡号" style="width: 220px;" v-model="testOut.card"></el-input>
					</div>
					<div class="flexX flexcenter mb20">
						<span class="dataKey">金额：</span>
						<span>{{gold}}</span>
					</div>
				</div>
				<div class="textCenter">
					<el-button size="mini" type="primary" @click="sure()" v-if="isEdit || dialogType == 'testIn' || dialogType == 'testOut'">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import region from "@/data";
	export default {
		data() {
			return {
				tableData: [],
				height: 0,
				loading: false,
				disLoading: false,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				state: '',
				stateArr: [{
						key: '',
						val: "全部"
					},
					{
						key: 0,
						val: "离线"
					},
					{
						key: 1,
						val: "在线"
					},
					{
						key: 2,
						val: "掉线"
					},
				],
				payTypeInfoData: {},
				bank: "",
				bankarray: {},
				bankMap:{},
				pay_type: 66,
				item: {},
				cardInfo: {},
				dialogTitle:"",
				dialogType:'',
				dialogVisible:false,
				dialogloading:false,
				closeModal:false,//是否点击弹框 关闭
				isEdit:false,
				region:{},
				city:{},
				gold:0,
				testOut:{
					name:"",
					bank:"",
					card:"",
				}
			};
		},
		created() {
			this.region = region
			if (this.$route.query.state) {
				this.state = Number(this.$route.query.state);
			}
			this.payTypeInfo()
			this.bankList()
			this.cardList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val = Math.floor(((value * 1) * 100).toPrecision(12))
					return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			payTypeInfo(){
				let data = {pay_type:this.pay_type}
				this.$api.payTypeInfo(data).then(res=>{
					if(res.status == 1){
						this.payTypeInfoData = res.data
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			bankList() {
				let self = this
				this.$api.bankList({}).then(res => {
					if (res.status == 1) {
						self.bankarray = res.data
						self.bankarray.forEach(item => {
							self.bankMap[item.key] = item.name
						})						
						self.$forceUpdate()
						// console.log(this.bankMap)
					} else {

					}
				})
			},
			getData() {
				this.cardList()
			},
			cardList() {
				let data = {
					page: this.page,
					size: this.pageSize,
					pay_type: this.pay_type,
					is_online: this.state,
					bank: this.bank
				}
				this.disLoading = true
				this.$api.cardList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.page = 1
				this.cardList()
			},
			handleCurrentChange(val) {
				this.page = val
				this.cardList()
			},
			showInfo(item) {
				this.dialogType = 'show'
				this.dialogTitle = '卡详情'
				this.item = item
				this.getCardInfo()
				this.isEdit = false
				this.dialogVisible = true
			},
			edit(item){
				this.dialogType = 'show'
				this.dialogTitle = '编辑'
				this.item = item
				this.getCardInfo()
				this.isEdit = true
				this.dialogVisible = true
			},
			newCard(dialogType){
				this.dialogType = 'new'
				this.dialogTitle = '新增'
				this.isEdit = true
				this.dialogVisible = true
				this.cardInfo={
					true_name:'',
					card:'',
					bank:'',
					account_branch:'',
					telephone:'',
					province:'',
					city:'',
					login_password:'',
					pay_password:'',
				}
			},
			testOrderIn(item){
				this.dialogType = 'testIn'
				this.dialogTitle = '充值测试'
				this.item = item
				this.dialogVisible = true
				this.checkGold()
			},
			testOrderOut(item){
				this.dialogType = 'testOut'
				this.dialogTitle = '代付测试'
				this.item = item
				this.dialogVisible = true
				this.checkGold()
			},
			getCardInfo() {
				let data = {
					id: this.item.id
				}
				this.$api.cardInfo(data).then(res => {
					if (res.status == 1) {
						this.cardInfo = res.data
						for(let i in this.region){
							if(this.region[i].region_id == this.cardInfo.province){
								console.log(this.region[i])
								this.city=this.region[i].child
								this.$forceUpdate()
							}
						}
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			close(){
				this.dialogVisible = false
				this.dialogloading = false
				this.isEdit = false
				this.dialogTitle = ''
				this.dialogType=""
				this.item = {}
				this.cardInfo={}
				this.testOut.name="";
				this.testOut.bank="";
				this.testOut.card="";
				
			},
			changePro(e){
				for(let i in this.region){
					if(this.region[i].region_id == e){
						console.log(this.region[i])
						this.city=this.region[i].child
						this.cardInfo.city=""
						this.$forceUpdate()
					}
				}
			},
			sure(){
				if(this.dialogType == 'show'){
					if (isEmpty(this.cardInfo.true_name, "请输入姓名")) {
						return
					}
					if (isEmpty(this.cardInfo.card, "请输入卡号")) {
						return
					}
					if (isEmpty(this.cardInfo.bank, "请选择银行")) {
						return
					}
					// if (isEmpty(this.cardInfo.login_password, "登录密码不能为空")) {
					// 	return
					// }
					// if (isEmpty(this.cardInfo.pay_password, "交易密码不能为空")) {
					// 	return
					// }
					if (isEmpty(this.cardInfo.telephone, "请输入电话号码")) {
						return
					}
					if (isEmpty(this.cardInfo.province, "请选择省")) {
						return
					}
					if (isEmpty(this.cardInfo.city, "请选择市")) {
						return
					}
					let data = {
						id: this.cardInfo.id,
						name: this.cardInfo.true_name,
						card: this.cardInfo.card,
						bank: this.cardInfo.bank,
						account_branch: this.cardInfo.account_branch,
						telephone: this.cardInfo.telephone,
						province: this.cardInfo.province,
						city: this.cardInfo.city,
						login_password: this.cardInfo.login_password,
						pay_password: this.cardInfo.pay_password
					}
					this.$api.editCard(data).then(res=>{
						if(res.status == 1){
							this.close()
							this.cardList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				if(this.dialogType == 'new'){
					
					if (isEmpty(this.cardInfo.true_name, "请输入姓名")) {
						return
					}
					if (isEmpty(this.cardInfo.card, "请输入卡号")) {
						return
					}
					if (isEmpty(this.cardInfo.bank, "请选择银行")) {
						return
					}
					if (isEmpty(this.cardInfo.login_password, "登录密码不能为空")) {
						return
					}
					if (isEmpty(this.cardInfo.pay_password, "交易密码不能为空")) {
						return
					}
					if (isEmpty(this.cardInfo.telephone, "请输入电话号码")) {
						return
					}
					if (isEmpty(this.cardInfo.province, "请选择省")) {
						return
					}
					if (isEmpty(this.cardInfo.city, "请选择市")) {
						return
					}
					let data = {
						name: this.cardInfo.true_name,
						card: this.cardInfo.card,
						bank: this.cardInfo.bank,
						account_branch: this.cardInfo.account_branch,
						telephone: this.cardInfo.telephone,
						province: this.cardInfo.province,
						city: this.cardInfo.city,
						login_password: this.cardInfo.login_password,
						pay_password: this.cardInfo.pay_password,
						state:1
					}
					this.$api.addCard(data).then(res=>{
						if(res.status == 1){
							this.close()
							this.cardList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				if(this.dialogType == 'testIn'){
					let data = {
						card_id : this.item.id,
						pay_type:this.item.pay_type
						
					}
					this.$api.addOrderIn(data).then(res=>{
						if(res.status == 1){
							this.close()
							this.cardList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				if(this.dialogType == 'testOut'){
					if(isEmpty(this.testOut.name,"请输入转出姓名")){
						return
					}
					if(isEmpty(this.testOut.bank,"请输入转出银行")){
						return
					}
					if(isEmpty(this.testOut.card,"请输入转出卡号")){
						return
					}
					let data = {
						card_id: this.item.id,
						pay_type: this.item.pay_type,
						name: this.testOut.name,
						bank: this.testOut.bank,
						card: this.testOut.card
					};
					this.$api.addOrderOut(data).then(res=>{
						if(res.status == 1){
							this.close()
							this.cardList()
							this.$message({
								type: 'success',
								message: res.msg
							})
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				console.log(this.cardInfo)
			},
			checkGold(){
				this.$api.checkGold({}).then(res=>{
					if(res.status == 1){
						this.gold = res.data 
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}

		},
		watch: {
			
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
				})
			}
		},

	};
</script>

<style scoped="scoped">
	.dataKey{
		display: inline-block;
		flex: 0 0 100px;
		text-align: left;
	}
</style>
