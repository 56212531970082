const region = {
	"2": {
		"region_id": "2",
		"region_name": "北京市",
		"child": {
			"2001": {
				"region_id": "2001",
				"region_name": "北京市",
				"child": {
					"2001001": {
						"region_id": "2001001",
						"region_name": "东城区"
					},
					"2001002": {
						"region_id": "2001002",
						"region_name": "西城区"
					},
					"2001003": {
						"region_id": "2001003",
						"region_name": "崇文区"
					},
					"2001004": {
						"region_id": "2001004",
						"region_name": "宣武区"
					},
					"2001005": {
						"region_id": "2001005",
						"region_name": "朝阳区"
					},
					"2001006": {
						"region_id": "2001006",
						"region_name": "丰台区"
					},
					"2001007": {
						"region_id": "2001007",
						"region_name": "石景山区"
					},
					"2001008": {
						"region_id": "2001008",
						"region_name": "海淀区"
					},
					"2001009": {
						"region_id": "2001009",
						"region_name": "门头沟区"
					},
					"2001010": {
						"region_id": "2001010",
						"region_name": "房山区"
					},
					"2001011": {
						"region_id": "2001011",
						"region_name": "通州区"
					},
					"2001012": {
						"region_id": "2001012",
						"region_name": "顺义区"
					},
					"2001013": {
						"region_id": "2001013",
						"region_name": "昌平区"
					},
					"2001014": {
						"region_id": "2001014",
						"region_name": "大兴区"
					},
					"2001015": {
						"region_id": "2001015",
						"region_name": "平谷区"
					},
					"2001016": {
						"region_id": "2001016",
						"region_name": "怀柔区"
					},
					"2001017": {
						"region_id": "2001017",
						"region_name": "密云县"
					},
					"2001018": {
						"region_id": "2001018",
						"region_name": "延庆县"
					}
				}
			}
		}
	},
	"3": {
		"region_id": "3",
		"region_name": "天津市",
		"child": {
			"3001": {
				"region_id": "3001",
				"region_name": "天津市",
				"child": {
					"3001001": {
						"region_id": "3001001",
						"region_name": "和平区"
					},
					"3001002": {
						"region_id": "3001002",
						"region_name": "河东区"
					},
					"3001003": {
						"region_id": "3001003",
						"region_name": "河西区"
					},
					"3001004": {
						"region_id": "3001004",
						"region_name": "南开区"
					},
					"3001005": {
						"region_id": "3001005",
						"region_name": "河北区"
					},
					"3001006": {
						"region_id": "3001006",
						"region_name": "红桥区"
					},
					"3001007": {
						"region_id": "3001007",
						"region_name": "塘沽区"
					},
					"3001008": {
						"region_id": "3001008",
						"region_name": "汉沽区"
					},
					"3001009": {
						"region_id": "3001009",
						"region_name": "大港区"
					},
					"3001010": {
						"region_id": "3001010",
						"region_name": "东丽区"
					},
					"3001011": {
						"region_id": "3001011",
						"region_name": "西青区"
					},
					"3001012": {
						"region_id": "3001012",
						"region_name": "津南区"
					},
					"3001013": {
						"region_id": "3001013",
						"region_name": "北辰区"
					},
					"3001014": {
						"region_id": "3001014",
						"region_name": "武清区"
					},
					"3001015": {
						"region_id": "3001015",
						"region_name": "宝坻区"
					},
					"3001016": {
						"region_id": "3001016",
						"region_name": "宁河县"
					},
					"3001017": {
						"region_id": "3001017",
						"region_name": "静海县"
					},
					"3001018": {
						"region_id": "3001018",
						"region_name": "蓟县"
					}
				}
			}
		}
	},
	"4": {
		"region_id": "4",
		"region_name": "河北省",
		"child": {
			"4001": {
				"region_id": "4001",
				"region_name": "石家庄市",
				"child": {
					"4001001": {
						"region_id": "4001001",
						"region_name": "长安区"
					},
					"4001002": {
						"region_id": "4001002",
						"region_name": "桥东区"
					},
					"4001003": {
						"region_id": "4001003",
						"region_name": "桥西区"
					},
					"4001004": {
						"region_id": "4001004",
						"region_name": "新华区"
					},
					"4001005": {
						"region_id": "4001005",
						"region_name": "郊区"
					},
					"4001006": {
						"region_id": "4001006",
						"region_name": "井陉矿区"
					},
					"4001007": {
						"region_id": "4001007",
						"region_name": "井陉县"
					},
					"4001008": {
						"region_id": "4001008",
						"region_name": "正定县"
					},
					"4001009": {
						"region_id": "4001009",
						"region_name": "栾城县"
					},
					"4001010": {
						"region_id": "4001010",
						"region_name": "行唐县"
					},
					"4001011": {
						"region_id": "4001011",
						"region_name": "灵寿县"
					},
					"4001012": {
						"region_id": "4001012",
						"region_name": "高邑县"
					},
					"4001013": {
						"region_id": "4001013",
						"region_name": "深泽县"
					},
					"4001014": {
						"region_id": "4001014",
						"region_name": "赞皇县"
					},
					"4001015": {
						"region_id": "4001015",
						"region_name": "无极县"
					},
					"4001016": {
						"region_id": "4001016",
						"region_name": "平山县"
					},
					"4001017": {
						"region_id": "4001017",
						"region_name": "元氏县"
					},
					"4001018": {
						"region_id": "4001018",
						"region_name": "赵县"
					},
					"4001019": {
						"region_id": "4001019",
						"region_name": "辛集市"
					},
					"4001020": {
						"region_id": "4001020",
						"region_name": "藁城市"
					},
					"4001021": {
						"region_id": "4001021",
						"region_name": "晋州市"
					},
					"4001022": {
						"region_id": "4001022",
						"region_name": "新乐市"
					},
					"4001023": {
						"region_id": "4001023",
						"region_name": "鹿泉市"
					}
				}
			},
			"4002": {
				"region_id": "4002",
				"region_name": "唐山市",
				"child": {
					"4002001": {
						"region_id": "4002001",
						"region_name": "路南区"
					},
					"4002002": {
						"region_id": "4002002",
						"region_name": "路北区"
					},
					"4002003": {
						"region_id": "4002003",
						"region_name": "古冶区"
					},
					"4002004": {
						"region_id": "4002004",
						"region_name": "开平区"
					},
					"4002005": {
						"region_id": "4002005",
						"region_name": "新区"
					},
					"4002006": {
						"region_id": "4002006",
						"region_name": "丰润县"
					},
					"4002007": {
						"region_id": "4002007",
						"region_name": "滦县"
					},
					"4002008": {
						"region_id": "4002008",
						"region_name": "滦南县"
					},
					"4002009": {
						"region_id": "4002009",
						"region_name": "乐亭县"
					},
					"4002010": {
						"region_id": "4002010",
						"region_name": "迁西县"
					},
					"4002011": {
						"region_id": "4002011",
						"region_name": "玉田县"
					},
					"4002012": {
						"region_id": "4002012",
						"region_name": "唐海县"
					},
					"4002013": {
						"region_id": "4002013",
						"region_name": "遵化市"
					},
					"4002014": {
						"region_id": "4002014",
						"region_name": "丰南市"
					},
					"4002015": {
						"region_id": "4002015",
						"region_name": "迁安市"
					}
				}
			},
			"4003": {
				"region_id": "4003",
				"region_name": "秦皇岛市",
				"child": {
					"4003001": {
						"region_id": "4003001",
						"region_name": "海港区"
					},
					"4003002": {
						"region_id": "4003002",
						"region_name": "山海关区"
					},
					"4003003": {
						"region_id": "4003003",
						"region_name": "北戴河区"
					},
					"4003004": {
						"region_id": "4003004",
						"region_name": "青龙满族自治县"
					},
					"4003005": {
						"region_id": "4003005",
						"region_name": "昌黎县"
					},
					"4003006": {
						"region_id": "4003006",
						"region_name": "抚宁县"
					},
					"4003007": {
						"region_id": "4003007",
						"region_name": "卢龙县"
					}
				}
			},
			"4004": {
				"region_id": "4004",
				"region_name": "邯郸市",
				"child": {
					"4004001": {
						"region_id": "4004001",
						"region_name": "邯山区"
					},
					"4004002": {
						"region_id": "4004002",
						"region_name": "丛台区"
					},
					"4004003": {
						"region_id": "4004003",
						"region_name": "复兴区"
					},
					"4004004": {
						"region_id": "4004004",
						"region_name": "峰峰矿区"
					},
					"4004005": {
						"region_id": "4004005",
						"region_name": "邯郸县"
					},
					"4004006": {
						"region_id": "4004006",
						"region_name": "临漳县"
					},
					"4004007": {
						"region_id": "4004007",
						"region_name": "成安县"
					},
					"4004008": {
						"region_id": "4004008",
						"region_name": "大名县"
					},
					"4004009": {
						"region_id": "4004009",
						"region_name": "涉县"
					},
					"4004010": {
						"region_id": "4004010",
						"region_name": "磁县"
					},
					"4004011": {
						"region_id": "4004011",
						"region_name": "肥乡县"
					},
					"4004012": {
						"region_id": "4004012",
						"region_name": "永年县"
					},
					"4004013": {
						"region_id": "4004013",
						"region_name": "邱县"
					},
					"4004014": {
						"region_id": "4004014",
						"region_name": "鸡泽县"
					},
					"4004015": {
						"region_id": "4004015",
						"region_name": "广平县"
					},
					"4004016": {
						"region_id": "4004016",
						"region_name": "馆陶县"
					},
					"4004017": {
						"region_id": "4004017",
						"region_name": "魏县"
					},
					"4004018": {
						"region_id": "4004018",
						"region_name": "曲周县"
					},
					"4004019": {
						"region_id": "4004019",
						"region_name": "武安市"
					}
				}
			},
			"4005": {
				"region_id": "4005",
				"region_name": "邢台市",
				"child": {
					"4005001": {
						"region_id": "4005001",
						"region_name": "桥东区"
					},
					"4005002": {
						"region_id": "4005002",
						"region_name": "桥西区"
					},
					"4005003": {
						"region_id": "4005003",
						"region_name": "邢台县"
					},
					"4005004": {
						"region_id": "4005004",
						"region_name": "临城县"
					},
					"4005005": {
						"region_id": "4005005",
						"region_name": "内丘县"
					},
					"4005006": {
						"region_id": "4005006",
						"region_name": "柏乡县"
					},
					"4005007": {
						"region_id": "4005007",
						"region_name": "隆尧县"
					},
					"4005008": {
						"region_id": "4005008",
						"region_name": "任县"
					},
					"4005009": {
						"region_id": "4005009",
						"region_name": "南和县"
					},
					"4005010": {
						"region_id": "4005010",
						"region_name": "宁晋县"
					},
					"4005011": {
						"region_id": "4005011",
						"region_name": "巨鹿县"
					},
					"4005012": {
						"region_id": "4005012",
						"region_name": "新河县"
					},
					"4005013": {
						"region_id": "4005013",
						"region_name": "广宗县"
					},
					"4005014": {
						"region_id": "4005014",
						"region_name": "平乡县"
					},
					"4005015": {
						"region_id": "4005015",
						"region_name": "威县"
					},
					"4005016": {
						"region_id": "4005016",
						"region_name": "清河县"
					},
					"4005017": {
						"region_id": "4005017",
						"region_name": "临西县"
					},
					"4005018": {
						"region_id": "4005018",
						"region_name": "南宫市"
					},
					"4005019": {
						"region_id": "4005019",
						"region_name": "沙河市"
					}
				}
			},
			"4006": {
				"region_id": "4006",
				"region_name": "保定市",
				"child": {
					"4006001": {
						"region_id": "4006001",
						"region_name": "新市区"
					},
					"4006002": {
						"region_id": "4006002",
						"region_name": "北市区"
					},
					"4006003": {
						"region_id": "4006003",
						"region_name": "南市区"
					},
					"4006004": {
						"region_id": "4006004",
						"region_name": "满城县"
					},
					"4006005": {
						"region_id": "4006005",
						"region_name": "清苑县"
					},
					"4006006": {
						"region_id": "4006006",
						"region_name": "涞水县"
					},
					"4006007": {
						"region_id": "4006007",
						"region_name": "阜平县"
					},
					"4006008": {
						"region_id": "4006008",
						"region_name": "徐水县"
					},
					"4006009": {
						"region_id": "4006009",
						"region_name": "定兴县"
					},
					"4006010": {
						"region_id": "4006010",
						"region_name": "唐县"
					},
					"4006011": {
						"region_id": "4006011",
						"region_name": "高阳县"
					},
					"4006012": {
						"region_id": "4006012",
						"region_name": "容城县"
					},
					"4006013": {
						"region_id": "4006013",
						"region_name": "涞源县"
					},
					"4006014": {
						"region_id": "4006014",
						"region_name": "望都县"
					},
					"4006015": {
						"region_id": "4006015",
						"region_name": "安新县"
					},
					"4006016": {
						"region_id": "4006016",
						"region_name": "易县"
					},
					"4006017": {
						"region_id": "4006017",
						"region_name": "曲阳县"
					},
					"4006018": {
						"region_id": "4006018",
						"region_name": "蠡县"
					},
					"4006019": {
						"region_id": "4006019",
						"region_name": "顺平县"
					},
					"4006020": {
						"region_id": "4006020",
						"region_name": "博野县"
					},
					"4006021": {
						"region_id": "4006021",
						"region_name": "雄县"
					},
					"4006022": {
						"region_id": "4006022",
						"region_name": "涿州市"
					},
					"4006023": {
						"region_id": "4006023",
						"region_name": "定州市"
					},
					"4006024": {
						"region_id": "4006024",
						"region_name": "安国市"
					},
					"4006025": {
						"region_id": "4006025",
						"region_name": "高碑店市"
					}
				}
			},
			"4007": {
				"region_id": "4007",
				"region_name": "张家口市",
				"child": {
					"4007001": {
						"region_id": "4007001",
						"region_name": "桥东区"
					},
					"4007002": {
						"region_id": "4007002",
						"region_name": "桥西区"
					},
					"4007003": {
						"region_id": "4007003",
						"region_name": "宣化区"
					},
					"4007004": {
						"region_id": "4007004",
						"region_name": "下花园区"
					},
					"4007005": {
						"region_id": "4007005",
						"region_name": "宣化县"
					},
					"4007006": {
						"region_id": "4007006",
						"region_name": "张北县"
					},
					"4007007": {
						"region_id": "4007007",
						"region_name": "康保县"
					},
					"4007008": {
						"region_id": "4007008",
						"region_name": "沽源县"
					},
					"4007009": {
						"region_id": "4007009",
						"region_name": "尚义县"
					},
					"4007010": {
						"region_id": "4007010",
						"region_name": "蔚县"
					},
					"4007011": {
						"region_id": "4007011",
						"region_name": "阳原县"
					},
					"4007012": {
						"region_id": "4007012",
						"region_name": "怀安县"
					},
					"4007013": {
						"region_id": "4007013",
						"region_name": "万全县"
					},
					"4007014": {
						"region_id": "4007014",
						"region_name": "怀来县"
					},
					"4007015": {
						"region_id": "4007015",
						"region_name": "涿鹿县"
					},
					"4007016": {
						"region_id": "4007016",
						"region_name": "赤城县"
					},
					"4007017": {
						"region_id": "4007017",
						"region_name": "崇礼县"
					}
				}
			},
			"4008": {
				"region_id": "4008",
				"region_name": "承德市",
				"child": {
					"4008001": {
						"region_id": "4008001",
						"region_name": "双桥区"
					},
					"4008002": {
						"region_id": "4008002",
						"region_name": "双滦区"
					},
					"4008003": {
						"region_id": "4008003",
						"region_name": "鹰手营子矿区"
					},
					"4008004": {
						"region_id": "4008004",
						"region_name": "承德县"
					},
					"4008005": {
						"region_id": "4008005",
						"region_name": "兴隆县"
					},
					"4008006": {
						"region_id": "4008006",
						"region_name": "平泉县"
					},
					"4008007": {
						"region_id": "4008007",
						"region_name": "滦平县"
					},
					"4008008": {
						"region_id": "4008008",
						"region_name": "隆化县"
					},
					"4008009": {
						"region_id": "4008009",
						"region_name": "丰宁满族自治县"
					},
					"4008010": {
						"region_id": "4008010",
						"region_name": "宽城满族自治县"
					},
					"4008011": {
						"region_id": "4008011",
						"region_name": "围场满族蒙古族自治县"
					}
				}
			},
			"4009": {
				"region_id": "4009",
				"region_name": "沧州市",
				"child": {
					"4009001": {
						"region_id": "4009001",
						"region_name": "新华区"
					},
					"4009002": {
						"region_id": "4009002",
						"region_name": "运河区"
					},
					"4009003": {
						"region_id": "4009003",
						"region_name": "沧县"
					},
					"4009004": {
						"region_id": "4009004",
						"region_name": "青县"
					},
					"4009005": {
						"region_id": "4009005",
						"region_name": "东光县"
					},
					"4009006": {
						"region_id": "4009006",
						"region_name": "海兴县"
					},
					"4009007": {
						"region_id": "4009007",
						"region_name": "盐山县"
					},
					"4009008": {
						"region_id": "4009008",
						"region_name": "肃宁县"
					},
					"4009009": {
						"region_id": "4009009",
						"region_name": "南皮县"
					},
					"4009010": {
						"region_id": "4009010",
						"region_name": "吴桥县"
					},
					"4009011": {
						"region_id": "4009011",
						"region_name": "献县"
					},
					"4009012": {
						"region_id": "4009012",
						"region_name": "孟村回族自治县"
					},
					"4009013": {
						"region_id": "4009013",
						"region_name": "泊头市"
					},
					"4009014": {
						"region_id": "4009014",
						"region_name": "任丘市"
					},
					"4009015": {
						"region_id": "4009015",
						"region_name": "黄骅市"
					},
					"4009016": {
						"region_id": "4009016",
						"region_name": "河间市"
					}
				}
			},
			"4010": {
				"region_id": "4010",
				"region_name": "廊坊市",
				"child": {
					"4010001": {
						"region_id": "4010001",
						"region_name": "安次区"
					},
					"4010002": {
						"region_id": "4010002",
						"region_name": "固安县"
					},
					"4010003": {
						"region_id": "4010003",
						"region_name": "永清县"
					},
					"4010004": {
						"region_id": "4010004",
						"region_name": "香河县"
					},
					"4010005": {
						"region_id": "4010005",
						"region_name": "大城县"
					},
					"4010006": {
						"region_id": "4010006",
						"region_name": "文安县"
					},
					"4010007": {
						"region_id": "4010007",
						"region_name": "大厂回族自治县"
					},
					"4010008": {
						"region_id": "4010008",
						"region_name": "霸州市"
					},
					"4010009": {
						"region_id": "4010009",
						"region_name": "三河市"
					}
				}
			},
			"4011": {
				"region_id": "4011",
				"region_name": "衡水市",
				"child": {
					"4011001": {
						"region_id": "4011001",
						"region_name": "桃城区"
					},
					"4011002": {
						"region_id": "4011002",
						"region_name": "枣强县"
					},
					"4011003": {
						"region_id": "4011003",
						"region_name": "武邑县"
					},
					"4011004": {
						"region_id": "4011004",
						"region_name": "武强县"
					},
					"4011005": {
						"region_id": "4011005",
						"region_name": "饶阳县"
					},
					"4011006": {
						"region_id": "4011006",
						"region_name": "安平县"
					},
					"4011007": {
						"region_id": "4011007",
						"region_name": "故城县"
					},
					"4011008": {
						"region_id": "4011008",
						"region_name": "景县"
					},
					"4011009": {
						"region_id": "4011009",
						"region_name": "阜城县"
					},
					"4011010": {
						"region_id": "4011010",
						"region_name": "冀州市"
					},
					"4011011": {
						"region_id": "4011011",
						"region_name": "深州市"
					}
				}
			}
		}
	},
	"5": {
		"region_id": "5",
		"region_name": "山西省",
		"child": {
			"5001": {
				"region_id": "5001",
				"region_name": "太原市",
				"child": {
					"5001001": {
						"region_id": "5001001",
						"region_name": "小店区"
					},
					"5001002": {
						"region_id": "5001002",
						"region_name": "迎泽区"
					},
					"5001003": {
						"region_id": "5001003",
						"region_name": "杏花岭区"
					},
					"5001004": {
						"region_id": "5001004",
						"region_name": "尖草坪区"
					},
					"5001005": {
						"region_id": "5001005",
						"region_name": "万柏林区"
					},
					"5001006": {
						"region_id": "5001006",
						"region_name": "晋源区"
					},
					"5001007": {
						"region_id": "5001007",
						"region_name": "清徐县"
					},
					"5001008": {
						"region_id": "5001008",
						"region_name": "阳曲县"
					},
					"5001009": {
						"region_id": "5001009",
						"region_name": "娄烦县"
					},
					"5001010": {
						"region_id": "5001010",
						"region_name": "古交市"
					}
				}
			},
			"5002": {
				"region_id": "5002",
				"region_name": "大同市",
				"child": {
					"5002001": {
						"region_id": "5002001",
						"region_name": "城区"
					},
					"5002002": {
						"region_id": "5002002",
						"region_name": "矿区"
					},
					"5002003": {
						"region_id": "5002003",
						"region_name": "南郊区"
					},
					"5002004": {
						"region_id": "5002004",
						"region_name": "新荣区"
					},
					"5002005": {
						"region_id": "5002005",
						"region_name": "阳高县"
					},
					"5002006": {
						"region_id": "5002006",
						"region_name": "天镇县"
					},
					"5002007": {
						"region_id": "5002007",
						"region_name": "广灵县"
					},
					"5002008": {
						"region_id": "5002008",
						"region_name": "灵丘县"
					},
					"5002009": {
						"region_id": "5002009",
						"region_name": "浑源县"
					},
					"5002010": {
						"region_id": "5002010",
						"region_name": "左云县"
					},
					"5002011": {
						"region_id": "5002011",
						"region_name": "大同县"
					}
				}
			},
			"5003": {
				"region_id": "5003",
				"region_name": "阳泉市",
				"child": {
					"5003001": {
						"region_id": "5003001",
						"region_name": "城区"
					},
					"5003002": {
						"region_id": "5003002",
						"region_name": "矿区"
					},
					"5003003": {
						"region_id": "5003003",
						"region_name": "郊区"
					},
					"5003004": {
						"region_id": "5003004",
						"region_name": "平定县"
					},
					"5003005": {
						"region_id": "5003005",
						"region_name": "盂县"
					}
				}
			},
			"5004": {
				"region_id": "5004",
				"region_name": "长治市",
				"child": {
					"5004001": {
						"region_id": "5004001",
						"region_name": "城区"
					},
					"5004002": {
						"region_id": "5004002",
						"region_name": "郊区"
					},
					"5004003": {
						"region_id": "5004003",
						"region_name": "长治县"
					},
					"5004004": {
						"region_id": "5004004",
						"region_name": "襄垣县"
					},
					"5004005": {
						"region_id": "5004005",
						"region_name": "屯留县"
					},
					"5004006": {
						"region_id": "5004006",
						"region_name": "平顺县"
					},
					"5004007": {
						"region_id": "5004007",
						"region_name": "黎城县"
					},
					"5004008": {
						"region_id": "5004008",
						"region_name": "壶关县"
					},
					"5004009": {
						"region_id": "5004009",
						"region_name": "长子县"
					},
					"5004010": {
						"region_id": "5004010",
						"region_name": "武乡县"
					},
					"5004011": {
						"region_id": "5004011",
						"region_name": "沁县"
					},
					"5004012": {
						"region_id": "5004012",
						"region_name": "沁源县"
					},
					"5004013": {
						"region_id": "5004013",
						"region_name": "潞城市"
					}
				}
			},
			"5005": {
				"region_id": "5005",
				"region_name": "晋城市",
				"child": {
					"5005001": {
						"region_id": "5005001",
						"region_name": "城区"
					},
					"5005002": {
						"region_id": "5005002",
						"region_name": "沁水县"
					},
					"5005003": {
						"region_id": "5005003",
						"region_name": "阳城县"
					},
					"5005004": {
						"region_id": "5005004",
						"region_name": "陵川县"
					},
					"5005005": {
						"region_id": "5005005",
						"region_name": "泽州县"
					},
					"5005006": {
						"region_id": "5005006",
						"region_name": "高平市"
					}
				}
			},
			"5006": {
				"region_id": "5006",
				"region_name": "朔州市",
				"child": {
					"5006001": {
						"region_id": "5006001",
						"region_name": "朔城区"
					},
					"5006002": {
						"region_id": "5006002",
						"region_name": "平鲁区"
					},
					"5006003": {
						"region_id": "5006003",
						"region_name": "山阴县"
					},
					"5006004": {
						"region_id": "5006004",
						"region_name": "应县"
					},
					"5006005": {
						"region_id": "5006005",
						"region_name": "右玉县"
					},
					"5006006": {
						"region_id": "5006006",
						"region_name": "怀仁县"
					}
				}
			},
			"5007": {
				"region_id": "5007",
				"region_name": "忻州市",
				"child": {
					"5007001": {
						"region_id": "5007001",
						"region_name": "忻府区"
					},
					"5007002": {
						"region_id": "5007002",
						"region_name": "原平市"
					},
					"5007003": {
						"region_id": "5007003",
						"region_name": "定襄县"
					},
					"5007004": {
						"region_id": "5007004",
						"region_name": "五台县"
					},
					"5007005": {
						"region_id": "5007005",
						"region_name": "代县"
					},
					"5007006": {
						"region_id": "5007006",
						"region_name": "繁峙县"
					},
					"5007007": {
						"region_id": "5007007",
						"region_name": "宁武县"
					},
					"5007008": {
						"region_id": "5007008",
						"region_name": "静乐县"
					},
					"5007009": {
						"region_id": "5007009",
						"region_name": "神池县"
					},
					"5007010": {
						"region_id": "5007010",
						"region_name": "五寨县"
					},
					"5007011": {
						"region_id": "5007011",
						"region_name": "岢岚县"
					},
					"5007012": {
						"region_id": "5007012",
						"region_name": "河曲县"
					},
					"5007013": {
						"region_id": "5007013",
						"region_name": "保德县"
					},
					"5007014": {
						"region_id": "5007014",
						"region_name": "偏关县"
					}
				}
			},
			"5008": {
				"region_id": "5008",
				"region_name": "吕梁市",
				"child": {
					"5008001": {
						"region_id": "5008001",
						"region_name": "离石区"
					},
					"5008002": {
						"region_id": "5008002",
						"region_name": "孝义市"
					},
					"5008003": {
						"region_id": "5008003",
						"region_name": "汾阳市"
					},
					"5008004": {
						"region_id": "5008004",
						"region_name": "文水县"
					},
					"5008005": {
						"region_id": "5008005",
						"region_name": "交城县"
					},
					"5008006": {
						"region_id": "5008006",
						"region_name": "兴县"
					},
					"5008007": {
						"region_id": "5008007",
						"region_name": "临县"
					},
					"5008008": {
						"region_id": "5008008",
						"region_name": "柳林县"
					},
					"5008009": {
						"region_id": "5008009",
						"region_name": "石楼县"
					},
					"5008010": {
						"region_id": "5008010",
						"region_name": "岚县"
					},
					"5008011": {
						"region_id": "5008011",
						"region_name": "方山县"
					},
					"5008012": {
						"region_id": "5008012",
						"region_name": "中阳县"
					},
					"5008013": {
						"region_id": "5008013",
						"region_name": "交口县"
					}
				}
			},
			"5009": {
				"region_id": "5009",
				"region_name": "晋中市",
				"child": {
					"5009001": {
						"region_id": "5009001",
						"region_name": "榆次市"
					},
					"5009002": {
						"region_id": "5009002",
						"region_name": "介休市"
					},
					"5009003": {
						"region_id": "5009003",
						"region_name": "榆社县"
					},
					"5009004": {
						"region_id": "5009004",
						"region_name": "左权县"
					},
					"5009005": {
						"region_id": "5009005",
						"region_name": "和顺县"
					},
					"5009006": {
						"region_id": "5009006",
						"region_name": "昔阳县"
					},
					"5009007": {
						"region_id": "5009007",
						"region_name": "寿阳县"
					},
					"5009008": {
						"region_id": "5009008",
						"region_name": "太谷县"
					},
					"5009009": {
						"region_id": "5009009",
						"region_name": "祁县"
					},
					"5009010": {
						"region_id": "5009010",
						"region_name": "平遥县"
					},
					"5009011": {
						"region_id": "5009011",
						"region_name": "灵石县"
					}
				}
			},
			"5010": {
				"region_id": "5010",
				"region_name": "临汾市",
				"child": {
					"5010001": {
						"region_id": "5010001",
						"region_name": "临汾市"
					},
					"5010002": {
						"region_id": "5010002",
						"region_name": "侯马市"
					},
					"5010003": {
						"region_id": "5010003",
						"region_name": "霍州市"
					},
					"5010004": {
						"region_id": "5010004",
						"region_name": "曲沃县"
					},
					"5010005": {
						"region_id": "5010005",
						"region_name": "翼城县"
					},
					"5010006": {
						"region_id": "5010006",
						"region_name": "襄汾县"
					},
					"5010007": {
						"region_id": "5010007",
						"region_name": "洪洞县"
					},
					"5010008": {
						"region_id": "5010008",
						"region_name": "古县"
					},
					"5010009": {
						"region_id": "5010009",
						"region_name": "安泽县"
					},
					"5010010": {
						"region_id": "5010010",
						"region_name": "浮山县"
					},
					"5010011": {
						"region_id": "5010011",
						"region_name": "吉县"
					},
					"5010012": {
						"region_id": "5010012",
						"region_name": "乡宁县"
					},
					"5010013": {
						"region_id": "5010013",
						"region_name": "蒲县"
					},
					"5010014": {
						"region_id": "5010014",
						"region_name": "大宁县"
					},
					"5010015": {
						"region_id": "5010015",
						"region_name": "永和县"
					},
					"5010016": {
						"region_id": "5010016",
						"region_name": "隰县"
					},
					"5010017": {
						"region_id": "5010017",
						"region_name": "汾西县"
					}
				}
			},
			"5011": {
				"region_id": "5011",
				"region_name": "运城市",
				"child": {
					"5011001": {
						"region_id": "5011001",
						"region_name": "运城市"
					},
					"5011002": {
						"region_id": "5011002",
						"region_name": "永济市"
					},
					"5011003": {
						"region_id": "5011003",
						"region_name": "河津市"
					},
					"5011004": {
						"region_id": "5011004",
						"region_name": "芮城县"
					},
					"5011005": {
						"region_id": "5011005",
						"region_name": "临猗县"
					},
					"5011006": {
						"region_id": "5011006",
						"region_name": "万荣县"
					},
					"5011007": {
						"region_id": "5011007",
						"region_name": "新绛县"
					},
					"5011008": {
						"region_id": "5011008",
						"region_name": "稷山县"
					},
					"5011009": {
						"region_id": "5011009",
						"region_name": "闻喜县"
					},
					"5011010": {
						"region_id": "5011010",
						"region_name": "夏县"
					},
					"5011011": {
						"region_id": "5011011",
						"region_name": "绛县"
					},
					"5011012": {
						"region_id": "5011012",
						"region_name": "平陆县"
					},
					"5011013": {
						"region_id": "5011013",
						"region_name": "垣曲县"
					}
				}
			}
		}
	},
	"6": {
		"region_id": "6",
		"region_name": "内蒙古",
		"child": {
			"6001": {
				"region_id": "6001",
				"region_name": "呼和浩特市",
				"child": {
					"6001001": {
						"region_id": "6001001",
						"region_name": "新城区"
					},
					"6001002": {
						"region_id": "6001002",
						"region_name": "回民区"
					},
					"6001003": {
						"region_id": "6001003",
						"region_name": "玉泉区"
					},
					"6001004": {
						"region_id": "6001004",
						"region_name": "郊区"
					},
					"6001005": {
						"region_id": "6001005",
						"region_name": "土默特左旗"
					},
					"6001006": {
						"region_id": "6001006",
						"region_name": "托克托县"
					},
					"6001007": {
						"region_id": "6001007",
						"region_name": "和林格尔县"
					},
					"6001008": {
						"region_id": "6001008",
						"region_name": "清水河县"
					},
					"6001009": {
						"region_id": "6001009",
						"region_name": "武川县"
					}
				}
			},
			"6002": {
				"region_id": "6002",
				"region_name": "包头市",
				"child": {
					"6002001": {
						"region_id": "6002001",
						"region_name": "东河区"
					},
					"6002002": {
						"region_id": "6002002",
						"region_name": "昆都伦区"
					},
					"6002003": {
						"region_id": "6002003",
						"region_name": "青山区"
					},
					"6002004": {
						"region_id": "6002004",
						"region_name": "石拐矿区"
					},
					"6002005": {
						"region_id": "6002005",
						"region_name": "白云矿区"
					},
					"6002006": {
						"region_id": "6002006",
						"region_name": "郊区"
					},
					"6002007": {
						"region_id": "6002007",
						"region_name": "土默特右旗"
					},
					"6002008": {
						"region_id": "6002008",
						"region_name": "固阳县"
					},
					"6002009": {
						"region_id": "6002009",
						"region_name": "达尔罕茂明安联合旗"
					}
				}
			},
			"6003": {
				"region_id": "6003",
				"region_name": "乌海市",
				"child": {
					"6003001": {
						"region_id": "6003001",
						"region_name": "海勃湾区"
					},
					"6003002": {
						"region_id": "6003002",
						"region_name": "海南区"
					},
					"6003003": {
						"region_id": "6003003",
						"region_name": "乌达区"
					}
				}
			},
			"6004": {
				"region_id": "6004",
				"region_name": "赤峰市",
				"child": {
					"6004001": {
						"region_id": "6004001",
						"region_name": "红山区"
					},
					"6004002": {
						"region_id": "6004002",
						"region_name": "元宝山区"
					},
					"6004003": {
						"region_id": "6004003",
						"region_name": "松山区"
					},
					"6004004": {
						"region_id": "6004004",
						"region_name": "阿鲁科尔沁旗"
					},
					"6004005": {
						"region_id": "6004005",
						"region_name": "巴林左旗"
					},
					"6004006": {
						"region_id": "6004006",
						"region_name": "巴林右旗"
					},
					"6004007": {
						"region_id": "6004007",
						"region_name": "林西县"
					},
					"6004008": {
						"region_id": "6004008",
						"region_name": "克什克腾旗"
					},
					"6004009": {
						"region_id": "6004009",
						"region_name": "翁牛特旗"
					},
					"6004010": {
						"region_id": "6004010",
						"region_name": "喀喇沁旗"
					},
					"6004011": {
						"region_id": "6004011",
						"region_name": "宁城县"
					},
					"6004012": {
						"region_id": "6004012",
						"region_name": "敖汉旗"
					}
				}
			},
			"6005": {
				"region_id": "6005",
				"region_name": "呼伦贝尔市",
				"child": {
					"6005001": {
						"region_id": "6005001",
						"region_name": "海拉尔市"
					},
					"6005002": {
						"region_id": "6005002",
						"region_name": "满洲里市"
					},
					"6005003": {
						"region_id": "6005003",
						"region_name": "扎兰屯市"
					},
					"6005004": {
						"region_id": "6005004",
						"region_name": "牙克石市"
					},
					"6005005": {
						"region_id": "6005005",
						"region_name": "根河市"
					},
					"6005006": {
						"region_id": "6005006",
						"region_name": "额尔古纳市"
					},
					"6005007": {
						"region_id": "6005007",
						"region_name": "阿荣旗"
					},
					"6005008": {
						"region_id": "6005008",
						"region_name": "莫力达瓦达斡尔族自治旗"
					},
					"6005009": {
						"region_id": "6005009",
						"region_name": "鄂伦春自治旗"
					},
					"6005010": {
						"region_id": "6005010",
						"region_name": "鄂温克族自治旗"
					},
					"6005011": {
						"region_id": "6005011",
						"region_name": "新巴尔虎右旗"
					},
					"6005012": {
						"region_id": "6005012",
						"region_name": "新巴尔虎左旗"
					},
					"6005013": {
						"region_id": "6005013",
						"region_name": "陈巴尔虎旗"
					}
				}
			},
			"6006": {
				"region_id": "6006",
				"region_name": "兴安盟",
				"child": {
					"6006001": {
						"region_id": "6006001",
						"region_name": "乌兰浩特市"
					},
					"6006002": {
						"region_id": "6006002",
						"region_name": "阿尔山市"
					},
					"6006003": {
						"region_id": "6006003",
						"region_name": "科尔沁右翼前旗"
					},
					"6006004": {
						"region_id": "6006004",
						"region_name": "科尔沁右翼中旗"
					},
					"6006005": {
						"region_id": "6006005",
						"region_name": "扎赉特旗"
					},
					"6006006": {
						"region_id": "6006006",
						"region_name": "突泉县"
					}
				}
			},
			"6007": {
				"region_id": "6007",
				"region_name": "通辽市",
				"child": {
					"6007001": {
						"region_id": "6007001",
						"region_name": "科尔沁区"
					},
					"6007002": {
						"region_id": "6007002",
						"region_name": "霍林郭勒市"
					},
					"6007003": {
						"region_id": "6007003",
						"region_name": "科尔沁左翼中旗"
					},
					"6007004": {
						"region_id": "6007004",
						"region_name": "科尔沁左翼后旗"
					},
					"6007005": {
						"region_id": "6007005",
						"region_name": "开鲁县"
					},
					"6007006": {
						"region_id": "6007006",
						"region_name": "库伦旗"
					},
					"6007007": {
						"region_id": "6007007",
						"region_name": "奈曼旗"
					},
					"6007008": {
						"region_id": "6007008",
						"region_name": "扎鲁特旗"
					}
				}
			},
			"6008": {
				"region_id": "6008",
				"region_name": "锡林郭勒盟",
				"child": {
					"6008001": {
						"region_id": "6008001",
						"region_name": "二连浩特市"
					},
					"6008002": {
						"region_id": "6008002",
						"region_name": "锡林浩特市"
					},
					"6008003": {
						"region_id": "6008003",
						"region_name": "阿巴嘎旗"
					},
					"6008004": {
						"region_id": "6008004",
						"region_name": "苏尼特左旗"
					},
					"6008005": {
						"region_id": "6008005",
						"region_name": "苏尼特右旗"
					},
					"6008006": {
						"region_id": "6008006",
						"region_name": "东乌珠穆沁旗"
					},
					"6008007": {
						"region_id": "6008007",
						"region_name": "西乌珠穆沁旗"
					},
					"6008008": {
						"region_id": "6008008",
						"region_name": "太仆寺旗"
					},
					"6008009": {
						"region_id": "6008009",
						"region_name": "镶黄旗"
					},
					"6008010": {
						"region_id": "6008010",
						"region_name": "正镶白旗"
					},
					"6008011": {
						"region_id": "6008011",
						"region_name": "正蓝旗"
					},
					"6008012": {
						"region_id": "6008012",
						"region_name": "多伦县"
					}
				}
			},
			"6009": {
				"region_id": "6009",
				"region_name": "乌兰察布盟",
				"child": {
					"6009001": {
						"region_id": "6009001",
						"region_name": "集宁市"
					},
					"6009002": {
						"region_id": "6009002",
						"region_name": "丰镇市"
					},
					"6009003": {
						"region_id": "6009003",
						"region_name": "卓资县"
					},
					"6009004": {
						"region_id": "6009004",
						"region_name": "化德县"
					},
					"6009005": {
						"region_id": "6009005",
						"region_name": "商都县"
					},
					"6009006": {
						"region_id": "6009006",
						"region_name": "兴和县"
					},
					"6009007": {
						"region_id": "6009007",
						"region_name": "凉城县"
					},
					"6009008": {
						"region_id": "6009008",
						"region_name": "察哈尔右翼前旗"
					},
					"6009009": {
						"region_id": "6009009",
						"region_name": "察哈尔右翼中旗"
					},
					"6009010": {
						"region_id": "6009010",
						"region_name": "察哈尔右翼后旗"
					},
					"6009011": {
						"region_id": "6009011",
						"region_name": "四子王旗"
					}
				}
			},
			"6010": {
				"region_id": "6010",
				"region_name": "伊克昭盟（鄂尔多斯旧称）",
				"child": {
					"6010001": {
						"region_id": "6010001",
						"region_name": "东胜市"
					},
					"6010002": {
						"region_id": "6010002",
						"region_name": "达拉特旗"
					},
					"6010003": {
						"region_id": "6010003",
						"region_name": "准格尔旗"
					},
					"6010004": {
						"region_id": "6010004",
						"region_name": "鄂托克前旗"
					},
					"6010005": {
						"region_id": "6010005",
						"region_name": "鄂托克旗"
					},
					"6010006": {
						"region_id": "6010006",
						"region_name": "杭锦旗"
					},
					"6010007": {
						"region_id": "6010007",
						"region_name": "乌审旗"
					},
					"6010008": {
						"region_id": "6010008",
						"region_name": "伊金霍洛旗"
					}
				}
			},
			"6011": {
				"region_id": "6011",
				"region_name": "巴彦淖尔盟",
				"child": {
					"6011001": {
						"region_id": "6011001",
						"region_name": "临河市"
					},
					"6011002": {
						"region_id": "6011002",
						"region_name": "五原县"
					},
					"6011003": {
						"region_id": "6011003",
						"region_name": "磴口县"
					},
					"6011004": {
						"region_id": "6011004",
						"region_name": "乌拉特前旗"
					},
					"6011005": {
						"region_id": "6011005",
						"region_name": "乌拉特中旗"
					},
					"6011006": {
						"region_id": "6011006",
						"region_name": "乌拉特后旗"
					},
					"6011007": {
						"region_id": "6011007",
						"region_name": "杭锦后旗"
					}
				}
			},
			"6012": {
				"region_id": "6012",
				"region_name": "阿拉善盟",
				"child": {
					"6012001": {
						"region_id": "6012001",
						"region_name": "阿拉善左旗"
					},
					"6012002": {
						"region_id": "6012002",
						"region_name": "阿拉善右旗"
					},
					"6012003": {
						"region_id": "6012003",
						"region_name": "额济纳旗"
					}
				}
			}
		}
	},
	"7": {
		"region_id": "7",
		"region_name": "辽宁省",
		"child": {
			"7001": {
				"region_id": "7001",
				"region_name": "沈阳市",
				"child": {
					"7001001": {
						"region_id": "7001001",
						"region_name": "和平区"
					},
					"7001002": {
						"region_id": "7001002",
						"region_name": "沈河区"
					},
					"7001003": {
						"region_id": "7001003",
						"region_name": "大东区"
					},
					"7001004": {
						"region_id": "7001004",
						"region_name": "皇姑区"
					},
					"7001005": {
						"region_id": "7001005",
						"region_name": "铁西区"
					},
					"7001006": {
						"region_id": "7001006",
						"region_name": "苏家屯区"
					},
					"7001007": {
						"region_id": "7001007",
						"region_name": "东陵区"
					},
					"7001008": {
						"region_id": "7001008",
						"region_name": "新城子区"
					},
					"7001009": {
						"region_id": "7001009",
						"region_name": "于洪区"
					},
					"7001010": {
						"region_id": "7001010",
						"region_name": "辽中县"
					},
					"7001011": {
						"region_id": "7001011",
						"region_name": "康平县"
					},
					"7001012": {
						"region_id": "7001012",
						"region_name": "法库县"
					},
					"7001013": {
						"region_id": "7001013",
						"region_name": "新民市"
					}
				}
			},
			"7002": {
				"region_id": "7002",
				"region_name": "大连市",
				"child": {
					"7002001": {
						"region_id": "7002001",
						"region_name": "中山区"
					},
					"7002002": {
						"region_id": "7002002",
						"region_name": "西岗区"
					},
					"7002003": {
						"region_id": "7002003",
						"region_name": "沙河口区"
					},
					"7002004": {
						"region_id": "7002004",
						"region_name": "甘井子区"
					},
					"7002005": {
						"region_id": "7002005",
						"region_name": "旅顺口区"
					},
					"7002006": {
						"region_id": "7002006",
						"region_name": "金州区"
					},
					"7002007": {
						"region_id": "7002007",
						"region_name": "长海县"
					},
					"7002008": {
						"region_id": "7002008",
						"region_name": "瓦房店市"
					},
					"7002009": {
						"region_id": "7002009",
						"region_name": "普兰店市"
					},
					"7002010": {
						"region_id": "7002010",
						"region_name": "庄河市"
					}
				}
			},
			"7003": {
				"region_id": "7003",
				"region_name": "鞍山市",
				"child": {
					"7003001": {
						"region_id": "7003001",
						"region_name": "铁东区"
					},
					"7003002": {
						"region_id": "7003002",
						"region_name": "铁西区"
					},
					"7003003": {
						"region_id": "7003003",
						"region_name": "立山区"
					},
					"7003004": {
						"region_id": "7003004",
						"region_name": "千山区"
					},
					"7003005": {
						"region_id": "7003005",
						"region_name": "台安县"
					},
					"7003006": {
						"region_id": "7003006",
						"region_name": "岫岩满族自治县"
					},
					"7003007": {
						"region_id": "7003007",
						"region_name": "海城市"
					}
				}
			},
			"7004": {
				"region_id": "7004",
				"region_name": "抚顺市",
				"child": {
					"7004001": {
						"region_id": "7004001",
						"region_name": "新抚区"
					},
					"7004002": {
						"region_id": "7004002",
						"region_name": "露天区"
					},
					"7004003": {
						"region_id": "7004003",
						"region_name": "望花区"
					},
					"7004004": {
						"region_id": "7004004",
						"region_name": "顺城区"
					},
					"7004005": {
						"region_id": "7004005",
						"region_name": "抚顺县"
					},
					"7004006": {
						"region_id": "7004006",
						"region_name": "新宾满族自治县"
					},
					"7004007": {
						"region_id": "7004007",
						"region_name": "清原满族自治县"
					}
				}
			},
			"7005": {
				"region_id": "7005",
				"region_name": "本溪市",
				"child": {
					"7005001": {
						"region_id": "7005001",
						"region_name": "平山区"
					},
					"7005002": {
						"region_id": "7005002",
						"region_name": "溪湖区"
					},
					"7005003": {
						"region_id": "7005003",
						"region_name": "明山区"
					},
					"7005004": {
						"region_id": "7005004",
						"region_name": "南芬区"
					},
					"7005005": {
						"region_id": "7005005",
						"region_name": "本溪满族自治县"
					},
					"7005006": {
						"region_id": "7005006",
						"region_name": "桓仁满族自治县"
					}
				}
			},
			"7006": {
				"region_id": "7006",
				"region_name": "丹东市",
				"child": {
					"7006001": {
						"region_id": "7006001",
						"region_name": "元宝区"
					},
					"7006002": {
						"region_id": "7006002",
						"region_name": "振兴区"
					},
					"7006003": {
						"region_id": "7006003",
						"region_name": "振安区"
					},
					"7006004": {
						"region_id": "7006004",
						"region_name": "宽甸满族自治县"
					},
					"7006005": {
						"region_id": "7006005",
						"region_name": "东港市"
					},
					"7006006": {
						"region_id": "7006006",
						"region_name": "凤城市"
					}
				}
			},
			"7007": {
				"region_id": "7007",
				"region_name": "锦州市",
				"child": {
					"7007001": {
						"region_id": "7007001",
						"region_name": "古塔区"
					},
					"7007002": {
						"region_id": "7007002",
						"region_name": "凌河区"
					},
					"7007003": {
						"region_id": "7007003",
						"region_name": "太和区"
					},
					"7007004": {
						"region_id": "7007004",
						"region_name": "黑山县"
					},
					"7007005": {
						"region_id": "7007005",
						"region_name": "义县"
					},
					"7007006": {
						"region_id": "7007006",
						"region_name": "凌海市"
					},
					"7007007": {
						"region_id": "7007007",
						"region_name": "北宁市"
					}
				}
			},
			"7008": {
				"region_id": "7008",
				"region_name": "营口市",
				"child": {
					"7008001": {
						"region_id": "7008001",
						"region_name": "站前区"
					},
					"7008002": {
						"region_id": "7008002",
						"region_name": "西市区"
					},
					"7008003": {
						"region_id": "7008003",
						"region_name": "鲅鱼圈区"
					},
					"7008004": {
						"region_id": "7008004",
						"region_name": "老边区"
					},
					"7008005": {
						"region_id": "7008005",
						"region_name": "盖州市"
					},
					"7008006": {
						"region_id": "7008006",
						"region_name": "大石桥市"
					}
				}
			},
			"7009": {
				"region_id": "7009",
				"region_name": "阜新市",
				"child": {
					"7009001": {
						"region_id": "7009001",
						"region_name": "海州区"
					},
					"7009002": {
						"region_id": "7009002",
						"region_name": "新邱区"
					},
					"7009003": {
						"region_id": "7009003",
						"region_name": "太平区"
					},
					"7009004": {
						"region_id": "7009004",
						"region_name": "清河门区"
					},
					"7009005": {
						"region_id": "7009005",
						"region_name": "细河区"
					},
					"7009006": {
						"region_id": "7009006",
						"region_name": "阜新蒙古族自治县"
					},
					"7009007": {
						"region_id": "7009007",
						"region_name": "彰武县"
					}
				}
			},
			"7010": {
				"region_id": "7010",
				"region_name": "辽阳市",
				"child": {
					"7010001": {
						"region_id": "7010001",
						"region_name": "白塔区"
					},
					"7010002": {
						"region_id": "7010002",
						"region_name": "文圣区"
					},
					"7010003": {
						"region_id": "7010003",
						"region_name": "宏伟区"
					},
					"7010004": {
						"region_id": "7010004",
						"region_name": "弓长岭区"
					},
					"7010005": {
						"region_id": "7010005",
						"region_name": "太子河区"
					},
					"7010006": {
						"region_id": "7010006",
						"region_name": "辽阳县"
					},
					"7010007": {
						"region_id": "7010007",
						"region_name": "灯塔市"
					}
				}
			},
			"7011": {
				"region_id": "7011",
				"region_name": "盘锦市",
				"child": {
					"7011001": {
						"region_id": "7011001",
						"region_name": "双台子区"
					},
					"7011002": {
						"region_id": "7011002",
						"region_name": "兴隆台区"
					},
					"7011003": {
						"region_id": "7011003",
						"region_name": "大洼县"
					},
					"7011004": {
						"region_id": "7011004",
						"region_name": "盘山县"
					}
				}
			},
			"7012": {
				"region_id": "7012",
				"region_name": "铁岭市",
				"child": {
					"7012001": {
						"region_id": "7012001",
						"region_name": "银州区"
					},
					"7012002": {
						"region_id": "7012002",
						"region_name": "清河区"
					},
					"7012003": {
						"region_id": "7012003",
						"region_name": "铁岭县"
					},
					"7012004": {
						"region_id": "7012004",
						"region_name": "西丰县"
					},
					"7012005": {
						"region_id": "7012005",
						"region_name": "昌图县"
					},
					"7012006": {
						"region_id": "7012006",
						"region_name": "铁法市"
					},
					"7012007": {
						"region_id": "7012007",
						"region_name": "开原市"
					}
				}
			},
			"7013": {
				"region_id": "7013",
				"region_name": "朝阳市",
				"child": {
					"7013001": {
						"region_id": "7013001",
						"region_name": "双塔区"
					},
					"7013002": {
						"region_id": "7013002",
						"region_name": "龙城区"
					},
					"7013003": {
						"region_id": "7013003",
						"region_name": "朝阳县"
					},
					"7013004": {
						"region_id": "7013004",
						"region_name": "建平县"
					},
					"7013005": {
						"region_id": "7013005",
						"region_name": "喀喇沁左翼蒙古族自治县"
					},
					"7013006": {
						"region_id": "7013006",
						"region_name": "北票市"
					},
					"7013007": {
						"region_id": "7013007",
						"region_name": "凌源市"
					}
				}
			},
			"7014": {
				"region_id": "7014",
				"region_name": "葫芦岛市",
				"child": {
					"7014001": {
						"region_id": "7014001",
						"region_name": "连山区"
					},
					"7014002": {
						"region_id": "7014002",
						"region_name": "龙港区"
					},
					"7014003": {
						"region_id": "7014003",
						"region_name": "南票区"
					},
					"7014004": {
						"region_id": "7014004",
						"region_name": "绥中县"
					},
					"7014005": {
						"region_id": "7014005",
						"region_name": "建昌县"
					},
					"7014006": {
						"region_id": "7014006",
						"region_name": "兴城市"
					}
				}
			}
		}
	},
	"8": {
		"region_id": "8",
		"region_name": "吉林省",
		"child": {
			"8001": {
				"region_id": "8001",
				"region_name": "长春市",
				"child": {
					"8001001": {
						"region_id": "8001001",
						"region_name": "南关区"
					},
					"8001002": {
						"region_id": "8001002",
						"region_name": "宽城区"
					},
					"8001003": {
						"region_id": "8001003",
						"region_name": "朝阳区"
					},
					"8001004": {
						"region_id": "8001004",
						"region_name": "二道区"
					},
					"8001005": {
						"region_id": "8001005",
						"region_name": "绿园区"
					},
					"8001006": {
						"region_id": "8001006",
						"region_name": "双阳区"
					},
					"8001007": {
						"region_id": "8001007",
						"region_name": "农安县"
					},
					"8001008": {
						"region_id": "8001008",
						"region_name": "九台市"
					},
					"8001009": {
						"region_id": "8001009",
						"region_name": "榆树市"
					},
					"8001010": {
						"region_id": "8001010",
						"region_name": "德惠市"
					}
				}
			},
			"8002": {
				"region_id": "8002",
				"region_name": "吉林市",
				"child": {
					"8002001": {
						"region_id": "8002001",
						"region_name": "昌邑区"
					},
					"8002002": {
						"region_id": "8002002",
						"region_name": "龙潭区"
					},
					"8002003": {
						"region_id": "8002003",
						"region_name": "船营区"
					},
					"8002004": {
						"region_id": "8002004",
						"region_name": "丰满区"
					},
					"8002005": {
						"region_id": "8002005",
						"region_name": "永吉县"
					},
					"8002006": {
						"region_id": "8002006",
						"region_name": "蛟河市"
					},
					"8002007": {
						"region_id": "8002007",
						"region_name": "桦甸市"
					},
					"8002008": {
						"region_id": "8002008",
						"region_name": "舒兰市"
					},
					"8002009": {
						"region_id": "8002009",
						"region_name": "磐石市"
					}
				}
			},
			"8003": {
				"region_id": "8003",
				"region_name": "四平市",
				"child": {
					"8003001": {
						"region_id": "8003001",
						"region_name": "铁西区"
					},
					"8003002": {
						"region_id": "8003002",
						"region_name": "铁东区"
					},
					"8003003": {
						"region_id": "8003003",
						"region_name": "梨树县"
					},
					"8003004": {
						"region_id": "8003004",
						"region_name": "伊通满族自治县"
					},
					"8003005": {
						"region_id": "8003005",
						"region_name": "公主岭市"
					},
					"8003006": {
						"region_id": "8003006",
						"region_name": "双辽市"
					}
				}
			},
			"8004": {
				"region_id": "8004",
				"region_name": "辽源市",
				"child": {
					"8004001": {
						"region_id": "8004001",
						"region_name": "龙山区"
					},
					"8004002": {
						"region_id": "8004002",
						"region_name": "西安区"
					},
					"8004003": {
						"region_id": "8004003",
						"region_name": "东丰县"
					},
					"8004004": {
						"region_id": "8004004",
						"region_name": "东辽县"
					}
				}
			},
			"8005": {
				"region_id": "8005",
				"region_name": "通化市",
				"child": {
					"8005001": {
						"region_id": "8005001",
						"region_name": "东昌区"
					},
					"8005002": {
						"region_id": "8005002",
						"region_name": "二道江区"
					},
					"8005003": {
						"region_id": "8005003",
						"region_name": "通化县"
					},
					"8005004": {
						"region_id": "8005004",
						"region_name": "辉南县"
					},
					"8005005": {
						"region_id": "8005005",
						"region_name": "柳河县"
					},
					"8005006": {
						"region_id": "8005006",
						"region_name": "梅河口市"
					},
					"8005007": {
						"region_id": "8005007",
						"region_name": "集安市"
					}
				}
			},
			"8006": {
				"region_id": "8006",
				"region_name": "白山市",
				"child": {
					"8006001": {
						"region_id": "8006001",
						"region_name": "八道江区"
					},
					"8006002": {
						"region_id": "8006002",
						"region_name": "抚松县"
					},
					"8006003": {
						"region_id": "8006003",
						"region_name": "靖宇县"
					},
					"8006004": {
						"region_id": "8006004",
						"region_name": "长白朝鲜族自治县"
					},
					"8006005": {
						"region_id": "8006005",
						"region_name": "江源县"
					},
					"8006006": {
						"region_id": "8006006",
						"region_name": "临江市"
					}
				}
			},
			"8007": {
				"region_id": "8007",
				"region_name": "松原市",
				"child": {
					"8007001": {
						"region_id": "8007001",
						"region_name": "宁江区"
					},
					"8007002": {
						"region_id": "8007002",
						"region_name": "前郭尔罗斯蒙古族自治县"
					},
					"8007003": {
						"region_id": "8007003",
						"region_name": "长岭县"
					},
					"8007004": {
						"region_id": "8007004",
						"region_name": "乾安县"
					},
					"8007005": {
						"region_id": "8007005",
						"region_name": "扶余县"
					}
				}
			},
			"8008": {
				"region_id": "8008",
				"region_name": "白城市",
				"child": {
					"8008001": {
						"region_id": "8008001",
						"region_name": "洮北区"
					},
					"8008002": {
						"region_id": "8008002",
						"region_name": "镇赉县"
					},
					"8008003": {
						"region_id": "8008003",
						"region_name": "通榆县"
					},
					"8008004": {
						"region_id": "8008004",
						"region_name": "洮南市"
					},
					"8008005": {
						"region_id": "8008005",
						"region_name": "大安市"
					}
				}
			},
			"8009": {
				"region_id": "8009",
				"region_name": "延边朝鲜族自治州",
				"child": {
					"8009001": {
						"region_id": "8009001",
						"region_name": "延吉市"
					},
					"8009002": {
						"region_id": "8009002",
						"region_name": "图们市"
					},
					"8009003": {
						"region_id": "8009003",
						"region_name": "敦化市"
					},
					"8009004": {
						"region_id": "8009004",
						"region_name": "珲春市"
					},
					"8009005": {
						"region_id": "8009005",
						"region_name": "龙井市"
					},
					"8009006": {
						"region_id": "8009006",
						"region_name": "和龙市"
					},
					"8009007": {
						"region_id": "8009007",
						"region_name": "汪清县"
					},
					"8009008": {
						"region_id": "8009008",
						"region_name": "安图县"
					}
				}
			}
		}
	},
	"9": {
		"region_id": "9",
		"region_name": "黑龙江省",
		"child": {
			"9001": {
				"region_id": "9001",
				"region_name": "哈尔滨市",
				"child": {
					"9001001": {
						"region_id": "9001001",
						"region_name": "道里区"
					},
					"9001002": {
						"region_id": "9001002",
						"region_name": "南岗区"
					},
					"9001003": {
						"region_id": "9001003",
						"region_name": "道外区"
					},
					"9001004": {
						"region_id": "9001004",
						"region_name": "松北区"
					},
					"9001005": {
						"region_id": "9001005",
						"region_name": "香坊区"
					},
					"9001006": {
						"region_id": "9001006",
						"region_name": "阿成区"
					},
					"9001007": {
						"region_id": "9001007",
						"region_name": "平房区"
					},
					"9001008": {
						"region_id": "9001008",
						"region_name": "呼兰区"
					},
					"9001009": {
						"region_id": "9001009",
						"region_name": "依兰县"
					},
					"9001010": {
						"region_id": "9001010",
						"region_name": "方正县"
					},
					"9001011": {
						"region_id": "9001011",
						"region_name": "宾县"
					},
					"9001012": {
						"region_id": "9001012",
						"region_name": "巴彦县"
					},
					"9001013": {
						"region_id": "9001013",
						"region_name": "木兰县"
					},
					"9001014": {
						"region_id": "9001014",
						"region_name": "通河县"
					},
					"9001015": {
						"region_id": "9001015",
						"region_name": "延寿县"
					},
					"9001016": {
						"region_id": "9001016",
						"region_name": "双城市"
					},
					"9001017": {
						"region_id": "9001017",
						"region_name": "尚志市"
					},
					"9001018": {
						"region_id": "9001018",
						"region_name": "五常市"
					}
				}
			},
			"9002": {
				"region_id": "9002",
				"region_name": "齐齐哈尔市",
				"child": {
					"9002001": {
						"region_id": "9002001",
						"region_name": "龙沙区"
					},
					"9002002": {
						"region_id": "9002002",
						"region_name": "建华区"
					},
					"9002003": {
						"region_id": "9002003",
						"region_name": "铁锋区"
					},
					"9002004": {
						"region_id": "9002004",
						"region_name": "昂昂溪区"
					},
					"9002005": {
						"region_id": "9002005",
						"region_name": "富拉尔基区"
					},
					"9002006": {
						"region_id": "9002006",
						"region_name": "碾子山区"
					},
					"9002007": {
						"region_id": "9002007",
						"region_name": "梅里斯达斡尔族区"
					},
					"9002008": {
						"region_id": "9002008",
						"region_name": "龙江县"
					},
					"9002009": {
						"region_id": "9002009",
						"region_name": "依安县"
					},
					"9002010": {
						"region_id": "9002010",
						"region_name": "泰来县"
					},
					"9002011": {
						"region_id": "9002011",
						"region_name": "甘南县"
					},
					"9002012": {
						"region_id": "9002012",
						"region_name": "富裕县"
					},
					"9002013": {
						"region_id": "9002013",
						"region_name": "克山县"
					},
					"9002014": {
						"region_id": "9002014",
						"region_name": "克东县"
					},
					"9002015": {
						"region_id": "9002015",
						"region_name": "拜泉县"
					},
					"9002016": {
						"region_id": "9002016",
						"region_name": "讷河市"
					}
				}
			},
			"9003": {
				"region_id": "9003",
				"region_name": "鸡西市",
				"child": {
					"9003001": {
						"region_id": "9003001",
						"region_name": "鸡冠区"
					},
					"9003002": {
						"region_id": "9003002",
						"region_name": "恒山区"
					},
					"9003003": {
						"region_id": "9003003",
						"region_name": "滴道区"
					},
					"9003004": {
						"region_id": "9003004",
						"region_name": "梨树区"
					},
					"9003005": {
						"region_id": "9003005",
						"region_name": "城子河区"
					},
					"9003006": {
						"region_id": "9003006",
						"region_name": "麻山区"
					},
					"9003007": {
						"region_id": "9003007",
						"region_name": "鸡东县"
					},
					"9003008": {
						"region_id": "9003008",
						"region_name": "虎林市"
					},
					"9003009": {
						"region_id": "9003009",
						"region_name": "密山市"
					}
				}
			},
			"9004": {
				"region_id": "9004",
				"region_name": "鹤岗市",
				"child": {
					"9004001": {
						"region_id": "9004001",
						"region_name": "向阳区"
					},
					"9004002": {
						"region_id": "9004002",
						"region_name": "工农区"
					},
					"9004003": {
						"region_id": "9004003",
						"region_name": "南山区"
					},
					"9004004": {
						"region_id": "9004004",
						"region_name": "兴安区"
					},
					"9004005": {
						"region_id": "9004005",
						"region_name": "东山区"
					},
					"9004006": {
						"region_id": "9004006",
						"region_name": "兴山区"
					},
					"9004007": {
						"region_id": "9004007",
						"region_name": "萝北县"
					},
					"9004008": {
						"region_id": "9004008",
						"region_name": "绥滨县"
					}
				}
			},
			"9005": {
				"region_id": "9005",
				"region_name": "双鸭山市",
				"child": {
					"9005001": {
						"region_id": "9005001",
						"region_name": "尖山区"
					},
					"9005002": {
						"region_id": "9005002",
						"region_name": "岭东区"
					},
					"9005003": {
						"region_id": "9005003",
						"region_name": "四方台区"
					},
					"9005004": {
						"region_id": "9005004",
						"region_name": "宝山区"
					},
					"9005005": {
						"region_id": "9005005",
						"region_name": "集贤县"
					},
					"9005006": {
						"region_id": "9005006",
						"region_name": "友谊县"
					},
					"9005007": {
						"region_id": "9005007",
						"region_name": "宝清县"
					},
					"9005008": {
						"region_id": "9005008",
						"region_name": "饶河县"
					}
				}
			},
			"9006": {
				"region_id": "9006",
				"region_name": "大庆市",
				"child": {
					"9006001": {
						"region_id": "9006001",
						"region_name": "萨尔图区"
					},
					"9006002": {
						"region_id": "9006002",
						"region_name": "龙凤区"
					},
					"9006003": {
						"region_id": "9006003",
						"region_name": "让胡路区"
					},
					"9006004": {
						"region_id": "9006004",
						"region_name": "红岗区"
					},
					"9006005": {
						"region_id": "9006005",
						"region_name": "大同区"
					},
					"9006006": {
						"region_id": "9006006",
						"region_name": "肇州县"
					},
					"9006007": {
						"region_id": "9006007",
						"region_name": "肇源县"
					},
					"9006008": {
						"region_id": "9006008",
						"region_name": "林甸县"
					},
					"9006009": {
						"region_id": "9006009",
						"region_name": "杜尔伯特蒙古族自治县"
					}
				}
			},
			"9007": {
				"region_id": "9007",
				"region_name": "伊春市",
				"child": {
					"9007001": {
						"region_id": "9007001",
						"region_name": "伊春区"
					},
					"9007002": {
						"region_id": "9007002",
						"region_name": "南岔区"
					},
					"9007003": {
						"region_id": "9007003",
						"region_name": "友好区"
					},
					"9007004": {
						"region_id": "9007004",
						"region_name": "西林区"
					},
					"9007005": {
						"region_id": "9007005",
						"region_name": "翠峦区"
					},
					"9007006": {
						"region_id": "9007006",
						"region_name": "新青区"
					},
					"9007007": {
						"region_id": "9007007",
						"region_name": "美溪区"
					},
					"9007008": {
						"region_id": "9007008",
						"region_name": "金山屯区"
					},
					"9007009": {
						"region_id": "9007009",
						"region_name": "五营区"
					},
					"9007010": {
						"region_id": "9007010",
						"region_name": "乌马河区"
					},
					"9007011": {
						"region_id": "9007011",
						"region_name": "汤旺河区"
					},
					"9007012": {
						"region_id": "9007012",
						"region_name": "带岭区"
					},
					"9007013": {
						"region_id": "9007013",
						"region_name": "乌伊岭区"
					},
					"9007014": {
						"region_id": "9007014",
						"region_name": "红星区"
					},
					"9007015": {
						"region_id": "9007015",
						"region_name": "上甘岭区"
					},
					"9007016": {
						"region_id": "9007016",
						"region_name": "嘉荫县"
					},
					"9007017": {
						"region_id": "9007017",
						"region_name": "铁力市"
					},
					"9007018": {
						"region_id": "9007018",
						"region_name": "向阳区"
					},
					"9007019": {
						"region_id": "9007019",
						"region_name": "前进区"
					},
					"9007020": {
						"region_id": "9007020",
						"region_name": "东风区"
					},
					"9007021": {
						"region_id": "9007021",
						"region_name": "郊区"
					},
					"9007022": {
						"region_id": "9007022",
						"region_name": "桦南县"
					},
					"9007023": {
						"region_id": "9007023",
						"region_name": "桦川县"
					},
					"9007024": {
						"region_id": "9007024",
						"region_name": "汤原县"
					},
					"9007025": {
						"region_id": "9007025",
						"region_name": "抚远县"
					},
					"9007026": {
						"region_id": "9007026",
						"region_name": "同江市"
					},
					"9007027": {
						"region_id": "9007027",
						"region_name": "富锦市"
					}
				}
			},
			"9008": {
				"region_id": "9008",
				"region_name": "七台河市",
				"child": {
					"9008001": {
						"region_id": "9008001",
						"region_name": "新兴区"
					},
					"9008002": {
						"region_id": "9008002",
						"region_name": "桃山区"
					},
					"9008003": {
						"region_id": "9008003",
						"region_name": "茄子河区"
					},
					"9008004": {
						"region_id": "9008004",
						"region_name": "勃利县"
					}
				}
			},
			"9009": {
				"region_id": "9009",
				"region_name": "牡丹江市",
				"child": {
					"9009001": {
						"region_id": "9009001",
						"region_name": "东安区"
					},
					"9009002": {
						"region_id": "9009002",
						"region_name": "阳明区"
					},
					"9009003": {
						"region_id": "9009003",
						"region_name": "爱民区"
					},
					"9009004": {
						"region_id": "9009004",
						"region_name": "西安区"
					},
					"9009005": {
						"region_id": "9009005",
						"region_name": "东宁县"
					},
					"9009006": {
						"region_id": "9009006",
						"region_name": "林口县"
					},
					"9009007": {
						"region_id": "9009007",
						"region_name": "绥芬河市"
					},
					"9009008": {
						"region_id": "9009008",
						"region_name": "海林市"
					},
					"9009009": {
						"region_id": "9009009",
						"region_name": "宁安市"
					},
					"9009010": {
						"region_id": "9009010",
						"region_name": "穆棱市"
					}
				}
			},
			"9010": {
				"region_id": "9010",
				"region_name": "黑河市",
				"child": {
					"9010001": {
						"region_id": "9010001",
						"region_name": "爱辉区"
					},
					"9010002": {
						"region_id": "9010002",
						"region_name": "嫩江县"
					},
					"9010003": {
						"region_id": "9010003",
						"region_name": "逊克县"
					},
					"9010004": {
						"region_id": "9010004",
						"region_name": "孙吴县"
					},
					"9010005": {
						"region_id": "9010005",
						"region_name": "北安市"
					},
					"9010006": {
						"region_id": "9010006",
						"region_name": "五大连池市"
					}
				}
			},
			"9011": {
				"region_id": "9011",
				"region_name": "绥化市",
				"child": {
					"9011001": {
						"region_id": "9011001",
						"region_name": "绥化市"
					},
					"9011002": {
						"region_id": "9011002",
						"region_name": "安达市"
					},
					"9011003": {
						"region_id": "9011003",
						"region_name": "肇东市"
					},
					"9011004": {
						"region_id": "9011004",
						"region_name": "海伦市"
					},
					"9011005": {
						"region_id": "9011005",
						"region_name": "望奎县"
					},
					"9011006": {
						"region_id": "9011006",
						"region_name": "兰西县"
					},
					"9011007": {
						"region_id": "9011007",
						"region_name": "青冈县"
					},
					"9011008": {
						"region_id": "9011008",
						"region_name": "庆安县"
					},
					"9011009": {
						"region_id": "9011009",
						"region_name": "明水县"
					},
					"9011010": {
						"region_id": "9011010",
						"region_name": "绥棱县"
					}
				}
			},
			"9012": {
				"region_id": "9012",
				"region_name": "大兴安岭地区",
				"child": {
					"9012001": {
						"region_id": "9012001",
						"region_name": "呼玛县"
					},
					"9012002": {
						"region_id": "9012002",
						"region_name": "塔河县"
					},
					"9012003": {
						"region_id": "9012003",
						"region_name": "漠河县"
					}
				}
			}
		}
	},
	"10": {
		"region_id": "10",
		"region_name": "上海市",
		"child": {
			"10001": {
				"region_id": "10001",
				"region_name": "上海市",
				"child": {
					"10001001": {
						"region_id": "10001001",
						"region_name": "浦东新区"
					},
					"10001002": {
						"region_id": "10001002",
						"region_name": "徐汇区"
					},
					"10001003": {
						"region_id": "10001003",
						"region_name": "黄浦区"
					},
					"10001004": {
						"region_id": "10001004",
						"region_name": "杨浦区"
					},
					"10001005": {
						"region_id": "10001005",
						"region_name": "虹口区"
					},
					"10001006": {
						"region_id": "10001006",
						"region_name": "闵行区"
					},
					"10001007": {
						"region_id": "10001007",
						"region_name": "长宁区"
					},
					"10001008": {
						"region_id": "10001008",
						"region_name": "普陀区"
					},
					"10001009": {
						"region_id": "10001009",
						"region_name": "宝山区"
					},
					"10001010": {
						"region_id": "10001010",
						"region_name": "静安区"
					},
					"10001011": {
						"region_id": "10001011",
						"region_name": "闸北区"
					},
					"10001012": {
						"region_id": "10001012",
						"region_name": "卢湾区"
					},
					"10001013": {
						"region_id": "10001013",
						"region_name": "松江区"
					},
					"10001014": {
						"region_id": "10001014",
						"region_name": "嘉定区"
					},
					"10001015": {
						"region_id": "10001015",
						"region_name": "南汇区"
					},
					"10001016": {
						"region_id": "10001016",
						"region_name": "金山区"
					},
					"10001017": {
						"region_id": "10001017",
						"region_name": "青浦区"
					},
					"10001018": {
						"region_id": "10001018",
						"region_name": "奉贤区"
					},
					"10001019": {
						"region_id": "10001019",
						"region_name": "崇明县"
					}
				}
			}
		}
	},
	"11": {
		"region_id": "11",
		"region_name": "江苏省",
		"child": {
			"11001": {
				"region_id": "11001",
				"region_name": "南京市",
				"child": {
					"11001001": {
						"region_id": "11001001",
						"region_name": "玄武区"
					},
					"11001002": {
						"region_id": "11001002",
						"region_name": "白下区"
					},
					"11001003": {
						"region_id": "11001003",
						"region_name": "秦淮区"
					},
					"11001004": {
						"region_id": "11001004",
						"region_name": "建邺区"
					},
					"11001005": {
						"region_id": "11001005",
						"region_name": "鼓楼区"
					},
					"11001006": {
						"region_id": "11001006",
						"region_name": "下关区"
					},
					"11001007": {
						"region_id": "11001007",
						"region_name": "浦口区"
					},
					"11001008": {
						"region_id": "11001008",
						"region_name": "栖霞区"
					},
					"11001009": {
						"region_id": "11001009",
						"region_name": "雨花台区"
					},
					"11001010": {
						"region_id": "11001010",
						"region_name": "江宁区"
					},
					"11001011": {
						"region_id": "11001011",
						"region_name": "六合区"
					},
					"11001012": {
						"region_id": "11001012",
						"region_name": "溧水县"
					},
					"11001013": {
						"region_id": "11001013",
						"region_name": "高淳县"
					}
				}
			},
			"11002": {
				"region_id": "11002",
				"region_name": "无锡市",
				"child": {
					"11002001": {
						"region_id": "11002001",
						"region_name": "崇安区"
					},
					"11002002": {
						"region_id": "11002002",
						"region_name": "南长区"
					},
					"11002003": {
						"region_id": "11002003",
						"region_name": "北塘区"
					},
					"11002004": {
						"region_id": "11002004",
						"region_name": "郊区"
					},
					"11002005": {
						"region_id": "11002005",
						"region_name": "马山区"
					},
					"11002006": {
						"region_id": "11002006",
						"region_name": "江阴市"
					},
					"11002007": {
						"region_id": "11002007",
						"region_name": "宜兴市"
					},
					"11002008": {
						"region_id": "11002008",
						"region_name": "锡山市"
					}
				}
			},
			"11003": {
				"region_id": "11003",
				"region_name": "徐州市",
				"child": {
					"11003001": {
						"region_id": "11003001",
						"region_name": "鼓楼区"
					},
					"11003002": {
						"region_id": "11003002",
						"region_name": "云龙区"
					},
					"11003003": {
						"region_id": "11003003",
						"region_name": "九里区"
					},
					"11003004": {
						"region_id": "11003004",
						"region_name": "贾汪区"
					},
					"11003005": {
						"region_id": "11003005",
						"region_name": "泉山区"
					},
					"11003006": {
						"region_id": "11003006",
						"region_name": "丰县"
					},
					"11003007": {
						"region_id": "11003007",
						"region_name": "沛县"
					},
					"11003008": {
						"region_id": "11003008",
						"region_name": "铜山县"
					},
					"11003009": {
						"region_id": "11003009",
						"region_name": "睢宁县"
					},
					"11003010": {
						"region_id": "11003010",
						"region_name": "新沂市"
					},
					"11003011": {
						"region_id": "11003011",
						"region_name": "邳州市"
					}
				}
			},
			"11004": {
				"region_id": "11004",
				"region_name": "常州市",
				"child": {
					"11004001": {
						"region_id": "11004001",
						"region_name": "天宁区"
					},
					"11004002": {
						"region_id": "11004002",
						"region_name": "钟楼区"
					},
					"11004003": {
						"region_id": "11004003",
						"region_name": "戚墅堰区"
					},
					"11004004": {
						"region_id": "11004004",
						"region_name": "郊区"
					},
					"11004005": {
						"region_id": "11004005",
						"region_name": "溧阳市"
					},
					"11004006": {
						"region_id": "11004006",
						"region_name": "金坛市"
					},
					"11004007": {
						"region_id": "11004007",
						"region_name": "武进市"
					}
				}
			},
			"11005": {
				"region_id": "11005",
				"region_name": "苏州市",
				"child": {
					"11005001": {
						"region_id": "11005001",
						"region_name": "沧浪区"
					},
					"11005002": {
						"region_id": "11005002",
						"region_name": "平江区"
					},
					"11005003": {
						"region_id": "11005003",
						"region_name": "金阊区"
					},
					"11005004": {
						"region_id": "11005004",
						"region_name": "郊区"
					},
					"11005005": {
						"region_id": "11005005",
						"region_name": "常熟市"
					},
					"11005006": {
						"region_id": "11005006",
						"region_name": "张家港市"
					},
					"11005007": {
						"region_id": "11005007",
						"region_name": "昆山市"
					},
					"11005008": {
						"region_id": "11005008",
						"region_name": "吴江市"
					},
					"11005009": {
						"region_id": "11005009",
						"region_name": "太仓市"
					},
					"11005010": {
						"region_id": "11005010",
						"region_name": "吴县市"
					}
				}
			},
			"11006": {
				"region_id": "11006",
				"region_name": "南通市",
				"child": {
					"11006001": {
						"region_id": "11006001",
						"region_name": "崇川区"
					},
					"11006002": {
						"region_id": "11006002",
						"region_name": "港闸区"
					},
					"11006003": {
						"region_id": "11006003",
						"region_name": "海安县"
					},
					"11006004": {
						"region_id": "11006004",
						"region_name": "如东县"
					},
					"11006005": {
						"region_id": "11006005",
						"region_name": "启东市"
					},
					"11006006": {
						"region_id": "11006006",
						"region_name": "如皋市"
					},
					"11006007": {
						"region_id": "11006007",
						"region_name": "通州市"
					},
					"11006008": {
						"region_id": "11006008",
						"region_name": "海门市"
					}
				}
			},
			"11007": {
				"region_id": "11007",
				"region_name": "连云港市",
				"child": {
					"11007001": {
						"region_id": "11007001",
						"region_name": "连云区"
					},
					"11007002": {
						"region_id": "11007002",
						"region_name": "云台区"
					},
					"11007003": {
						"region_id": "11007003",
						"region_name": "新浦区"
					},
					"11007004": {
						"region_id": "11007004",
						"region_name": "海州区"
					},
					"11007005": {
						"region_id": "11007005",
						"region_name": "赣榆县"
					},
					"11007006": {
						"region_id": "11007006",
						"region_name": "东海县"
					},
					"11007007": {
						"region_id": "11007007",
						"region_name": "灌云县"
					},
					"11007008": {
						"region_id": "11007008",
						"region_name": "灌南县"
					}
				}
			},
			"11008": {
				"region_id": "11008",
				"region_name": "淮安市(原淮阴市）",
				"child": {
					"11008001": {
						"region_id": "11008001",
						"region_name": "清河区"
					},
					"11008002": {
						"region_id": "11008002",
						"region_name": "清浦区"
					},
					"11008003": {
						"region_id": "11008003",
						"region_name": "淮阴县"
					},
					"11008004": {
						"region_id": "11008004",
						"region_name": "涟水县"
					},
					"11008005": {
						"region_id": "11008005",
						"region_name": "洪泽县"
					},
					"11008006": {
						"region_id": "11008006",
						"region_name": "盱眙县"
					},
					"11008007": {
						"region_id": "11008007",
						"region_name": "金湖县"
					},
					"11008008": {
						"region_id": "11008008",
						"region_name": "淮安市"
					}
				}
			},
			"11009": {
				"region_id": "11009",
				"region_name": "盐城市",
				"child": {
					"11009001": {
						"region_id": "11009001",
						"region_name": "城区"
					},
					"11009002": {
						"region_id": "11009002",
						"region_name": "响水县"
					},
					"11009003": {
						"region_id": "11009003",
						"region_name": "滨海县"
					},
					"11009004": {
						"region_id": "11009004",
						"region_name": "阜宁县"
					},
					"11009005": {
						"region_id": "11009005",
						"region_name": "射阳县"
					},
					"11009006": {
						"region_id": "11009006",
						"region_name": "建湖县"
					},
					"11009007": {
						"region_id": "11009007",
						"region_name": "盐都县"
					},
					"11009008": {
						"region_id": "11009008",
						"region_name": "东台市"
					},
					"11009009": {
						"region_id": "11009009",
						"region_name": "大丰市"
					}
				}
			},
			"11010": {
				"region_id": "11010",
				"region_name": "扬州市",
				"child": {
					"11010001": {
						"region_id": "11010001",
						"region_name": "广陵区"
					},
					"11010002": {
						"region_id": "11010002",
						"region_name": "郊区"
					},
					"11010003": {
						"region_id": "11010003",
						"region_name": "宝应县"
					},
					"11010004": {
						"region_id": "11010004",
						"region_name": "邗江县"
					},
					"11010005": {
						"region_id": "11010005",
						"region_name": "仪征市"
					},
					"11010006": {
						"region_id": "11010006",
						"region_name": "高邮市"
					},
					"11010007": {
						"region_id": "11010007",
						"region_name": "江都市"
					}
				}
			},
			"11011": {
				"region_id": "11011",
				"region_name": "镇江市",
				"child": {
					"11011001": {
						"region_id": "11011001",
						"region_name": "京口区"
					},
					"11011002": {
						"region_id": "11011002",
						"region_name": "润州区"
					},
					"11011003": {
						"region_id": "11011003",
						"region_name": "丹徒县"
					},
					"11011004": {
						"region_id": "11011004",
						"region_name": "丹阳市"
					},
					"11011005": {
						"region_id": "11011005",
						"region_name": "扬中市"
					},
					"11011006": {
						"region_id": "11011006",
						"region_name": "句容市"
					}
				}
			},
			"11012": {
				"region_id": "11012",
				"region_name": "泰州市",
				"child": {
					"11012001": {
						"region_id": "11012001",
						"region_name": "海陵区"
					},
					"11012002": {
						"region_id": "11012002",
						"region_name": "高港区"
					},
					"11012003": {
						"region_id": "11012003",
						"region_name": "兴化市"
					},
					"11012004": {
						"region_id": "11012004",
						"region_name": "靖江市"
					},
					"11012005": {
						"region_id": "11012005",
						"region_name": "泰兴市"
					},
					"11012006": {
						"region_id": "11012006",
						"region_name": "姜堰市"
					}
				}
			},
			"11013": {
				"region_id": "11013",
				"region_name": "宿迁市",
				"child": {
					"11013001": {
						"region_id": "11013001",
						"region_name": "宿城区"
					},
					"11013002": {
						"region_id": "11013002",
						"region_name": "宿豫县"
					},
					"11013003": {
						"region_id": "11013003",
						"region_name": "沭阳县"
					},
					"11013004": {
						"region_id": "11013004",
						"region_name": "泗阳县"
					},
					"11013005": {
						"region_id": "11013005",
						"region_name": "泗洪县"
					}
				}
			}
		}
	},
	"12": {
		"region_id": "12",
		"region_name": "浙江省",
		"child": {
			"12001": {
				"region_id": "12001",
				"region_name": "杭州市",
				"child": {
					"12001001": {
						"region_id": "12001001",
						"region_name": "上城区"
					},
					"12001002": {
						"region_id": "12001002",
						"region_name": "下城区"
					},
					"12001003": {
						"region_id": "12001003",
						"region_name": "江干区"
					},
					"12001004": {
						"region_id": "12001004",
						"region_name": "拱墅区"
					},
					"12001005": {
						"region_id": "12001005",
						"region_name": "西湖区"
					},
					"12001006": {
						"region_id": "12001006",
						"region_name": "滨江区"
					},
					"12001007": {
						"region_id": "12001007",
						"region_name": "桐庐县"
					},
					"12001008": {
						"region_id": "12001008",
						"region_name": "淳安县"
					},
					"12001009": {
						"region_id": "12001009",
						"region_name": "萧山市"
					},
					"12001010": {
						"region_id": "12001010",
						"region_name": "建德市"
					},
					"12001011": {
						"region_id": "12001011",
						"region_name": "富阳市"
					},
					"12001012": {
						"region_id": "12001012",
						"region_name": "余杭市"
					},
					"12001013": {
						"region_id": "12001013",
						"region_name": "临安市"
					}
				}
			},
			"12002": {
				"region_id": "12002",
				"region_name": "宁波市",
				"child": {
					"12002001": {
						"region_id": "12002001",
						"region_name": "海曙区"
					},
					"12002002": {
						"region_id": "12002002",
						"region_name": "江东区"
					},
					"12002003": {
						"region_id": "12002003",
						"region_name": "江北区"
					},
					"12002004": {
						"region_id": "12002004",
						"region_name": "北仑区"
					},
					"12002005": {
						"region_id": "12002005",
						"region_name": "镇海区"
					},
					"12002006": {
						"region_id": "12002006",
						"region_name": "象山县"
					},
					"12002007": {
						"region_id": "12002007",
						"region_name": "宁海县"
					},
					"12002008": {
						"region_id": "12002008",
						"region_name": "鄞县"
					},
					"12002009": {
						"region_id": "12002009",
						"region_name": "余姚市"
					},
					"12002010": {
						"region_id": "12002010",
						"region_name": "慈溪市"
					},
					"12002011": {
						"region_id": "12002011",
						"region_name": "奉化市"
					}
				}
			},
			"12003": {
				"region_id": "12003",
				"region_name": "温州市",
				"child": {
					"12003001": {
						"region_id": "12003001",
						"region_name": "鹿城区"
					},
					"12003002": {
						"region_id": "12003002",
						"region_name": "龙湾区"
					},
					"12003003": {
						"region_id": "12003003",
						"region_name": "瓯海区"
					},
					"12003004": {
						"region_id": "12003004",
						"region_name": "洞头县"
					},
					"12003005": {
						"region_id": "12003005",
						"region_name": "永嘉县"
					},
					"12003006": {
						"region_id": "12003006",
						"region_name": "平阳县"
					},
					"12003007": {
						"region_id": "12003007",
						"region_name": "苍南县"
					},
					"12003008": {
						"region_id": "12003008",
						"region_name": "文成县"
					},
					"12003009": {
						"region_id": "12003009",
						"region_name": "泰顺县"
					},
					"12003010": {
						"region_id": "12003010",
						"region_name": "瑞安市"
					},
					"12003011": {
						"region_id": "12003011",
						"region_name": "乐清市"
					}
				}
			},
			"12004": {
				"region_id": "12004",
				"region_name": "嘉兴市",
				"child": {
					"12004001": {
						"region_id": "12004001",
						"region_name": "秀城区"
					},
					"12004002": {
						"region_id": "12004002",
						"region_name": "郊区"
					},
					"12004003": {
						"region_id": "12004003",
						"region_name": "嘉善县"
					},
					"12004004": {
						"region_id": "12004004",
						"region_name": "海盐县"
					},
					"12004005": {
						"region_id": "12004005",
						"region_name": "海宁市"
					},
					"12004006": {
						"region_id": "12004006",
						"region_name": "平湖市"
					},
					"12004007": {
						"region_id": "12004007",
						"region_name": "桐乡市"
					}
				}
			},
			"12005": {
				"region_id": "12005",
				"region_name": "湖州市",
				"child": {
					"12005001": {
						"region_id": "12005001",
						"region_name": "吴兴区"
					},
					"12005002": {
						"region_id": "12005002",
						"region_name": "南浔区"
					},
					"12005003": {
						"region_id": "12005003",
						"region_name": "德清县"
					},
					"12005004": {
						"region_id": "12005004",
						"region_name": "长兴县"
					},
					"12005005": {
						"region_id": "12005005",
						"region_name": "安吉县"
					}
				}
			},
			"12006": {
				"region_id": "12006",
				"region_name": "绍兴市",
				"child": {
					"12006001": {
						"region_id": "12006001",
						"region_name": "越城区"
					},
					"12006002": {
						"region_id": "12006002",
						"region_name": "绍兴县"
					},
					"12006003": {
						"region_id": "12006003",
						"region_name": "新昌县"
					},
					"12006004": {
						"region_id": "12006004",
						"region_name": "诸暨市"
					},
					"12006005": {
						"region_id": "12006005",
						"region_name": "上虞市"
					},
					"12006006": {
						"region_id": "12006006",
						"region_name": "嵊州市"
					}
				}
			},
			"12007": {
				"region_id": "12007",
				"region_name": "金华市",
				"child": {
					"12007001": {
						"region_id": "12007001",
						"region_name": "婺城区"
					},
					"12007002": {
						"region_id": "12007002",
						"region_name": "金华县"
					},
					"12007003": {
						"region_id": "12007003",
						"region_name": "武义县"
					},
					"12007004": {
						"region_id": "12007004",
						"region_name": "浦江县"
					},
					"12007005": {
						"region_id": "12007005",
						"region_name": "磐安县"
					},
					"12007006": {
						"region_id": "12007006",
						"region_name": "兰溪市"
					},
					"12007007": {
						"region_id": "12007007",
						"region_name": "义乌市"
					},
					"12007008": {
						"region_id": "12007008",
						"region_name": "东阳市"
					},
					"12007009": {
						"region_id": "12007009",
						"region_name": "永康市"
					}
				}
			},
			"12008": {
				"region_id": "12008",
				"region_name": "衢州市",
				"child": {
					"12008001": {
						"region_id": "12008001",
						"region_name": "柯城区"
					},
					"12008002": {
						"region_id": "12008002",
						"region_name": "衢县"
					},
					"12008003": {
						"region_id": "12008003",
						"region_name": "常山县"
					},
					"12008004": {
						"region_id": "12008004",
						"region_name": "开化县"
					},
					"12008005": {
						"region_id": "12008005",
						"region_name": "龙游县"
					},
					"12008006": {
						"region_id": "12008006",
						"region_name": "江山市"
					}
				}
			},
			"12009": {
				"region_id": "12009",
				"region_name": "舟山市",
				"child": {
					"12009001": {
						"region_id": "12009001",
						"region_name": "定海区"
					},
					"12009002": {
						"region_id": "12009002",
						"region_name": "普陀区"
					},
					"12009003": {
						"region_id": "12009003",
						"region_name": "岱山县"
					},
					"12009004": {
						"region_id": "12009004",
						"region_name": "嵊泗县"
					}
				}
			},
			"12010": {
				"region_id": "12010",
				"region_name": "台州市",
				"child": {
					"12010001": {
						"region_id": "12010001",
						"region_name": "椒江区"
					},
					"12010002": {
						"region_id": "12010002",
						"region_name": "黄岩区"
					},
					"12010003": {
						"region_id": "12010003",
						"region_name": "路桥区"
					},
					"12010004": {
						"region_id": "12010004",
						"region_name": "玉环县"
					},
					"12010005": {
						"region_id": "12010005",
						"region_name": "三门县"
					},
					"12010006": {
						"region_id": "12010006",
						"region_name": "天台县"
					},
					"12010007": {
						"region_id": "12010007",
						"region_name": "仙居县"
					},
					"12010008": {
						"region_id": "12010008",
						"region_name": "温岭市"
					},
					"12010009": {
						"region_id": "12010009",
						"region_name": "临海市"
					}
				}
			},
			"12011": {
				"region_id": "12011",
				"region_name": "丽水市",
				"child": {
					"12011001": {
						"region_id": "12011001",
						"region_name": "丽水市"
					},
					"12011002": {
						"region_id": "12011002",
						"region_name": "龙泉市"
					},
					"12011003": {
						"region_id": "12011003",
						"region_name": "青田县"
					},
					"12011004": {
						"region_id": "12011004",
						"region_name": "云和县"
					},
					"12011005": {
						"region_id": "12011005",
						"region_name": "庆元县"
					},
					"12011006": {
						"region_id": "12011006",
						"region_name": "缙云县"
					},
					"12011007": {
						"region_id": "12011007",
						"region_name": "遂昌县"
					},
					"12011008": {
						"region_id": "12011008",
						"region_name": "松阳县"
					},
					"12011009": {
						"region_id": "12011009",
						"region_name": "景宁畲族自治县"
					}
				}
			}
		}
	},
	"13": {
		"region_id": "13",
		"region_name": "安徽省",
		"child": {
			"13001": {
				"region_id": "13001",
				"region_name": "合肥市",
				"child": {
					"13001001": {
						"region_id": "13001001",
						"region_name": "东市区"
					},
					"13001002": {
						"region_id": "13001002",
						"region_name": "中市区"
					},
					"13001003": {
						"region_id": "13001003",
						"region_name": "西市区"
					},
					"13001004": {
						"region_id": "13001004",
						"region_name": "郊区"
					},
					"13001005": {
						"region_id": "13001005",
						"region_name": "长丰县"
					},
					"13001006": {
						"region_id": "13001006",
						"region_name": "肥东县"
					},
					"13001007": {
						"region_id": "13001007",
						"region_name": "肥西县"
					}
				}
			},
			"13002": {
				"region_id": "13002",
				"region_name": "芜湖市",
				"child": {
					"13002001": {
						"region_id": "13002001",
						"region_name": "镜湖区"
					},
					"13002002": {
						"region_id": "13002002",
						"region_name": "马塘区"
					},
					"13002003": {
						"region_id": "13002003",
						"region_name": "新芜区"
					},
					"13002004": {
						"region_id": "13002004",
						"region_name": "鸠江区"
					},
					"13002005": {
						"region_id": "13002005",
						"region_name": "芜湖县"
					},
					"13002006": {
						"region_id": "13002006",
						"region_name": "繁昌县"
					},
					"13002007": {
						"region_id": "13002007",
						"region_name": "南陵县"
					}
				}
			},
			"13003": {
				"region_id": "13003",
				"region_name": "蚌埠市",
				"child": {
					"13003001": {
						"region_id": "13003001",
						"region_name": "东市区"
					},
					"13003002": {
						"region_id": "13003002",
						"region_name": "中市区"
					},
					"13003003": {
						"region_id": "13003003",
						"region_name": "西市区"
					},
					"13003004": {
						"region_id": "13003004",
						"region_name": "郊区"
					},
					"13003005": {
						"region_id": "13003005",
						"region_name": "怀远县"
					},
					"13003006": {
						"region_id": "13003006",
						"region_name": "五河县"
					},
					"13003007": {
						"region_id": "13003007",
						"region_name": "固镇县"
					}
				}
			},
			"13004": {
				"region_id": "13004",
				"region_name": "淮南市",
				"child": {
					"13004001": {
						"region_id": "13004001",
						"region_name": "大通区"
					},
					"13004002": {
						"region_id": "13004002",
						"region_name": "田家庵区"
					},
					"13004003": {
						"region_id": "13004003",
						"region_name": "谢家集区"
					},
					"13004004": {
						"region_id": "13004004",
						"region_name": "八公山区"
					},
					"13004005": {
						"region_id": "13004005",
						"region_name": "潘集区"
					},
					"13004006": {
						"region_id": "13004006",
						"region_name": "凤台县"
					}
				}
			},
			"13005": {
				"region_id": "13005",
				"region_name": "马鞍山市",
				"child": {
					"13005001": {
						"region_id": "13005001",
						"region_name": "金家庄区"
					},
					"13005002": {
						"region_id": "13005002",
						"region_name": "花山区"
					},
					"13005003": {
						"region_id": "13005003",
						"region_name": "雨山区"
					},
					"13005004": {
						"region_id": "13005004",
						"region_name": "当涂县"
					}
				}
			},
			"13006": {
				"region_id": "13006",
				"region_name": "淮北市",
				"child": {
					"13006001": {
						"region_id": "13006001",
						"region_name": "杜集区"
					},
					"13006002": {
						"region_id": "13006002",
						"region_name": "相山区"
					},
					"13006003": {
						"region_id": "13006003",
						"region_name": "烈山区"
					},
					"13006004": {
						"region_id": "13006004",
						"region_name": "濉溪县"
					}
				}
			},
			"13007": {
				"region_id": "13007",
				"region_name": "铜陵市",
				"child": {
					"13007001": {
						"region_id": "13007001",
						"region_name": "铜官山区"
					},
					"13007002": {
						"region_id": "13007002",
						"region_name": "狮子山区"
					},
					"13007003": {
						"region_id": "13007003",
						"region_name": "郊区"
					},
					"13007004": {
						"region_id": "13007004",
						"region_name": "铜陵县"
					}
				}
			},
			"13008": {
				"region_id": "13008",
				"region_name": "安庆市",
				"child": {
					"13008001": {
						"region_id": "13008001",
						"region_name": "迎江区"
					},
					"13008002": {
						"region_id": "13008002",
						"region_name": "大观区"
					},
					"13008003": {
						"region_id": "13008003",
						"region_name": "郊区"
					},
					"13008004": {
						"region_id": "13008004",
						"region_name": "怀宁县"
					},
					"13008005": {
						"region_id": "13008005",
						"region_name": "枞阳县"
					},
					"13008006": {
						"region_id": "13008006",
						"region_name": "潜山县"
					},
					"13008007": {
						"region_id": "13008007",
						"region_name": "太湖县"
					},
					"13008008": {
						"region_id": "13008008",
						"region_name": "宿松县"
					},
					"13008009": {
						"region_id": "13008009",
						"region_name": "望江县"
					},
					"13008010": {
						"region_id": "13008010",
						"region_name": "岳西县"
					},
					"13008011": {
						"region_id": "13008011",
						"region_name": "桐城市"
					}
				}
			},
			"13009": {
				"region_id": "13009",
				"region_name": "黄山市",
				"child": {
					"13009001": {
						"region_id": "13009001",
						"region_name": "屯溪区"
					},
					"13009002": {
						"region_id": "13009002",
						"region_name": "黄山区"
					},
					"13009003": {
						"region_id": "13009003",
						"region_name": "徽州区"
					},
					"13009004": {
						"region_id": "13009004",
						"region_name": "歙县"
					},
					"13009005": {
						"region_id": "13009005",
						"region_name": "休宁县"
					},
					"13009006": {
						"region_id": "13009006",
						"region_name": "黟县"
					},
					"13009007": {
						"region_id": "13009007",
						"region_name": "祁门县"
					}
				}
			},
			"13010": {
				"region_id": "13010",
				"region_name": "滁州市",
				"child": {
					"13010001": {
						"region_id": "13010001",
						"region_name": "琅琊区"
					},
					"13010002": {
						"region_id": "13010002",
						"region_name": "南谯区"
					},
					"13010003": {
						"region_id": "13010003",
						"region_name": "来安县"
					},
					"13010004": {
						"region_id": "13010004",
						"region_name": "全椒县"
					},
					"13010005": {
						"region_id": "13010005",
						"region_name": "定远县"
					},
					"13010006": {
						"region_id": "13010006",
						"region_name": "凤阳县"
					},
					"13010007": {
						"region_id": "13010007",
						"region_name": "天长市"
					},
					"13010008": {
						"region_id": "13010008",
						"region_name": "明光市"
					}
				}
			},
			"13011": {
				"region_id": "13011",
				"region_name": "阜阳市",
				"child": {
					"13011001": {
						"region_id": "13011001",
						"region_name": "颍州区"
					},
					"13011002": {
						"region_id": "13011002",
						"region_name": "颍东区"
					},
					"13011003": {
						"region_id": "13011003",
						"region_name": "颍泉区"
					},
					"13011004": {
						"region_id": "13011004",
						"region_name": "临泉县"
					},
					"13011005": {
						"region_id": "13011005",
						"region_name": "太和县"
					},
					"13011006": {
						"region_id": "13011006",
						"region_name": "阜南县"
					},
					"13011007": {
						"region_id": "13011007",
						"region_name": "颍上县"
					},
					"13011008": {
						"region_id": "13011008",
						"region_name": "界首市"
					}
				}
			},
			"13012": {
				"region_id": "13012",
				"region_name": "宿州市",
				"child": {
					"13012001": {
						"region_id": "13012001",
						"region_name": "墉桥区"
					},
					"13012002": {
						"region_id": "13012002",
						"region_name": "砀山县"
					},
					"13012003": {
						"region_id": "13012003",
						"region_name": "萧县"
					},
					"13012004": {
						"region_id": "13012004",
						"region_name": "灵璧县"
					},
					"13012005": {
						"region_id": "13012005",
						"region_name": "泗县"
					}
				}
			},
			"13013": {
				"region_id": "13013",
				"region_name": "六安市",
				"child": {
					"13013001": {
						"region_id": "13013001",
						"region_name": "金安区"
					},
					"13013002": {
						"region_id": "13013002",
						"region_name": "裕安区"
					},
					"13013003": {
						"region_id": "13013003",
						"region_name": "寿县"
					},
					"13013004": {
						"region_id": "13013004",
						"region_name": "霍邱县"
					},
					"13013005": {
						"region_id": "13013005",
						"region_name": "舒城县"
					},
					"13013006": {
						"region_id": "13013006",
						"region_name": "金寨县"
					},
					"13013007": {
						"region_id": "13013007",
						"region_name": "霍山县"
					}
				}
			},
			"13014": {
				"region_id": "13014",
				"region_name": "宣城市",
				"child": {
					"13014001": {
						"region_id": "13014001",
						"region_name": "宣州区"
					},
					"13014002": {
						"region_id": "13014002",
						"region_name": "宁国市"
					},
					"13014003": {
						"region_id": "13014003",
						"region_name": "郎溪县"
					},
					"13014004": {
						"region_id": "13014004",
						"region_name": "广德县"
					},
					"13014005": {
						"region_id": "13014005",
						"region_name": "泾县"
					},
					"13014006": {
						"region_id": "13014006",
						"region_name": "旌德县"
					},
					"13014007": {
						"region_id": "13014007",
						"region_name": "绩溪县"
					}
				}
			},
			"13015": {
				"region_id": "13015",
				"region_name": "巢湖市",
				"child": {
					"13015001": {
						"region_id": "13015001",
						"region_name": "巢湖区"
					},
					"13015002": {
						"region_id": "13015002",
						"region_name": "庐江县"
					},
					"13015003": {
						"region_id": "13015003",
						"region_name": "无为县"
					},
					"13015004": {
						"region_id": "13015004",
						"region_name": "含山县"
					},
					"13015005": {
						"region_id": "13015005",
						"region_name": "和县"
					}
				}
			},
			"13016": {
				"region_id": "13016",
				"region_name": "池州市",
				"child": {
					"13016001": {
						"region_id": "13016001",
						"region_name": "贵池区"
					},
					"13016002": {
						"region_id": "13016002",
						"region_name": "东至县"
					},
					"13016003": {
						"region_id": "13016003",
						"region_name": "石台县"
					},
					"13016004": {
						"region_id": "13016004",
						"region_name": "青阳县"
					}
				}
			}
		}
	},
	"14": {
		"region_id": "14",
		"region_name": "福建省",
		"child": {
			"14001": {
				"region_id": "14001",
				"region_name": "福州市",
				"child": {
					"14001001": {
						"region_id": "14001001",
						"region_name": "鼓楼区"
					},
					"14001002": {
						"region_id": "14001002",
						"region_name": "台江区"
					},
					"14001003": {
						"region_id": "14001003",
						"region_name": "仓山区"
					},
					"14001004": {
						"region_id": "14001004",
						"region_name": "马尾区"
					},
					"14001005": {
						"region_id": "14001005",
						"region_name": "晋安区"
					},
					"14001006": {
						"region_id": "14001006",
						"region_name": "闽侯县"
					},
					"14001007": {
						"region_id": "14001007",
						"region_name": "连江县"
					},
					"14001008": {
						"region_id": "14001008",
						"region_name": "罗源县"
					},
					"14001009": {
						"region_id": "14001009",
						"region_name": "闽清县"
					},
					"14001010": {
						"region_id": "14001010",
						"region_name": "永泰县"
					},
					"14001011": {
						"region_id": "14001011",
						"region_name": "平潭县"
					},
					"14001012": {
						"region_id": "14001012",
						"region_name": "福清市"
					},
					"14001013": {
						"region_id": "14001013",
						"region_name": "长乐市"
					}
				}
			},
			"14002": {
				"region_id": "14002",
				"region_name": "厦门市",
				"child": {
					"14002001": {
						"region_id": "14002001",
						"region_name": "思明区"
					},
					"14002002": {
						"region_id": "14002002",
						"region_name": "海沧区"
					},
					"14002003": {
						"region_id": "14002003",
						"region_name": "湖里区"
					},
					"14002004": {
						"region_id": "14002004",
						"region_name": "集美区"
					},
					"14002005": {
						"region_id": "14002005",
						"region_name": "同安区"
					},
					"14002006": {
						"region_id": "14002006",
						"region_name": "翔安区"
					}
				}
			},
			"14003": {
				"region_id": "14003",
				"region_name": "宁德市",
				"child": {
					"14003001": {
						"region_id": "14003001",
						"region_name": "蕉城区"
					},
					"14003002": {
						"region_id": "14003002",
						"region_name": "霞浦县"
					},
					"14003003": {
						"region_id": "14003003",
						"region_name": "古田县"
					},
					"14003004": {
						"region_id": "14003004",
						"region_name": "屏南县"
					},
					"14003005": {
						"region_id": "14003005",
						"region_name": "寿宁县"
					},
					"14003006": {
						"region_id": "14003006",
						"region_name": "周宁县"
					},
					"14003007": {
						"region_id": "14003007",
						"region_name": "柘荣县"
					},
					"14003008": {
						"region_id": "14003008",
						"region_name": "福安市"
					},
					"14003009": {
						"region_id": "14003009",
						"region_name": "福鼎市"
					}
				}
			},
			"14004": {
				"region_id": "14004",
				"region_name": "莆田市",
				"child": {
					"14004001": {
						"region_id": "14004001",
						"region_name": "城厢区"
					},
					"14004002": {
						"region_id": "14004002",
						"region_name": "涵江区"
					},
					"14004003": {
						"region_id": "14004003",
						"region_name": "荔城区"
					},
					"14004004": {
						"region_id": "14004004",
						"region_name": "秀屿区"
					},
					"14004005": {
						"region_id": "14004005",
						"region_name": "仙游县"
					}
				}
			},
			"14005": {
				"region_id": "14005",
				"region_name": "泉州市",
				"child": {
					"14005001": {
						"region_id": "14005001",
						"region_name": "鲤城区"
					},
					"14005002": {
						"region_id": "14005002",
						"region_name": "丰泽区"
					},
					"14005003": {
						"region_id": "14005003",
						"region_name": "洛江区"
					},
					"14005004": {
						"region_id": "14005004",
						"region_name": "泉港区"
					},
					"14005005": {
						"region_id": "14005005",
						"region_name": "惠安县"
					},
					"14005006": {
						"region_id": "14005006",
						"region_name": "安溪县"
					},
					"14005007": {
						"region_id": "14005007",
						"region_name": "永春县"
					},
					"14005008": {
						"region_id": "14005008",
						"region_name": "德化县"
					},
					"14005009": {
						"region_id": "14005009",
						"region_name": "金门县"
					},
					"14005010": {
						"region_id": "14005010",
						"region_name": "石狮市"
					},
					"14005011": {
						"region_id": "14005011",
						"region_name": "晋江市"
					},
					"14005012": {
						"region_id": "14005012",
						"region_name": "南安市"
					}
				}
			},
			"14006": {
				"region_id": "14006",
				"region_name": "漳州市",
				"child": {
					"14006001": {
						"region_id": "14006001",
						"region_name": "芗城区"
					},
					"14006002": {
						"region_id": "14006002",
						"region_name": "龙文区"
					},
					"14006003": {
						"region_id": "14006003",
						"region_name": "云霄县"
					},
					"14006004": {
						"region_id": "14006004",
						"region_name": "漳浦县"
					},
					"14006005": {
						"region_id": "14006005",
						"region_name": "诏安县"
					},
					"14006006": {
						"region_id": "14006006",
						"region_name": "长泰县"
					},
					"14006007": {
						"region_id": "14006007",
						"region_name": "东山县"
					},
					"14006008": {
						"region_id": "14006008",
						"region_name": "南靖县"
					},
					"14006009": {
						"region_id": "14006009",
						"region_name": "平和县"
					},
					"14006010": {
						"region_id": "14006010",
						"region_name": "华安县"
					},
					"14006011": {
						"region_id": "14006011",
						"region_name": "龙海市"
					}
				}
			},
			"14007": {
				"region_id": "14007",
				"region_name": "龙岩",
				"child": {
					"14007001": {
						"region_id": "14007001",
						"region_name": "新罗区"
					},
					"14007002": {
						"region_id": "14007002",
						"region_name": "长汀县"
					},
					"14007003": {
						"region_id": "14007003",
						"region_name": "永定县"
					},
					"14007004": {
						"region_id": "14007004",
						"region_name": "上杭县"
					},
					"14007005": {
						"region_id": "14007005",
						"region_name": "武平县"
					},
					"14007006": {
						"region_id": "14007006",
						"region_name": "连城县"
					},
					"14007007": {
						"region_id": "14007007",
						"region_name": "漳平市"
					}
				}
			},
			"14008": {
				"region_id": "14008",
				"region_name": "三明市",
				"child": {
					"14008001": {
						"region_id": "14008001",
						"region_name": "梅列区"
					},
					"14008002": {
						"region_id": "14008002",
						"region_name": "三元区"
					},
					"14008003": {
						"region_id": "14008003",
						"region_name": "明溪县"
					},
					"14008004": {
						"region_id": "14008004",
						"region_name": "清流县"
					},
					"14008005": {
						"region_id": "14008005",
						"region_name": "宁化县"
					},
					"14008006": {
						"region_id": "14008006",
						"region_name": "大田县"
					},
					"14008007": {
						"region_id": "14008007",
						"region_name": "尤溪县"
					},
					"14008008": {
						"region_id": "14008008",
						"region_name": "沙　县"
					},
					"14008009": {
						"region_id": "14008009",
						"region_name": "将乐县"
					},
					"14008010": {
						"region_id": "14008010",
						"region_name": "泰宁县"
					},
					"14008011": {
						"region_id": "14008011",
						"region_name": "建宁县"
					},
					"14008012": {
						"region_id": "14008012",
						"region_name": "永安市"
					}
				}
			},
			"14009": {
				"region_id": "14009",
				"region_name": "南平市",
				"child": {
					"14009001": {
						"region_id": "14009001",
						"region_name": "延平区"
					},
					"14009002": {
						"region_id": "14009002",
						"region_name": "顺昌县"
					},
					"14009003": {
						"region_id": "14009003",
						"region_name": "浦城县"
					},
					"14009004": {
						"region_id": "14009004",
						"region_name": "光泽县"
					},
					"14009005": {
						"region_id": "14009005",
						"region_name": "松溪县"
					},
					"14009006": {
						"region_id": "14009006",
						"region_name": "政和县"
					},
					"14009007": {
						"region_id": "14009007",
						"region_name": "邵武市"
					},
					"14009008": {
						"region_id": "14009008",
						"region_name": "武夷山市"
					},
					"14009009": {
						"region_id": "14009009",
						"region_name": "建瓯市"
					},
					"14009010": {
						"region_id": "14009010",
						"region_name": "建阳市"
					}
				}
			}
		}
	},
	"15": {
		"region_id": "15",
		"region_name": "江西省",
		"child": {
			"15001": {
				"region_id": "15001",
				"region_name": "南昌市",
				"child": {
					"15001001": {
						"region_id": "15001001",
						"region_name": "东湖区"
					},
					"15001002": {
						"region_id": "15001002",
						"region_name": "西湖区"
					},
					"15001003": {
						"region_id": "15001003",
						"region_name": "青云谱区"
					},
					"15001004": {
						"region_id": "15001004",
						"region_name": "湾里区"
					},
					"15001005": {
						"region_id": "15001005",
						"region_name": "郊区"
					},
					"15001006": {
						"region_id": "15001006",
						"region_name": "南昌县"
					},
					"15001007": {
						"region_id": "15001007",
						"region_name": "新建县"
					},
					"15001008": {
						"region_id": "15001008",
						"region_name": "安义县"
					},
					"15001009": {
						"region_id": "15001009",
						"region_name": "进贤县"
					}
				}
			},
			"15002": {
				"region_id": "15002",
				"region_name": "景德镇市",
				"child": {
					"15002001": {
						"region_id": "15002001",
						"region_name": "昌江区"
					},
					"15002002": {
						"region_id": "15002002",
						"region_name": "珠山区"
					},
					"15002003": {
						"region_id": "15002003",
						"region_name": "浮梁县"
					},
					"15002004": {
						"region_id": "15002004",
						"region_name": "乐平市"
					}
				}
			},
			"15003": {
				"region_id": "15003",
				"region_name": "萍乡市",
				"child": {
					"15003001": {
						"region_id": "15003001",
						"region_name": "安源区"
					},
					"15003002": {
						"region_id": "15003002",
						"region_name": "湘东区"
					},
					"15003003": {
						"region_id": "15003003",
						"region_name": "莲花县"
					},
					"15003004": {
						"region_id": "15003004",
						"region_name": "上栗县"
					},
					"15003005": {
						"region_id": "15003005",
						"region_name": "芦溪县"
					}
				}
			},
			"15004": {
				"region_id": "15004",
				"region_name": "九江市",
				"child": {
					"15004001": {
						"region_id": "15004001",
						"region_name": "庐山区"
					},
					"15004002": {
						"region_id": "15004002",
						"region_name": "浔阳区"
					},
					"15004003": {
						"region_id": "15004003",
						"region_name": "九江县"
					},
					"15004004": {
						"region_id": "15004004",
						"region_name": "武宁县"
					},
					"15004005": {
						"region_id": "15004005",
						"region_name": "修水县"
					},
					"15004006": {
						"region_id": "15004006",
						"region_name": "永修县"
					},
					"15004007": {
						"region_id": "15004007",
						"region_name": "德安县"
					},
					"15004008": {
						"region_id": "15004008",
						"region_name": "星子县"
					},
					"15004009": {
						"region_id": "15004009",
						"region_name": "都昌县"
					},
					"15004010": {
						"region_id": "15004010",
						"region_name": "湖口县"
					},
					"15004011": {
						"region_id": "15004011",
						"region_name": "彭泽县"
					},
					"15004012": {
						"region_id": "15004012",
						"region_name": "瑞昌市"
					}
				}
			},
			"15005": {
				"region_id": "15005",
				"region_name": "新余市",
				"child": {
					"15005001": {
						"region_id": "15005001",
						"region_name": "渝水区"
					},
					"15005002": {
						"region_id": "15005002",
						"region_name": "分宜县"
					}
				}
			},
			"15006": {
				"region_id": "15006",
				"region_name": "鹰潭市",
				"child": {
					"15006001": {
						"region_id": "15006001",
						"region_name": "月湖区"
					},
					"15006002": {
						"region_id": "15006002",
						"region_name": "余江县"
					},
					"15006003": {
						"region_id": "15006003",
						"region_name": "贵溪市"
					}
				}
			},
			"15007": {
				"region_id": "15007",
				"region_name": "赣州市",
				"child": {
					"15007001": {
						"region_id": "15007001",
						"region_name": "章贡区"
					},
					"15007002": {
						"region_id": "15007002",
						"region_name": "赣县"
					},
					"15007003": {
						"region_id": "15007003",
						"region_name": "信丰县"
					},
					"15007004": {
						"region_id": "15007004",
						"region_name": "大余县"
					},
					"15007005": {
						"region_id": "15007005",
						"region_name": "上犹县"
					},
					"15007006": {
						"region_id": "15007006",
						"region_name": "崇义县"
					},
					"15007007": {
						"region_id": "15007007",
						"region_name": "安远县"
					},
					"15007008": {
						"region_id": "15007008",
						"region_name": "龙南县"
					},
					"15007009": {
						"region_id": "15007009",
						"region_name": "定南县"
					},
					"15007010": {
						"region_id": "15007010",
						"region_name": "全南县"
					},
					"15007011": {
						"region_id": "15007011",
						"region_name": "宁都县"
					},
					"15007012": {
						"region_id": "15007012",
						"region_name": "于都县"
					},
					"15007013": {
						"region_id": "15007013",
						"region_name": "兴国县"
					},
					"15007014": {
						"region_id": "15007014",
						"region_name": "会昌县"
					},
					"15007015": {
						"region_id": "15007015",
						"region_name": "寻乌县"
					},
					"15007016": {
						"region_id": "15007016",
						"region_name": "石城县"
					},
					"15007017": {
						"region_id": "15007017",
						"region_name": "瑞金市"
					},
					"15007018": {
						"region_id": "15007018",
						"region_name": "南康市"
					}
				}
			},
			"15008": {
				"region_id": "15008",
				"region_name": "宜春市",
				"child": {
					"15008001": {
						"region_id": "15008001",
						"region_name": "宜春市"
					},
					"15008002": {
						"region_id": "15008002",
						"region_name": "丰城市"
					},
					"15008003": {
						"region_id": "15008003",
						"region_name": "樟树市"
					},
					"15008004": {
						"region_id": "15008004",
						"region_name": "高安市"
					},
					"15008005": {
						"region_id": "15008005",
						"region_name": "奉新县"
					},
					"15008006": {
						"region_id": "15008006",
						"region_name": "万载县"
					},
					"15008007": {
						"region_id": "15008007",
						"region_name": "上高县"
					},
					"15008008": {
						"region_id": "15008008",
						"region_name": "宜丰县"
					},
					"15008009": {
						"region_id": "15008009",
						"region_name": "靖安县"
					},
					"15008010": {
						"region_id": "15008010",
						"region_name": "铜鼓县"
					}
				}
			},
			"15009": {
				"region_id": "15009",
				"region_name": "上饶市",
				"child": {
					"15009001": {
						"region_id": "15009001",
						"region_name": "上饶市"
					},
					"15009002": {
						"region_id": "15009002",
						"region_name": "德兴市"
					},
					"15009003": {
						"region_id": "15009003",
						"region_name": "上饶县"
					},
					"15009004": {
						"region_id": "15009004",
						"region_name": "广丰县"
					},
					"15009005": {
						"region_id": "15009005",
						"region_name": "玉山县"
					},
					"15009006": {
						"region_id": "15009006",
						"region_name": "铅山县"
					},
					"15009007": {
						"region_id": "15009007",
						"region_name": "横峰县"
					},
					"15009008": {
						"region_id": "15009008",
						"region_name": "弋阳县"
					},
					"15009009": {
						"region_id": "15009009",
						"region_name": "余干县"
					},
					"15009010": {
						"region_id": "15009010",
						"region_name": "波阳县"
					},
					"15009011": {
						"region_id": "15009011",
						"region_name": "万年县"
					},
					"15009012": {
						"region_id": "15009012",
						"region_name": "婺源县"
					}
				}
			},
			"15010": {
				"region_id": "15010",
				"region_name": "吉安市",
				"child": {
					"15010001": {
						"region_id": "15010001",
						"region_name": "吉安市"
					},
					"15010002": {
						"region_id": "15010002",
						"region_name": "井冈山市"
					},
					"15010003": {
						"region_id": "15010003",
						"region_name": "吉安县"
					},
					"15010004": {
						"region_id": "15010004",
						"region_name": "吉水县"
					},
					"15010005": {
						"region_id": "15010005",
						"region_name": "峡江县"
					},
					"15010006": {
						"region_id": "15010006",
						"region_name": "新干县"
					},
					"15010007": {
						"region_id": "15010007",
						"region_name": "永丰县"
					},
					"15010008": {
						"region_id": "15010008",
						"region_name": "泰和县"
					},
					"15010009": {
						"region_id": "15010009",
						"region_name": "遂川县"
					},
					"15010010": {
						"region_id": "15010010",
						"region_name": "万安县"
					},
					"15010011": {
						"region_id": "15010011",
						"region_name": "安福县"
					},
					"15010012": {
						"region_id": "15010012",
						"region_name": "永新县"
					},
					"15010013": {
						"region_id": "15010013",
						"region_name": "宁冈县"
					}
				}
			},
			"15011": {
				"region_id": "15011",
				"region_name": "抚州市",
				"child": {
					"15011001": {
						"region_id": "15011001",
						"region_name": "临川市"
					},
					"15011002": {
						"region_id": "15011002",
						"region_name": "南城县"
					},
					"15011003": {
						"region_id": "15011003",
						"region_name": "黎川县"
					},
					"15011004": {
						"region_id": "15011004",
						"region_name": "南丰县"
					},
					"15011005": {
						"region_id": "15011005",
						"region_name": "崇仁县"
					},
					"15011006": {
						"region_id": "15011006",
						"region_name": "乐安县"
					},
					"15011007": {
						"region_id": "15011007",
						"region_name": "宜黄县"
					},
					"15011008": {
						"region_id": "15011008",
						"region_name": "金溪县"
					},
					"15011009": {
						"region_id": "15011009",
						"region_name": "资溪县"
					},
					"15011010": {
						"region_id": "15011010",
						"region_name": "东乡县"
					},
					"15011011": {
						"region_id": "15011011",
						"region_name": "广昌县"
					}
				}
			}
		}
	},
	"16": {
		"region_id": "16",
		"region_name": "山东省",
		"child": {
			"16001": {
				"region_id": "16001",
				"region_name": "济南市",
				"child": {
					"16001001": {
						"region_id": "16001001",
						"region_name": "历下区"
					},
					"16001002": {
						"region_id": "16001002",
						"region_name": "市中区"
					},
					"16001003": {
						"region_id": "16001003",
						"region_name": "槐荫区"
					},
					"16001004": {
						"region_id": "16001004",
						"region_name": "天桥区"
					},
					"16001005": {
						"region_id": "16001005",
						"region_name": "历城区"
					},
					"16001006": {
						"region_id": "16001006",
						"region_name": "长清县"
					},
					"16001007": {
						"region_id": "16001007",
						"region_name": "平阴县"
					},
					"16001008": {
						"region_id": "16001008",
						"region_name": "济阳县"
					},
					"16001009": {
						"region_id": "16001009",
						"region_name": "商河县"
					},
					"16001010": {
						"region_id": "16001010",
						"region_name": "章丘市"
					}
				}
			},
			"16002": {
				"region_id": "16002",
				"region_name": "青岛市",
				"child": {
					"16002001": {
						"region_id": "16002001",
						"region_name": "市南区"
					},
					"16002002": {
						"region_id": "16002002",
						"region_name": "市北区"
					},
					"16002003": {
						"region_id": "16002003",
						"region_name": "四方区"
					},
					"16002004": {
						"region_id": "16002004",
						"region_name": "黄岛区"
					},
					"16002005": {
						"region_id": "16002005",
						"region_name": "崂山区"
					},
					"16002006": {
						"region_id": "16002006",
						"region_name": "李沧区"
					},
					"16002007": {
						"region_id": "16002007",
						"region_name": "城阳区"
					},
					"16002008": {
						"region_id": "16002008",
						"region_name": "胶州市"
					},
					"16002009": {
						"region_id": "16002009",
						"region_name": "即墨市"
					},
					"16002010": {
						"region_id": "16002010",
						"region_name": "平度市"
					},
					"16002011": {
						"region_id": "16002011",
						"region_name": "胶南市"
					},
					"16002012": {
						"region_id": "16002012",
						"region_name": "莱西市"
					}
				}
			},
			"16003": {
				"region_id": "16003",
				"region_name": "淄博市",
				"child": {
					"16003001": {
						"region_id": "16003001",
						"region_name": "淄川区"
					},
					"16003002": {
						"region_id": "16003002",
						"region_name": "张店区"
					},
					"16003003": {
						"region_id": "16003003",
						"region_name": "博山区"
					},
					"16003004": {
						"region_id": "16003004",
						"region_name": "临淄区"
					},
					"16003005": {
						"region_id": "16003005",
						"region_name": "周村区"
					},
					"16003006": {
						"region_id": "16003006",
						"region_name": "桓台县"
					},
					"16003007": {
						"region_id": "16003007",
						"region_name": "高青县"
					},
					"16003008": {
						"region_id": "16003008",
						"region_name": "沂源县"
					}
				}
			},
			"16004": {
				"region_id": "16004",
				"region_name": "枣庄市",
				"child": {
					"16004001": {
						"region_id": "16004001",
						"region_name": "市中区"
					},
					"16004002": {
						"region_id": "16004002",
						"region_name": "薛城区"
					},
					"16004003": {
						"region_id": "16004003",
						"region_name": "峄城区"
					},
					"16004004": {
						"region_id": "16004004",
						"region_name": "台儿庄区"
					},
					"16004005": {
						"region_id": "16004005",
						"region_name": "山亭区"
					},
					"16004006": {
						"region_id": "16004006",
						"region_name": "滕州市"
					}
				}
			},
			"16005": {
				"region_id": "16005",
				"region_name": "东营市",
				"child": {
					"16005001": {
						"region_id": "16005001",
						"region_name": "东营区"
					},
					"16005002": {
						"region_id": "16005002",
						"region_name": "河口区"
					},
					"16005003": {
						"region_id": "16005003",
						"region_name": "垦利县"
					},
					"16005004": {
						"region_id": "16005004",
						"region_name": "利津县"
					},
					"16005005": {
						"region_id": "16005005",
						"region_name": "广饶县"
					}
				}
			},
			"16006": {
				"region_id": "16006",
				"region_name": "烟台市",
				"child": {
					"16006001": {
						"region_id": "16006001",
						"region_name": "芝罘区"
					},
					"16006002": {
						"region_id": "16006002",
						"region_name": "福山区"
					},
					"16006003": {
						"region_id": "16006003",
						"region_name": "牟平区"
					},
					"16006004": {
						"region_id": "16006004",
						"region_name": "莱山区"
					},
					"16006005": {
						"region_id": "16006005",
						"region_name": "长岛县"
					},
					"16006006": {
						"region_id": "16006006",
						"region_name": "龙口市"
					},
					"16006007": {
						"region_id": "16006007",
						"region_name": "莱阳市"
					},
					"16006008": {
						"region_id": "16006008",
						"region_name": "莱州市"
					},
					"16006009": {
						"region_id": "16006009",
						"region_name": "蓬莱市"
					},
					"16006010": {
						"region_id": "16006010",
						"region_name": "招远市"
					},
					"16006011": {
						"region_id": "16006011",
						"region_name": "栖霞市"
					},
					"16006012": {
						"region_id": "16006012",
						"region_name": "海阳市"
					}
				}
			},
			"16007": {
				"region_id": "16007",
				"region_name": "潍坊市",
				"child": {
					"16007001": {
						"region_id": "16007001",
						"region_name": "潍城区"
					},
					"16007002": {
						"region_id": "16007002",
						"region_name": "寒亭区"
					},
					"16007003": {
						"region_id": "16007003",
						"region_name": "坊子区"
					},
					"16007004": {
						"region_id": "16007004",
						"region_name": "奎文区"
					},
					"16007005": {
						"region_id": "16007005",
						"region_name": "临朐县"
					},
					"16007006": {
						"region_id": "16007006",
						"region_name": "昌乐县"
					},
					"16007007": {
						"region_id": "16007007",
						"region_name": "青州市"
					},
					"16007008": {
						"region_id": "16007008",
						"region_name": "诸城市"
					},
					"16007009": {
						"region_id": "16007009",
						"region_name": "寿光市"
					},
					"16007010": {
						"region_id": "16007010",
						"region_name": "安丘市"
					},
					"16007011": {
						"region_id": "16007011",
						"region_name": "高密市"
					},
					"16007012": {
						"region_id": "16007012",
						"region_name": "昌邑市"
					}
				}
			},
			"16008": {
				"region_id": "16008",
				"region_name": "济宁市",
				"child": {
					"16008001": {
						"region_id": "16008001",
						"region_name": "市中区"
					},
					"16008002": {
						"region_id": "16008002",
						"region_name": "任城区"
					},
					"16008003": {
						"region_id": "16008003",
						"region_name": "微山县"
					},
					"16008004": {
						"region_id": "16008004",
						"region_name": "鱼台县"
					},
					"16008005": {
						"region_id": "16008005",
						"region_name": "金乡县"
					},
					"16008006": {
						"region_id": "16008006",
						"region_name": "嘉祥县"
					},
					"16008007": {
						"region_id": "16008007",
						"region_name": "汶上县"
					},
					"16008008": {
						"region_id": "16008008",
						"region_name": "泗水县"
					},
					"16008009": {
						"region_id": "16008009",
						"region_name": "梁山县"
					},
					"16008010": {
						"region_id": "16008010",
						"region_name": "曲阜市"
					},
					"16008011": {
						"region_id": "16008011",
						"region_name": "兖州市"
					},
					"16008012": {
						"region_id": "16008012",
						"region_name": "邹城市"
					}
				}
			},
			"16009": {
				"region_id": "16009",
				"region_name": "泰安市",
				"child": {
					"16009001": {
						"region_id": "16009001",
						"region_name": "泰山区"
					},
					"16009002": {
						"region_id": "16009002",
						"region_name": "郊区"
					},
					"16009003": {
						"region_id": "16009003",
						"region_name": "宁阳县"
					},
					"16009004": {
						"region_id": "16009004",
						"region_name": "东平县"
					},
					"16009005": {
						"region_id": "16009005",
						"region_name": "新泰市"
					},
					"16009006": {
						"region_id": "16009006",
						"region_name": "肥城市"
					}
				}
			},
			"16010": {
				"region_id": "16010",
				"region_name": "威海市",
				"child": {
					"16010001": {
						"region_id": "16010001",
						"region_name": "环翠区"
					},
					"16010002": {
						"region_id": "16010002",
						"region_name": "文登市"
					},
					"16010003": {
						"region_id": "16010003",
						"region_name": "荣成市"
					},
					"16010004": {
						"region_id": "16010004",
						"region_name": "乳山市"
					}
				}
			},
			"16011": {
				"region_id": "16011",
				"region_name": "日照市",
				"child": {
					"16011001": {
						"region_id": "16011001",
						"region_name": "东港区"
					},
					"16011002": {
						"region_id": "16011002",
						"region_name": "岚山区"
					},
					"16011003": {
						"region_id": "16011003",
						"region_name": "五莲县"
					},
					"16011004": {
						"region_id": "16011004",
						"region_name": "莒县"
					}
				}
			},
			"16012": {
				"region_id": "16012",
				"region_name": "莱芜市",
				"child": {
					"16012001": {
						"region_id": "16012001",
						"region_name": "莱城区"
					},
					"16012002": {
						"region_id": "16012002",
						"region_name": "钢城区"
					}
				}
			},
			"16013": {
				"region_id": "16013",
				"region_name": "临沂市",
				"child": {
					"16013001": {
						"region_id": "16013001",
						"region_name": "兰山区"
					},
					"16013002": {
						"region_id": "16013002",
						"region_name": "罗庄区"
					},
					"16013003": {
						"region_id": "16013003",
						"region_name": "河东区"
					},
					"16013004": {
						"region_id": "16013004",
						"region_name": "沂南县"
					},
					"16013005": {
						"region_id": "16013005",
						"region_name": "郯城县"
					},
					"16013006": {
						"region_id": "16013006",
						"region_name": "沂水县"
					},
					"16013007": {
						"region_id": "16013007",
						"region_name": "苍山县"
					},
					"16013008": {
						"region_id": "16013008",
						"region_name": "费县"
					},
					"16013009": {
						"region_id": "16013009",
						"region_name": "平邑县"
					},
					"16013010": {
						"region_id": "16013010",
						"region_name": "莒南县"
					},
					"16013011": {
						"region_id": "16013011",
						"region_name": "蒙阴县"
					},
					"16013012": {
						"region_id": "16013012",
						"region_name": "临沭县"
					}
				}
			},
			"16014": {
				"region_id": "16014",
				"region_name": "德州市",
				"child": {
					"16014001": {
						"region_id": "16014001",
						"region_name": "德城区"
					},
					"16014002": {
						"region_id": "16014002",
						"region_name": "陵县"
					},
					"16014003": {
						"region_id": "16014003",
						"region_name": "宁津县"
					},
					"16014004": {
						"region_id": "16014004",
						"region_name": "庆云县"
					},
					"16014005": {
						"region_id": "16014005",
						"region_name": "临邑县"
					},
					"16014006": {
						"region_id": "16014006",
						"region_name": "齐河县"
					},
					"16014007": {
						"region_id": "16014007",
						"region_name": "平原县"
					},
					"16014008": {
						"region_id": "16014008",
						"region_name": "夏津县"
					},
					"16014009": {
						"region_id": "16014009",
						"region_name": "武城县"
					},
					"16014010": {
						"region_id": "16014010",
						"region_name": "乐陵市"
					},
					"16014011": {
						"region_id": "16014011",
						"region_name": "禹城市"
					}
				}
			},
			"16015": {
				"region_id": "16015",
				"region_name": "聊城市",
				"child": {
					"16015001": {
						"region_id": "16015001",
						"region_name": "东昌府区"
					},
					"16015002": {
						"region_id": "16015002",
						"region_name": "阳谷县"
					},
					"16015003": {
						"region_id": "16015003",
						"region_name": "莘县"
					},
					"16015004": {
						"region_id": "16015004",
						"region_name": "茌平县"
					},
					"16015005": {
						"region_id": "16015005",
						"region_name": "东阿县"
					},
					"16015006": {
						"region_id": "16015006",
						"region_name": "冠县"
					},
					"16015007": {
						"region_id": "16015007",
						"region_name": "高唐县"
					},
					"16015008": {
						"region_id": "16015008",
						"region_name": "临清市"
					}
				}
			},
			"16016": {
				"region_id": "16016",
				"region_name": "滨州地区",
				"child": {
					"16016001": {
						"region_id": "16016001",
						"region_name": "滨州市"
					},
					"16016002": {
						"region_id": "16016002",
						"region_name": "惠民县"
					},
					"16016003": {
						"region_id": "16016003",
						"region_name": "阳信县"
					},
					"16016004": {
						"region_id": "16016004",
						"region_name": "无棣县"
					},
					"16016005": {
						"region_id": "16016005",
						"region_name": "沾化县"
					},
					"16016006": {
						"region_id": "16016006",
						"region_name": "博兴县"
					},
					"16016007": {
						"region_id": "16016007",
						"region_name": "邹平县"
					}
				}
			},
			"16017": {
				"region_id": "16017",
				"region_name": "菏泽地区",
				"child": {
					"16017001": {
						"region_id": "16017001",
						"region_name": "菏泽市"
					},
					"16017002": {
						"region_id": "16017002",
						"region_name": "曹县"
					},
					"16017003": {
						"region_id": "16017003",
						"region_name": "定陶县"
					},
					"16017004": {
						"region_id": "16017004",
						"region_name": "成武县"
					},
					"16017005": {
						"region_id": "16017005",
						"region_name": "单县"
					},
					"16017006": {
						"region_id": "16017006",
						"region_name": "巨野县"
					},
					"16017007": {
						"region_id": "16017007",
						"region_name": "郓城县"
					},
					"16017008": {
						"region_id": "16017008",
						"region_name": "鄄城县"
					},
					"16017009": {
						"region_id": "16017009",
						"region_name": "东明县"
					}
				}
			}
		}
	},
	"17": {
		"region_id": "17",
		"region_name": "河南省",
		"child": {
			"17001": {
				"region_id": "17001",
				"region_name": "郑州市",
				"child": {
					"17001001": {
						"region_id": "17001001",
						"region_name": "中原区"
					},
					"17001002": {
						"region_id": "17001002",
						"region_name": "二七区"
					},
					"17001003": {
						"region_id": "17001003",
						"region_name": "管城回族区"
					},
					"17001004": {
						"region_id": "17001004",
						"region_name": "金水区"
					},
					"17001005": {
						"region_id": "17001005",
						"region_name": "上街区"
					},
					"17001006": {
						"region_id": "17001006",
						"region_name": "邙山区"
					},
					"17001007": {
						"region_id": "17001007",
						"region_name": "中牟县"
					},
					"17001008": {
						"region_id": "17001008",
						"region_name": "巩义市"
					},
					"17001009": {
						"region_id": "17001009",
						"region_name": "荥阳市"
					},
					"17001010": {
						"region_id": "17001010",
						"region_name": "新密市"
					},
					"17001011": {
						"region_id": "17001011",
						"region_name": "新郑市"
					},
					"17001012": {
						"region_id": "17001012",
						"region_name": "登封市"
					}
				}
			},
			"17002": {
				"region_id": "17002",
				"region_name": "开封市",
				"child": {
					"17002001": {
						"region_id": "17002001",
						"region_name": "龙亭区"
					},
					"17002002": {
						"region_id": "17002002",
						"region_name": "顺河回族区"
					},
					"17002003": {
						"region_id": "17002003",
						"region_name": "鼓楼区"
					},
					"17002004": {
						"region_id": "17002004",
						"region_name": "南关区"
					},
					"17002005": {
						"region_id": "17002005",
						"region_name": "郊区"
					},
					"17002006": {
						"region_id": "17002006",
						"region_name": "杞县"
					},
					"17002007": {
						"region_id": "17002007",
						"region_name": "通许县"
					},
					"17002008": {
						"region_id": "17002008",
						"region_name": "尉氏县"
					},
					"17002009": {
						"region_id": "17002009",
						"region_name": "开封县"
					},
					"17002010": {
						"region_id": "17002010",
						"region_name": "兰考县"
					}
				}
			},
			"17003": {
				"region_id": "17003",
				"region_name": "洛阳市",
				"child": {
					"17003001": {
						"region_id": "17003001",
						"region_name": "老城区"
					},
					"17003002": {
						"region_id": "17003002",
						"region_name": "西工区"
					},
					"17003003": {
						"region_id": "17003003",
						"region_name": "廛河回族区"
					},
					"17003004": {
						"region_id": "17003004",
						"region_name": "涧西区"
					},
					"17003005": {
						"region_id": "17003005",
						"region_name": "吉利区"
					},
					"17003006": {
						"region_id": "17003006",
						"region_name": "郊区"
					},
					"17003007": {
						"region_id": "17003007",
						"region_name": "孟津县"
					},
					"17003008": {
						"region_id": "17003008",
						"region_name": "新安县"
					},
					"17003009": {
						"region_id": "17003009",
						"region_name": "栾川县"
					},
					"17003010": {
						"region_id": "17003010",
						"region_name": "嵩县"
					},
					"17003011": {
						"region_id": "17003011",
						"region_name": "汝阳县"
					},
					"17003012": {
						"region_id": "17003012",
						"region_name": "宜阳县"
					},
					"17003013": {
						"region_id": "17003013",
						"region_name": "洛宁县"
					},
					"17003014": {
						"region_id": "17003014",
						"region_name": "伊川县"
					},
					"17003015": {
						"region_id": "17003015",
						"region_name": "偃师市"
					}
				}
			},
			"17004": {
				"region_id": "17004",
				"region_name": "平顶山市",
				"child": {
					"17004001": {
						"region_id": "17004001",
						"region_name": "新华区"
					},
					"17004002": {
						"region_id": "17004002",
						"region_name": "卫东区"
					},
					"17004003": {
						"region_id": "17004003",
						"region_name": "石龙区"
					},
					"17004004": {
						"region_id": "17004004",
						"region_name": "湛河区"
					},
					"17004005": {
						"region_id": "17004005",
						"region_name": "宝丰县"
					},
					"17004006": {
						"region_id": "17004006",
						"region_name": "叶县"
					},
					"17004007": {
						"region_id": "17004007",
						"region_name": "鲁山县"
					},
					"17004008": {
						"region_id": "17004008",
						"region_name": "郏县"
					},
					"17004009": {
						"region_id": "17004009",
						"region_name": "舞钢市"
					},
					"17004010": {
						"region_id": "17004010",
						"region_name": "汝州市"
					}
				}
			},
			"17005": {
				"region_id": "17005",
				"region_name": "安阳市",
				"child": {
					"17005001": {
						"region_id": "17005001",
						"region_name": "文峰区"
					},
					"17005002": {
						"region_id": "17005002",
						"region_name": "北关区"
					},
					"17005003": {
						"region_id": "17005003",
						"region_name": "铁西区"
					},
					"17005004": {
						"region_id": "17005004",
						"region_name": "郊区"
					},
					"17005005": {
						"region_id": "17005005",
						"region_name": "安阳县"
					},
					"17005006": {
						"region_id": "17005006",
						"region_name": "汤阴县"
					},
					"17005007": {
						"region_id": "17005007",
						"region_name": "滑县"
					},
					"17005008": {
						"region_id": "17005008",
						"region_name": "内黄县"
					},
					"17005009": {
						"region_id": "17005009",
						"region_name": "林州市"
					}
				}
			},
			"17006": {
				"region_id": "17006",
				"region_name": "鹤壁市",
				"child": {
					"17006001": {
						"region_id": "17006001",
						"region_name": "鹤山区"
					},
					"17006002": {
						"region_id": "17006002",
						"region_name": "山城区"
					},
					"17006003": {
						"region_id": "17006003",
						"region_name": "郊区"
					},
					"17006004": {
						"region_id": "17006004",
						"region_name": "浚县"
					},
					"17006005": {
						"region_id": "17006005",
						"region_name": "淇县"
					}
				}
			},
			"17007": {
				"region_id": "17007",
				"region_name": "新乡市",
				"child": {
					"17007001": {
						"region_id": "17007001",
						"region_name": "红旗区"
					},
					"17007002": {
						"region_id": "17007002",
						"region_name": "新华区"
					},
					"17007003": {
						"region_id": "17007003",
						"region_name": "北站区"
					},
					"17007004": {
						"region_id": "17007004",
						"region_name": "郊区"
					},
					"17007005": {
						"region_id": "17007005",
						"region_name": "新乡县"
					},
					"17007006": {
						"region_id": "17007006",
						"region_name": "获嘉县"
					},
					"17007007": {
						"region_id": "17007007",
						"region_name": "原阳县"
					},
					"17007008": {
						"region_id": "17007008",
						"region_name": "延津县"
					},
					"17007009": {
						"region_id": "17007009",
						"region_name": "封丘县"
					},
					"17007010": {
						"region_id": "17007010",
						"region_name": "长垣县"
					},
					"17007011": {
						"region_id": "17007011",
						"region_name": "卫辉市"
					},
					"17007012": {
						"region_id": "17007012",
						"region_name": "辉县市"
					}
				}
			},
			"17008": {
				"region_id": "17008",
				"region_name": "焦作市",
				"child": {
					"17008001": {
						"region_id": "17008001",
						"region_name": "解放区"
					},
					"17008002": {
						"region_id": "17008002",
						"region_name": "中站区"
					},
					"17008003": {
						"region_id": "17008003",
						"region_name": "马村区"
					},
					"17008004": {
						"region_id": "17008004",
						"region_name": "山阳区"
					},
					"17008005": {
						"region_id": "17008005",
						"region_name": "修武县"
					},
					"17008006": {
						"region_id": "17008006",
						"region_name": "博爱县"
					},
					"17008007": {
						"region_id": "17008007",
						"region_name": "武陟县"
					},
					"17008008": {
						"region_id": "17008008",
						"region_name": "温县"
					},
					"17008009": {
						"region_id": "17008009",
						"region_name": "沁阳市"
					},
					"17008010": {
						"region_id": "17008010",
						"region_name": "孟州市"
					}
				}
			},
			"17009": {
				"region_id": "17009",
				"region_name": "濮阳市",
				"child": {
					"17009001": {
						"region_id": "17009001",
						"region_name": "华龙区"
					},
					"17009002": {
						"region_id": "17009002",
						"region_name": "清丰县"
					},
					"17009003": {
						"region_id": "17009003",
						"region_name": "南乐县"
					},
					"17009004": {
						"region_id": "17009004",
						"region_name": "范县"
					},
					"17009005": {
						"region_id": "17009005",
						"region_name": "台前县"
					},
					"17009006": {
						"region_id": "17009006",
						"region_name": "濮阳县"
					}
				}
			},
			"17010": {
				"region_id": "17010",
				"region_name": "许昌市",
				"child": {
					"17010001": {
						"region_id": "17010001",
						"region_name": "魏都区"
					},
					"17010002": {
						"region_id": "17010002",
						"region_name": "许昌县"
					},
					"17010003": {
						"region_id": "17010003",
						"region_name": "鄢陵县"
					},
					"17010004": {
						"region_id": "17010004",
						"region_name": "襄城县"
					},
					"17010005": {
						"region_id": "17010005",
						"region_name": "禹州市"
					},
					"17010006": {
						"region_id": "17010006",
						"region_name": "长葛市"
					}
				}
			},
			"17011": {
				"region_id": "17011",
				"region_name": "漯河市",
				"child": {
					"17011001": {
						"region_id": "17011001",
						"region_name": "源汇区"
					},
					"17011002": {
						"region_id": "17011002",
						"region_name": "郾城区"
					},
					"17011003": {
						"region_id": "17011003",
						"region_name": "召陵区"
					},
					"17011004": {
						"region_id": "17011004",
						"region_name": "舞阳县"
					},
					"17011005": {
						"region_id": "17011005",
						"region_name": "临颍县"
					}
				}
			},
			"17012": {
				"region_id": "17012",
				"region_name": "三门峡市",
				"child": {
					"17012001": {
						"region_id": "17012001",
						"region_name": "湖滨区"
					},
					"17012002": {
						"region_id": "17012002",
						"region_name": "渑池县"
					},
					"17012003": {
						"region_id": "17012003",
						"region_name": "陕县"
					},
					"17012004": {
						"region_id": "17012004",
						"region_name": "卢氏县"
					},
					"17012005": {
						"region_id": "17012005",
						"region_name": "义马市"
					},
					"17012006": {
						"region_id": "17012006",
						"region_name": "灵宝市"
					}
				}
			},
			"17013": {
				"region_id": "17013",
				"region_name": "南阳市",
				"child": {
					"17013001": {
						"region_id": "17013001",
						"region_name": "宛城区"
					},
					"17013002": {
						"region_id": "17013002",
						"region_name": "卧龙区"
					},
					"17013003": {
						"region_id": "17013003",
						"region_name": "南召县"
					},
					"17013004": {
						"region_id": "17013004",
						"region_name": "方城县"
					},
					"17013005": {
						"region_id": "17013005",
						"region_name": "西峡县"
					},
					"17013006": {
						"region_id": "17013006",
						"region_name": "镇平县"
					},
					"17013007": {
						"region_id": "17013007",
						"region_name": "内乡县"
					},
					"17013008": {
						"region_id": "17013008",
						"region_name": "淅川县"
					},
					"17013009": {
						"region_id": "17013009",
						"region_name": "社旗县"
					},
					"17013010": {
						"region_id": "17013010",
						"region_name": "唐河县"
					},
					"17013011": {
						"region_id": "17013011",
						"region_name": "新野县"
					},
					"17013012": {
						"region_id": "17013012",
						"region_name": "桐柏县"
					},
					"17013013": {
						"region_id": "17013013",
						"region_name": "邓州市"
					}
				}
			},
			"17014": {
				"region_id": "17014",
				"region_name": "商丘市",
				"child": {
					"17014001": {
						"region_id": "17014001",
						"region_name": "梁园区"
					},
					"17014002": {
						"region_id": "17014002",
						"region_name": "睢阳区"
					},
					"17014003": {
						"region_id": "17014003",
						"region_name": "民权县"
					},
					"17014004": {
						"region_id": "17014004",
						"region_name": "睢县"
					},
					"17014005": {
						"region_id": "17014005",
						"region_name": "宁陵县"
					},
					"17014006": {
						"region_id": "17014006",
						"region_name": "柘城县"
					},
					"17014007": {
						"region_id": "17014007",
						"region_name": "虞城县"
					},
					"17014008": {
						"region_id": "17014008",
						"region_name": "夏邑县"
					},
					"17014009": {
						"region_id": "17014009",
						"region_name": "永城市"
					}
				}
			},
			"17015": {
				"region_id": "17015",
				"region_name": "信阳市",
				"child": {
					"17015001": {
						"region_id": "17015001",
						"region_name": "浉河区"
					},
					"17015002": {
						"region_id": "17015002",
						"region_name": "平桥区"
					},
					"17015003": {
						"region_id": "17015003",
						"region_name": "罗山县"
					},
					"17015004": {
						"region_id": "17015004",
						"region_name": "光山县"
					},
					"17015005": {
						"region_id": "17015005",
						"region_name": "新县"
					},
					"17015006": {
						"region_id": "17015006",
						"region_name": "商城县"
					},
					"17015007": {
						"region_id": "17015007",
						"region_name": "固始县"
					},
					"17015008": {
						"region_id": "17015008",
						"region_name": "潢川县"
					},
					"17015009": {
						"region_id": "17015009",
						"region_name": "淮滨县"
					},
					"17015010": {
						"region_id": "17015010",
						"region_name": "息县"
					}
				}
			},
			"17016": {
				"region_id": "17016",
				"region_name": "周口市",
				"child": {
					"17016001": {
						"region_id": "17016001",
						"region_name": "周口市"
					},
					"17016002": {
						"region_id": "17016002",
						"region_name": "项城市"
					},
					"17016003": {
						"region_id": "17016003",
						"region_name": "扶沟县"
					},
					"17016004": {
						"region_id": "17016004",
						"region_name": "西华县"
					},
					"17016005": {
						"region_id": "17016005",
						"region_name": "商水县"
					},
					"17016006": {
						"region_id": "17016006",
						"region_name": "太康县"
					},
					"17016007": {
						"region_id": "17016007",
						"region_name": "鹿邑县"
					},
					"17016008": {
						"region_id": "17016008",
						"region_name": "郸城县"
					},
					"17016009": {
						"region_id": "17016009",
						"region_name": "淮阳县"
					},
					"17016010": {
						"region_id": "17016010",
						"region_name": "沈丘县"
					}
				}
			},
			"17017": {
				"region_id": "17017",
				"region_name": "驻马店地区",
				"child": {
					"17017001": {
						"region_id": "17017001",
						"region_name": "驻马店市"
					},
					"17017002": {
						"region_id": "17017002",
						"region_name": "确山县"
					},
					"17017003": {
						"region_id": "17017003",
						"region_name": "泌阳县"
					},
					"17017004": {
						"region_id": "17017004",
						"region_name": "遂平县"
					},
					"17017005": {
						"region_id": "17017005",
						"region_name": "西平县"
					},
					"17017006": {
						"region_id": "17017006",
						"region_name": "上蔡县"
					},
					"17017007": {
						"region_id": "17017007",
						"region_name": "汝南县"
					},
					"17017008": {
						"region_id": "17017008",
						"region_name": "平舆县"
					},
					"17017009": {
						"region_id": "17017009",
						"region_name": "新蔡县"
					},
					"17017010": {
						"region_id": "17017010",
						"region_name": "正阳县"
					}
				}
			},
			"17018": {
				"region_id": "17018",
				"region_name": "省直辖行政单位",
				"child": {
					"17018001": {
						"region_id": "17018001",
						"region_name": "济源市"
					}
				}
			}
		}
	},
	"18": {
		"region_id": "18",
		"region_name": "湖北省",
		"child": {
			"18001": {
				"region_id": "18001",
				"region_name": "武汉市",
				"child": {
					"18001001": {
						"region_id": "18001001",
						"region_name": "江岸区"
					},
					"18001002": {
						"region_id": "18001002",
						"region_name": "江汉区"
					},
					"18001003": {
						"region_id": "18001003",
						"region_name": "乔口区"
					},
					"18001004": {
						"region_id": "18001004",
						"region_name": "汉阳区"
					},
					"18001005": {
						"region_id": "18001005",
						"region_name": "武昌区"
					},
					"18001006": {
						"region_id": "18001006",
						"region_name": "青山区"
					},
					"18001007": {
						"region_id": "18001007",
						"region_name": "洪山区"
					},
					"18001008": {
						"region_id": "18001008",
						"region_name": "东西湖区"
					},
					"18001009": {
						"region_id": "18001009",
						"region_name": "汉南区"
					},
					"18001010": {
						"region_id": "18001010",
						"region_name": "蔡甸区"
					},
					"18001011": {
						"region_id": "18001011",
						"region_name": "江夏区"
					},
					"18001012": {
						"region_id": "18001012",
						"region_name": "黄陂区"
					},
					"18001013": {
						"region_id": "18001013",
						"region_name": "新洲区"
					}
				}
			},
			"18002": {
				"region_id": "18002",
				"region_name": "黄石市",
				"child": {
					"18002001": {
						"region_id": "18002001",
						"region_name": "黄石港区"
					},
					"18002002": {
						"region_id": "18002002",
						"region_name": "石灰窑区"
					},
					"18002003": {
						"region_id": "18002003",
						"region_name": "下陆区"
					},
					"18002004": {
						"region_id": "18002004",
						"region_name": "铁山区"
					},
					"18002005": {
						"region_id": "18002005",
						"region_name": "阳新县"
					},
					"18002006": {
						"region_id": "18002006",
						"region_name": "大冶市"
					}
				}
			},
			"18003": {
				"region_id": "18003",
				"region_name": "十堰市",
				"child": {
					"18003001": {
						"region_id": "18003001",
						"region_name": "茅箭区"
					},
					"18003002": {
						"region_id": "18003002",
						"region_name": "张湾区"
					},
					"18003003": {
						"region_id": "18003003",
						"region_name": "郧县"
					},
					"18003004": {
						"region_id": "18003004",
						"region_name": "郧西县"
					},
					"18003005": {
						"region_id": "18003005",
						"region_name": "竹山县"
					},
					"18003006": {
						"region_id": "18003006",
						"region_name": "竹溪县"
					},
					"18003007": {
						"region_id": "18003007",
						"region_name": "房县"
					},
					"18003008": {
						"region_id": "18003008",
						"region_name": "丹江口市"
					}
				}
			},
			"18004": {
				"region_id": "18004",
				"region_name": "宜昌市",
				"child": {
					"18004001": {
						"region_id": "18004001",
						"region_name": "西陵区"
					},
					"18004002": {
						"region_id": "18004002",
						"region_name": "伍家岗区"
					},
					"18004003": {
						"region_id": "18004003",
						"region_name": "点军区"
					},
					"18004004": {
						"region_id": "18004004",
						"region_name": "虎亭区"
					},
					"18004005": {
						"region_id": "18004005",
						"region_name": "宜昌县"
					},
					"18004006": {
						"region_id": "18004006",
						"region_name": "远安县"
					},
					"18004007": {
						"region_id": "18004007",
						"region_name": "兴山县"
					},
					"18004008": {
						"region_id": "18004008",
						"region_name": "秭归县"
					},
					"18004009": {
						"region_id": "18004009",
						"region_name": "长阳土家族自治县"
					},
					"18004010": {
						"region_id": "18004010",
						"region_name": "五峰土家族自治县"
					},
					"18004011": {
						"region_id": "18004011",
						"region_name": "宜都市"
					},
					"18004012": {
						"region_id": "18004012",
						"region_name": "当阳市"
					},
					"18004013": {
						"region_id": "18004013",
						"region_name": "枝江市"
					}
				}
			},
			"18005": {
				"region_id": "18005",
				"region_name": "襄樊市",
				"child": {
					"18005001": {
						"region_id": "18005001",
						"region_name": "襄城区"
					},
					"18005002": {
						"region_id": "18005002",
						"region_name": "樊城区"
					},
					"18005003": {
						"region_id": "18005003",
						"region_name": "襄阳县"
					},
					"18005004": {
						"region_id": "18005004",
						"region_name": "南漳县"
					},
					"18005005": {
						"region_id": "18005005",
						"region_name": "谷城县"
					},
					"18005006": {
						"region_id": "18005006",
						"region_name": "保康县"
					},
					"18005007": {
						"region_id": "18005007",
						"region_name": "老河口市"
					},
					"18005008": {
						"region_id": "18005008",
						"region_name": "枣阳市"
					},
					"18005009": {
						"region_id": "18005009",
						"region_name": "宜城市"
					}
				}
			},
			"18006": {
				"region_id": "18006",
				"region_name": "鄂州市",
				"child": {
					"18006001": {
						"region_id": "18006001",
						"region_name": "梁子湖区"
					},
					"18006002": {
						"region_id": "18006002",
						"region_name": "华容区"
					},
					"18006003": {
						"region_id": "18006003",
						"region_name": "鄂城区"
					}
				}
			},
			"18007": {
				"region_id": "18007",
				"region_name": "荆门市",
				"child": {
					"18007001": {
						"region_id": "18007001",
						"region_name": "东宝区"
					},
					"18007002": {
						"region_id": "18007002",
						"region_name": "掇刀区"
					},
					"18007003": {
						"region_id": "18007003",
						"region_name": "京山县"
					},
					"18007004": {
						"region_id": "18007004",
						"region_name": "沙洋县"
					},
					"18007005": {
						"region_id": "18007005",
						"region_name": "钟祥市"
					}
				}
			},
			"18008": {
				"region_id": "18008",
				"region_name": "孝感市",
				"child": {
					"18008001": {
						"region_id": "18008001",
						"region_name": "孝南区"
					},
					"18008002": {
						"region_id": "18008002",
						"region_name": "孝昌县"
					},
					"18008003": {
						"region_id": "18008003",
						"region_name": "大悟县"
					},
					"18008004": {
						"region_id": "18008004",
						"region_name": "云梦县"
					},
					"18008005": {
						"region_id": "18008005",
						"region_name": "应城市"
					},
					"18008006": {
						"region_id": "18008006",
						"region_name": "安陆市"
					},
					"18008007": {
						"region_id": "18008007",
						"region_name": "汉川市"
					}
				}
			},
			"18009": {
				"region_id": "18009",
				"region_name": "荆州市",
				"child": {
					"18009001": {
						"region_id": "18009001",
						"region_name": "沙市区"
					},
					"18009002": {
						"region_id": "18009002",
						"region_name": "荆州区"
					},
					"18009003": {
						"region_id": "18009003",
						"region_name": "公安县"
					},
					"18009004": {
						"region_id": "18009004",
						"region_name": "监利县"
					},
					"18009005": {
						"region_id": "18009005",
						"region_name": "江陵县"
					},
					"18009006": {
						"region_id": "18009006",
						"region_name": "石首市"
					},
					"18009007": {
						"region_id": "18009007",
						"region_name": "洪湖市"
					},
					"18009008": {
						"region_id": "18009008",
						"region_name": "松滋市"
					}
				}
			},
			"18010": {
				"region_id": "18010",
				"region_name": "黄冈市",
				"child": {
					"18010001": {
						"region_id": "18010001",
						"region_name": "黄州区"
					},
					"18010002": {
						"region_id": "18010002",
						"region_name": "团风县"
					},
					"18010003": {
						"region_id": "18010003",
						"region_name": "红安县"
					},
					"18010004": {
						"region_id": "18010004",
						"region_name": "罗田县"
					},
					"18010005": {
						"region_id": "18010005",
						"region_name": "英山县"
					},
					"18010006": {
						"region_id": "18010006",
						"region_name": "浠水县"
					},
					"18010007": {
						"region_id": "18010007",
						"region_name": "蕲春县"
					},
					"18010008": {
						"region_id": "18010008",
						"region_name": "黄梅县"
					},
					"18010009": {
						"region_id": "18010009",
						"region_name": "麻城市"
					},
					"18010010": {
						"region_id": "18010010",
						"region_name": "武穴市"
					}
				}
			},
			"18011": {
				"region_id": "18011",
				"region_name": "咸宁市",
				"child": {
					"18011001": {
						"region_id": "18011001",
						"region_name": "咸安区"
					},
					"18011002": {
						"region_id": "18011002",
						"region_name": "嘉鱼县"
					},
					"18011003": {
						"region_id": "18011003",
						"region_name": "通城县"
					},
					"18011004": {
						"region_id": "18011004",
						"region_name": "崇阳县"
					},
					"18011005": {
						"region_id": "18011005",
						"region_name": "通山县"
					},
					"18011006": {
						"region_id": "18011006",
						"region_name": "赤壁市"
					}
				}
			},
			"18012": {
				"region_id": "18012",
				"region_name": "随州市",
				"child": {
					"18012001": {
						"region_id": "18012001",
						"region_name": "曾都区"
					},
					"18012002": {
						"region_id": "18012002",
						"region_name": "随县"
					},
					"18012003": {
						"region_id": "18012003",
						"region_name": "广水市"
					}
				}
			},
			"18013": {
				"region_id": "18013",
				"region_name": "恩施土家族苗族自治州",
				"child": {
					"18013001": {
						"region_id": "18013001",
						"region_name": "恩施市"
					},
					"18013002": {
						"region_id": "18013002",
						"region_name": "利川市"
					},
					"18013003": {
						"region_id": "18013003",
						"region_name": "建始县"
					},
					"18013004": {
						"region_id": "18013004",
						"region_name": "巴东县"
					},
					"18013005": {
						"region_id": "18013005",
						"region_name": "宣恩县"
					},
					"18013006": {
						"region_id": "18013006",
						"region_name": "咸丰县"
					},
					"18013007": {
						"region_id": "18013007",
						"region_name": "来凤县"
					},
					"18013008": {
						"region_id": "18013008",
						"region_name": "鹤峰县"
					}
				}
			},
			"18014": {
				"region_id": "18014",
				"region_name": "省直辖行政单位",
				"child": {
					"18014001": {
						"region_id": "18014001",
						"region_name": "仙桃市"
					},
					"18014002": {
						"region_id": "18014002",
						"region_name": "潜江市"
					},
					"18014003": {
						"region_id": "18014003",
						"region_name": "天门市"
					},
					"18014004": {
						"region_id": "18014004",
						"region_name": "神农架林区"
					}
				}
			}
		}
	},
	"19": {
		"region_id": "19",
		"region_name": "湖南省",
		"child": {
			"19001": {
				"region_id": "19001",
				"region_name": "长沙市",
				"child": {
					"19001001": {
						"region_id": "19001001",
						"region_name": "芙蓉区"
					},
					"19001002": {
						"region_id": "19001002",
						"region_name": "天心区"
					},
					"19001003": {
						"region_id": "19001003",
						"region_name": "岳麓区"
					},
					"19001004": {
						"region_id": "19001004",
						"region_name": "开福区"
					},
					"19001005": {
						"region_id": "19001005",
						"region_name": "雨花区"
					},
					"19001006": {
						"region_id": "19001006",
						"region_name": "长沙县"
					},
					"19001007": {
						"region_id": "19001007",
						"region_name": "望城县"
					},
					"19001008": {
						"region_id": "19001008",
						"region_name": "宁乡县"
					},
					"19001009": {
						"region_id": "19001009",
						"region_name": "浏阳市"
					}
				}
			},
			"19002": {
				"region_id": "19002",
				"region_name": "株洲市",
				"child": {
					"19002001": {
						"region_id": "19002001",
						"region_name": "荷塘区"
					},
					"19002002": {
						"region_id": "19002002",
						"region_name": "芦淞区"
					},
					"19002003": {
						"region_id": "19002003",
						"region_name": "石峰区"
					},
					"19002004": {
						"region_id": "19002004",
						"region_name": "天元区"
					},
					"19002005": {
						"region_id": "19002005",
						"region_name": "株洲县"
					},
					"19002006": {
						"region_id": "19002006",
						"region_name": "攸县"
					},
					"19002007": {
						"region_id": "19002007",
						"region_name": "茶陵县"
					},
					"19002008": {
						"region_id": "19002008",
						"region_name": "炎陵县"
					},
					"19002009": {
						"region_id": "19002009",
						"region_name": "醴陵市"
					}
				}
			},
			"19003": {
				"region_id": "19003",
				"region_name": "湘潭市",
				"child": {
					"19003001": {
						"region_id": "19003001",
						"region_name": "雨湖区"
					},
					"19003002": {
						"region_id": "19003002",
						"region_name": "岳塘区"
					},
					"19003003": {
						"region_id": "19003003",
						"region_name": "湘潭县"
					},
					"19003004": {
						"region_id": "19003004",
						"region_name": "湘乡市"
					},
					"19003005": {
						"region_id": "19003005",
						"region_name": "韶山市"
					}
				}
			},
			"19004": {
				"region_id": "19004",
				"region_name": "衡阳市",
				"child": {
					"19004001": {
						"region_id": "19004001",
						"region_name": "江东区"
					},
					"19004002": {
						"region_id": "19004002",
						"region_name": "城南区"
					},
					"19004003": {
						"region_id": "19004003",
						"region_name": "城北区"
					},
					"19004004": {
						"region_id": "19004004",
						"region_name": "郊区"
					},
					"19004005": {
						"region_id": "19004005",
						"region_name": "南岳区"
					},
					"19004006": {
						"region_id": "19004006",
						"region_name": "衡阳县"
					},
					"19004007": {
						"region_id": "19004007",
						"region_name": "衡南县"
					},
					"19004008": {
						"region_id": "19004008",
						"region_name": "衡山县"
					},
					"19004009": {
						"region_id": "19004009",
						"region_name": "衡东县"
					},
					"19004010": {
						"region_id": "19004010",
						"region_name": "祁东县"
					},
					"19004011": {
						"region_id": "19004011",
						"region_name": "耒阳市"
					},
					"19004012": {
						"region_id": "19004012",
						"region_name": "常宁市"
					}
				}
			},
			"19005": {
				"region_id": "19005",
				"region_name": "邵阳市",
				"child": {
					"19005001": {
						"region_id": "19005001",
						"region_name": "双清区"
					},
					"19005002": {
						"region_id": "19005002",
						"region_name": "大祥区"
					},
					"19005003": {
						"region_id": "19005003",
						"region_name": "北塔区"
					},
					"19005004": {
						"region_id": "19005004",
						"region_name": "邵东县"
					},
					"19005005": {
						"region_id": "19005005",
						"region_name": "新邵县"
					},
					"19005006": {
						"region_id": "19005006",
						"region_name": "邵阳县"
					},
					"19005007": {
						"region_id": "19005007",
						"region_name": "隆回县"
					},
					"19005008": {
						"region_id": "19005008",
						"region_name": "洞口县"
					},
					"19005009": {
						"region_id": "19005009",
						"region_name": "绥宁县"
					},
					"19005010": {
						"region_id": "19005010",
						"region_name": "新宁县"
					},
					"19005011": {
						"region_id": "19005011",
						"region_name": "城步苗族自治县"
					},
					"19005012": {
						"region_id": "19005012",
						"region_name": "武冈市"
					}
				}
			},
			"19006": {
				"region_id": "19006",
				"region_name": "岳阳市",
				"child": {
					"19006001": {
						"region_id": "19006001",
						"region_name": "岳阳楼区"
					},
					"19006002": {
						"region_id": "19006002",
						"region_name": "云溪区"
					},
					"19006003": {
						"region_id": "19006003",
						"region_name": "君山区"
					},
					"19006004": {
						"region_id": "19006004",
						"region_name": "岳阳县"
					},
					"19006005": {
						"region_id": "19006005",
						"region_name": "华容县"
					},
					"19006006": {
						"region_id": "19006006",
						"region_name": "湘阴县"
					},
					"19006007": {
						"region_id": "19006007",
						"region_name": "平江县"
					},
					"19006008": {
						"region_id": "19006008",
						"region_name": "汨罗市"
					},
					"19006009": {
						"region_id": "19006009",
						"region_name": "临湘市"
					}
				}
			},
			"19007": {
				"region_id": "19007",
				"region_name": "常德市",
				"child": {
					"19007001": {
						"region_id": "19007001",
						"region_name": "武陵区"
					},
					"19007002": {
						"region_id": "19007002",
						"region_name": "鼎城区"
					},
					"19007003": {
						"region_id": "19007003",
						"region_name": "安乡县"
					},
					"19007004": {
						"region_id": "19007004",
						"region_name": "汉寿县"
					},
					"19007005": {
						"region_id": "19007005",
						"region_name": "澧县"
					},
					"19007006": {
						"region_id": "19007006",
						"region_name": "临澧县"
					},
					"19007007": {
						"region_id": "19007007",
						"region_name": "桃源县"
					},
					"19007008": {
						"region_id": "19007008",
						"region_name": "石门县"
					},
					"19007009": {
						"region_id": "19007009",
						"region_name": "津市市"
					}
				}
			},
			"19008": {
				"region_id": "19008",
				"region_name": "张家界市",
				"child": {
					"19008001": {
						"region_id": "19008001",
						"region_name": "永定区"
					},
					"19008002": {
						"region_id": "19008002",
						"region_name": "武陵源区"
					},
					"19008003": {
						"region_id": "19008003",
						"region_name": "慈利县"
					},
					"19008004": {
						"region_id": "19008004",
						"region_name": "桑植县"
					}
				}
			},
			"19009": {
				"region_id": "19009",
				"region_name": "益阳市",
				"child": {
					"19009001": {
						"region_id": "19009001",
						"region_name": "资阳区"
					},
					"19009002": {
						"region_id": "19009002",
						"region_name": "赫山区"
					},
					"19009003": {
						"region_id": "19009003",
						"region_name": "南县"
					},
					"19009004": {
						"region_id": "19009004",
						"region_name": "桃江县"
					},
					"19009005": {
						"region_id": "19009005",
						"region_name": "安化县"
					},
					"19009006": {
						"region_id": "19009006",
						"region_name": "沅江市"
					}
				}
			},
			"19010": {
				"region_id": "19010",
				"region_name": "郴州市",
				"child": {
					"19010001": {
						"region_id": "19010001",
						"region_name": "北湖区"
					},
					"19010002": {
						"region_id": "19010002",
						"region_name": "苏仙区"
					},
					"19010003": {
						"region_id": "19010003",
						"region_name": "桂阳县"
					},
					"19010004": {
						"region_id": "19010004",
						"region_name": "宜章县"
					},
					"19010005": {
						"region_id": "19010005",
						"region_name": "永兴县"
					},
					"19010006": {
						"region_id": "19010006",
						"region_name": "嘉禾县"
					},
					"19010007": {
						"region_id": "19010007",
						"region_name": "临武县"
					},
					"19010008": {
						"region_id": "19010008",
						"region_name": "汝城县"
					},
					"19010009": {
						"region_id": "19010009",
						"region_name": "桂东县"
					},
					"19010010": {
						"region_id": "19010010",
						"region_name": "安仁县"
					},
					"19010011": {
						"region_id": "19010011",
						"region_name": "资兴市"
					}
				}
			},
			"19011": {
				"region_id": "19011",
				"region_name": "永州市",
				"child": {
					"19011001": {
						"region_id": "19011001",
						"region_name": "芝山区"
					},
					"19011002": {
						"region_id": "19011002",
						"region_name": "冷水滩区"
					},
					"19011003": {
						"region_id": "19011003",
						"region_name": "祁阳县"
					},
					"19011004": {
						"region_id": "19011004",
						"region_name": "东安县"
					},
					"19011005": {
						"region_id": "19011005",
						"region_name": "双牌县"
					},
					"19011006": {
						"region_id": "19011006",
						"region_name": "道县"
					},
					"19011007": {
						"region_id": "19011007",
						"region_name": "江永县"
					},
					"19011008": {
						"region_id": "19011008",
						"region_name": "宁远县"
					},
					"19011009": {
						"region_id": "19011009",
						"region_name": "蓝山县"
					},
					"19011010": {
						"region_id": "19011010",
						"region_name": "新田县"
					},
					"19011011": {
						"region_id": "19011011",
						"region_name": "江华瑶族自治县"
					}
				}
			},
			"19012": {
				"region_id": "19012",
				"region_name": "怀化市",
				"child": {
					"19012001": {
						"region_id": "19012001",
						"region_name": "鹤城区"
					},
					"19012002": {
						"region_id": "19012002",
						"region_name": "中方县"
					},
					"19012003": {
						"region_id": "19012003",
						"region_name": "沅陵县"
					},
					"19012004": {
						"region_id": "19012004",
						"region_name": "辰溪县"
					},
					"19012005": {
						"region_id": "19012005",
						"region_name": "溆浦县"
					},
					"19012006": {
						"region_id": "19012006",
						"region_name": "会同县"
					},
					"19012007": {
						"region_id": "19012007",
						"region_name": "麻阳苗族自治县"
					},
					"19012008": {
						"region_id": "19012008",
						"region_name": "新晃侗族自治县"
					},
					"19012009": {
						"region_id": "19012009",
						"region_name": "芷江侗族自治县"
					},
					"19012010": {
						"region_id": "19012010",
						"region_name": "靖州苗族侗族自治县"
					},
					"19012011": {
						"region_id": "19012011",
						"region_name": "通道侗族自治县"
					},
					"19012012": {
						"region_id": "19012012",
						"region_name": "洪江市"
					}
				}
			},
			"19013": {
				"region_id": "19013",
				"region_name": "娄底地区",
				"child": {
					"19013001": {
						"region_id": "19013001",
						"region_name": "娄底市"
					},
					"19013002": {
						"region_id": "19013002",
						"region_name": "冷水江市"
					},
					"19013003": {
						"region_id": "19013003",
						"region_name": "涟源市"
					},
					"19013004": {
						"region_id": "19013004",
						"region_name": "双峰县"
					},
					"19013005": {
						"region_id": "19013005",
						"region_name": "新化县"
					}
				}
			},
			"19014": {
				"region_id": "19014",
				"region_name": "湘西土家族苗族自治州",
				"child": {
					"19014001": {
						"region_id": "19014001",
						"region_name": "吉首市"
					},
					"19014002": {
						"region_id": "19014002",
						"region_name": "泸溪县"
					},
					"19014003": {
						"region_id": "19014003",
						"region_name": "凤凰县"
					},
					"19014004": {
						"region_id": "19014004",
						"region_name": "花垣县"
					},
					"19014005": {
						"region_id": "19014005",
						"region_name": "保靖县"
					},
					"19014006": {
						"region_id": "19014006",
						"region_name": "古丈县"
					},
					"19014007": {
						"region_id": "19014007",
						"region_name": "永顺县"
					},
					"19014008": {
						"region_id": "19014008",
						"region_name": "龙山县"
					}
				}
			}
		}
	},
	"20": {
		"region_id": "20",
		"region_name": "广东省",
		"child": {
			"20001": {
				"region_id": "20001",
				"region_name": "广州市",
				"child": {
					"20001001": {
						"region_id": "20001001",
						"region_name": "东山区"
					},
					"20001002": {
						"region_id": "20001002",
						"region_name": "荔湾区"
					},
					"20001003": {
						"region_id": "20001003",
						"region_name": "越秀区"
					},
					"20001004": {
						"region_id": "20001004",
						"region_name": "海珠区"
					},
					"20001005": {
						"region_id": "20001005",
						"region_name": "天河区"
					},
					"20001006": {
						"region_id": "20001006",
						"region_name": "芳村区"
					},
					"20001007": {
						"region_id": "20001007",
						"region_name": "白云区"
					},
					"20001008": {
						"region_id": "20001008",
						"region_name": "黄埔区"
					},
					"20001009": {
						"region_id": "20001009",
						"region_name": "番禺市"
					},
					"20001010": {
						"region_id": "20001010",
						"region_name": "花都市"
					},
					"20001011": {
						"region_id": "20001011",
						"region_name": "增城市"
					},
					"20001012": {
						"region_id": "20001012",
						"region_name": "从化市"
					}
				}
			},
			"20002": {
				"region_id": "20002",
				"region_name": "韶关市",
				"child": {
					"20002001": {
						"region_id": "20002001",
						"region_name": "武江区"
					},
					"20002002": {
						"region_id": "20002002",
						"region_name": "浈江区"
					},
					"20002003": {
						"region_id": "20002003",
						"region_name": "曲江区"
					},
					"20002004": {
						"region_id": "20002004",
						"region_name": "始兴县"
					},
					"20002005": {
						"region_id": "20002005",
						"region_name": "仁化县"
					},
					"20002006": {
						"region_id": "20002006",
						"region_name": "翁源县"
					},
					"20002007": {
						"region_id": "20002007",
						"region_name": "乳源瑶族自治县"
					},
					"20002008": {
						"region_id": "20002008",
						"region_name": "新丰县"
					},
					"20002009": {
						"region_id": "20002009",
						"region_name": "乐昌市"
					},
					"20002010": {
						"region_id": "20002010",
						"region_name": "南雄市"
					}
				}
			},
			"20003": {
				"region_id": "20003",
				"region_name": "深圳市",
				"child": {
					"20003001": {
						"region_id": "20003001",
						"region_name": "罗湖区"
					},
					"20003002": {
						"region_id": "20003002",
						"region_name": "福田区"
					},
					"20003003": {
						"region_id": "20003003",
						"region_name": "南山区"
					},
					"20003004": {
						"region_id": "20003004",
						"region_name": "宝安区"
					},
					"20003005": {
						"region_id": "20003005",
						"region_name": "龙岗区"
					},
					"20003006": {
						"region_id": "20003006",
						"region_name": "盐田区"
					}
				}
			},
			"20004": {
				"region_id": "20004",
				"region_name": "珠海市",
				"child": {
					"20004001": {
						"region_id": "20004001",
						"region_name": "香洲区"
					},
					"20004002": {
						"region_id": "20004002",
						"region_name": "斗门区"
					},
					"20004003": {
						"region_id": "20004003",
						"region_name": "金湾区"
					}
				}
			},
			"20005": {
				"region_id": "20005",
				"region_name": "汕头市",
				"child": {
					"20005001": {
						"region_id": "20005001",
						"region_name": "濠江区"
					},
					"20005002": {
						"region_id": "20005002",
						"region_name": "龙湖区"
					},
					"20005003": {
						"region_id": "20005003",
						"region_name": "金平区"
					},
					"20005004": {
						"region_id": "20005004",
						"region_name": "潮南区"
					},
					"20005005": {
						"region_id": "20005005",
						"region_name": "澄海区"
					},
					"20005006": {
						"region_id": "20005006",
						"region_name": "潮阳区"
					},
					"20005007": {
						"region_id": "20005007",
						"region_name": "南澳县"
					}
				}
			},
			"20006": {
				"region_id": "20006",
				"region_name": "佛山市",
				"child": {
					"20006001": {
						"region_id": "20006001",
						"region_name": "禅城区"
					},
					"20006002": {
						"region_id": "20006002",
						"region_name": "顺德区"
					},
					"20006003": {
						"region_id": "20006003",
						"region_name": "南海区"
					},
					"20006004": {
						"region_id": "20006004",
						"region_name": "三水区"
					},
					"20006005": {
						"region_id": "20006005",
						"region_name": "高明区"
					}
				}
			},
			"20007": {
				"region_id": "20007",
				"region_name": "江门市",
				"child": {
					"20007001": {
						"region_id": "20007001",
						"region_name": "蓬江区"
					},
					"20007002": {
						"region_id": "20007002",
						"region_name": "江海区"
					},
					"20007003": {
						"region_id": "20007003",
						"region_name": "台山市"
					},
					"20007004": {
						"region_id": "20007004",
						"region_name": "新会市"
					},
					"20007005": {
						"region_id": "20007005",
						"region_name": "开平市"
					},
					"20007006": {
						"region_id": "20007006",
						"region_name": "鹤山市"
					},
					"20007007": {
						"region_id": "20007007",
						"region_name": "恩平市"
					}
				}
			},
			"20008": {
				"region_id": "20008",
				"region_name": "湛江市",
				"child": {
					"20008001": {
						"region_id": "20008001",
						"region_name": "赤坎区"
					},
					"20008002": {
						"region_id": "20008002",
						"region_name": "霞山区"
					},
					"20008003": {
						"region_id": "20008003",
						"region_name": "坡头区"
					},
					"20008004": {
						"region_id": "20008004",
						"region_name": "麻章区"
					},
					"20008005": {
						"region_id": "20008005",
						"region_name": "遂溪县"
					},
					"20008006": {
						"region_id": "20008006",
						"region_name": "徐闻县"
					},
					"20008007": {
						"region_id": "20008007",
						"region_name": "廉江市"
					},
					"20008008": {
						"region_id": "20008008",
						"region_name": "雷州市"
					},
					"20008009": {
						"region_id": "20008009",
						"region_name": "吴川市"
					}
				}
			},
			"20009": {
				"region_id": "20009",
				"region_name": "茂名市",
				"child": {
					"20009001": {
						"region_id": "20009001",
						"region_name": "茂南区"
					},
					"20009002": {
						"region_id": "20009002",
						"region_name": "茂港区"
					},
					"20009003": {
						"region_id": "20009003",
						"region_name": "电白县"
					},
					"20009004": {
						"region_id": "20009004",
						"region_name": "高州市"
					},
					"20009005": {
						"region_id": "20009005",
						"region_name": "化州市"
					},
					"20009006": {
						"region_id": "20009006",
						"region_name": "信宜市"
					}
				}
			},
			"20010": {
				"region_id": "20010",
				"region_name": "肇庆市",
				"child": {
					"20010001": {
						"region_id": "20010001",
						"region_name": "端州区"
					},
					"20010002": {
						"region_id": "20010002",
						"region_name": "鼎湖区"
					},
					"20010003": {
						"region_id": "20010003",
						"region_name": "广宁县"
					},
					"20010004": {
						"region_id": "20010004",
						"region_name": "怀集县"
					},
					"20010005": {
						"region_id": "20010005",
						"region_name": "封开县"
					},
					"20010006": {
						"region_id": "20010006",
						"region_name": "德庆县"
					},
					"20010007": {
						"region_id": "20010007",
						"region_name": "高要市"
					},
					"20010008": {
						"region_id": "20010008",
						"region_name": "四会市"
					}
				}
			},
			"20011": {
				"region_id": "20011",
				"region_name": "惠州市",
				"child": {
					"20011001": {
						"region_id": "20011001",
						"region_name": "惠城区"
					},
					"20011002": {
						"region_id": "20011002",
						"region_name": "博罗县"
					},
					"20011003": {
						"region_id": "20011003",
						"region_name": "惠东县"
					},
					"20011004": {
						"region_id": "20011004",
						"region_name": "龙门县"
					},
					"20011005": {
						"region_id": "20011005",
						"region_name": "惠阳市"
					}
				}
			},
			"20012": {
				"region_id": "20012",
				"region_name": "梅州市",
				"child": {
					"20012001": {
						"region_id": "20012001",
						"region_name": "梅江区"
					},
					"20012002": {
						"region_id": "20012002",
						"region_name": "梅县"
					},
					"20012003": {
						"region_id": "20012003",
						"region_name": "大埔县"
					},
					"20012004": {
						"region_id": "20012004",
						"region_name": "丰顺县"
					},
					"20012005": {
						"region_id": "20012005",
						"region_name": "五华县"
					},
					"20012006": {
						"region_id": "20012006",
						"region_name": "平远县"
					},
					"20012007": {
						"region_id": "20012007",
						"region_name": "蕉岭县"
					},
					"20012008": {
						"region_id": "20012008",
						"region_name": "兴宁市"
					}
				}
			},
			"20013": {
				"region_id": "20013",
				"region_name": "汕尾市",
				"child": {
					"20013001": {
						"region_id": "20013001",
						"region_name": "城区"
					},
					"20013002": {
						"region_id": "20013002",
						"region_name": "海丰县"
					},
					"20013003": {
						"region_id": "20013003",
						"region_name": "陆河县"
					},
					"20013004": {
						"region_id": "20013004",
						"region_name": "陆丰市"
					}
				}
			},
			"20014": {
				"region_id": "20014",
				"region_name": "河源市",
				"child": {
					"20014001": {
						"region_id": "20014001",
						"region_name": "源城区"
					},
					"20014002": {
						"region_id": "20014002",
						"region_name": "紫金县"
					},
					"20014003": {
						"region_id": "20014003",
						"region_name": "龙川县"
					},
					"20014004": {
						"region_id": "20014004",
						"region_name": "连平县"
					},
					"20014005": {
						"region_id": "20014005",
						"region_name": "和平县"
					},
					"20014006": {
						"region_id": "20014006",
						"region_name": "东源县"
					}
				}
			},
			"20015": {
				"region_id": "20015",
				"region_name": "阳江市",
				"child": {
					"20015001": {
						"region_id": "20015001",
						"region_name": "江城区"
					},
					"20015002": {
						"region_id": "20015002",
						"region_name": "阳西县"
					},
					"20015003": {
						"region_id": "20015003",
						"region_name": "阳东县"
					},
					"20015004": {
						"region_id": "20015004",
						"region_name": "阳春市"
					}
				}
			},
			"20016": {
				"region_id": "20016",
				"region_name": "清远市",
				"child": {
					"20016001": {
						"region_id": "20016001",
						"region_name": "清城区"
					},
					"20016002": {
						"region_id": "20016002",
						"region_name": "佛冈县"
					},
					"20016003": {
						"region_id": "20016003",
						"region_name": "阳山县"
					},
					"20016004": {
						"region_id": "20016004",
						"region_name": "连山壮族瑶族自治县"
					},
					"20016005": {
						"region_id": "20016005",
						"region_name": "连南瑶族自治县"
					},
					"20016006": {
						"region_id": "20016006",
						"region_name": "清新县"
					},
					"20016007": {
						"region_id": "20016007",
						"region_name": "英德市"
					},
					"20016008": {
						"region_id": "20016008",
						"region_name": "连州市"
					}
				}
			},
			"20017": {
				"region_id": "20017",
				"region_name": "东莞市",
				"child": {
					"20017001": {
						"region_id": "20017001",
						"region_name": "莞城区"
					},
					"20017002": {
						"region_id": "20017002",
						"region_name": "南城区"
					},
					"20017003": {
						"region_id": "20017003",
						"region_name": "东城区"
					},
					"20017004": {
						"region_id": "20017004",
						"region_name": "万江区"
					}
				}
			},
			"20018": {
				"region_id": "20018",
				"region_name": "中山市",
				"child": {
					"20018001": {
						"region_id": "20018001",
						"region_name": "中山市"
					}
				}
			},
			"20019": {
				"region_id": "20019",
				"region_name": "潮州市",
				"child": {
					"20019001": {
						"region_id": "20019001",
						"region_name": "湘桥区"
					},
					"20019002": {
						"region_id": "20019002",
						"region_name": "潮安县"
					},
					"20019003": {
						"region_id": "20019003",
						"region_name": "饶平县"
					}
				}
			},
			"20020": {
				"region_id": "20020",
				"region_name": "揭阳市",
				"child": {
					"20020001": {
						"region_id": "20020001",
						"region_name": "榕城区"
					},
					"20020002": {
						"region_id": "20020002",
						"region_name": "揭东县"
					},
					"20020003": {
						"region_id": "20020003",
						"region_name": "揭西县"
					},
					"20020004": {
						"region_id": "20020004",
						"region_name": "惠来县"
					},
					"20020005": {
						"region_id": "20020005",
						"region_name": "普宁市"
					}
				}
			},
			"20021": {
				"region_id": "20021",
				"region_name": "云浮市",
				"child": {
					"20021001": {
						"region_id": "20021001",
						"region_name": "云城区"
					},
					"20021002": {
						"region_id": "20021002",
						"region_name": "新兴县"
					},
					"20021003": {
						"region_id": "20021003",
						"region_name": "郁南县"
					},
					"20021004": {
						"region_id": "20021004",
						"region_name": "云安县"
					},
					"20021005": {
						"region_id": "20021005",
						"region_name": "罗定市"
					}
				}
			}
		}
	},
	"21": {
		"region_id": "21",
		"region_name": "广西省",
		"child": {
			"21001": {
				"region_id": "21001",
				"region_name": "南宁市",
				"child": {
					"21001001": {
						"region_id": "21001001",
						"region_name": "兴宁区"
					},
					"21001002": {
						"region_id": "21001002",
						"region_name": "青秀区"
					},
					"21001003": {
						"region_id": "21001003",
						"region_name": "西乡塘区"
					},
					"21001004": {
						"region_id": "21001004",
						"region_name": "江南区"
					},
					"21001005": {
						"region_id": "21001005",
						"region_name": "良庆区"
					},
					"21001006": {
						"region_id": "21001006",
						"region_name": "邕宁区"
					},
					"21001007": {
						"region_id": "21001007",
						"region_name": "武鸣县"
					},
					"21001008": {
						"region_id": "21001008",
						"region_name": "隆安县"
					},
					"21001009": {
						"region_id": "21001009",
						"region_name": "马山县"
					},
					"21001010": {
						"region_id": "21001010",
						"region_name": "上林县"
					},
					"21001011": {
						"region_id": "21001011",
						"region_name": "宾阳县"
					},
					"21001012": {
						"region_id": "21001012",
						"region_name": "横　县"
					}
				}
			},
			"21002": {
				"region_id": "21002",
				"region_name": "柳州市",
				"child": {
					"21002001": {
						"region_id": "21002001",
						"region_name": "城中区"
					},
					"21002002": {
						"region_id": "21002002",
						"region_name": "鱼峰区"
					},
					"21002003": {
						"region_id": "21002003",
						"region_name": "柳南区"
					},
					"21002004": {
						"region_id": "21002004",
						"region_name": "柳北区"
					},
					"21002005": {
						"region_id": "21002005",
						"region_name": "柳江县"
					},
					"21002006": {
						"region_id": "21002006",
						"region_name": "柳城县"
					},
					"21002007": {
						"region_id": "21002007",
						"region_name": "鹿寨县"
					},
					"21002008": {
						"region_id": "21002008",
						"region_name": "融安县"
					},
					"21002009": {
						"region_id": "21002009",
						"region_name": "融水苗族自治县"
					},
					"21002010": {
						"region_id": "21002010",
						"region_name": "三江侗族自治县"
					}
				}
			},
			"21003": {
				"region_id": "21003",
				"region_name": "桂林市",
				"child": {
					"21003001": {
						"region_id": "21003001",
						"region_name": "秀峰区"
					},
					"21003002": {
						"region_id": "21003002",
						"region_name": "叠彩区"
					},
					"21003003": {
						"region_id": "21003003",
						"region_name": "象山区"
					},
					"21003004": {
						"region_id": "21003004",
						"region_name": "七星区"
					},
					"21003005": {
						"region_id": "21003005",
						"region_name": "雁山区"
					},
					"21003006": {
						"region_id": "21003006",
						"region_name": "阳朔县"
					},
					"21003007": {
						"region_id": "21003007",
						"region_name": "临桂县"
					},
					"21003008": {
						"region_id": "21003008",
						"region_name": "灵川县"
					},
					"21003009": {
						"region_id": "21003009",
						"region_name": "全州县"
					},
					"21003010": {
						"region_id": "21003010",
						"region_name": "兴安县"
					},
					"21003011": {
						"region_id": "21003011",
						"region_name": "永福县"
					},
					"21003012": {
						"region_id": "21003012",
						"region_name": "灌阳县"
					},
					"21003013": {
						"region_id": "21003013",
						"region_name": "龙胜各县自治区"
					},
					"21003014": {
						"region_id": "21003014",
						"region_name": "资源县"
					},
					"21003015": {
						"region_id": "21003015",
						"region_name": "平乐县"
					},
					"21003016": {
						"region_id": "21003016",
						"region_name": "荔蒲县"
					},
					"21003017": {
						"region_id": "21003017",
						"region_name": "恭城瑶族自治县"
					}
				}
			},
			"21004": {
				"region_id": "21004",
				"region_name": "梧州市",
				"child": {
					"21004001": {
						"region_id": "21004001",
						"region_name": "万秀区"
					},
					"21004002": {
						"region_id": "21004002",
						"region_name": "蝶山区"
					},
					"21004003": {
						"region_id": "21004003",
						"region_name": "市郊区"
					},
					"21004004": {
						"region_id": "21004004",
						"region_name": "苍梧县"
					},
					"21004005": {
						"region_id": "21004005",
						"region_name": "藤县"
					},
					"21004006": {
						"region_id": "21004006",
						"region_name": "蒙山县"
					},
					"21004007": {
						"region_id": "21004007",
						"region_name": "岑溪市"
					}
				}
			},
			"21005": {
				"region_id": "21005",
				"region_name": "北海市",
				"child": {
					"21005001": {
						"region_id": "21005001",
						"region_name": "海城区"
					},
					"21005002": {
						"region_id": "21005002",
						"region_name": "银海区"
					},
					"21005003": {
						"region_id": "21005003",
						"region_name": "铁山港区"
					},
					"21005004": {
						"region_id": "21005004",
						"region_name": "合浦县"
					}
				}
			},
			"21006": {
				"region_id": "21006",
				"region_name": "防城港市",
				"child": {
					"21006001": {
						"region_id": "21006001",
						"region_name": "港口区"
					},
					"21006002": {
						"region_id": "21006002",
						"region_name": "防城区"
					},
					"21006003": {
						"region_id": "21006003",
						"region_name": "上思县"
					},
					"21006004": {
						"region_id": "21006004",
						"region_name": "东兴市"
					}
				}
			},
			"21007": {
				"region_id": "21007",
				"region_name": "钦州市",
				"child": {
					"21007001": {
						"region_id": "21007001",
						"region_name": "钦南区"
					},
					"21007002": {
						"region_id": "21007002",
						"region_name": "钦北区"
					},
					"21007003": {
						"region_id": "21007003",
						"region_name": "浦北县"
					},
					"21007004": {
						"region_id": "21007004",
						"region_name": "灵山县"
					}
				}
			},
			"21008": {
				"region_id": "21008",
				"region_name": "贵港市",
				"child": {
					"21008001": {
						"region_id": "21008001",
						"region_name": "港北区"
					},
					"21008002": {
						"region_id": "21008002",
						"region_name": "港南区"
					},
					"21008003": {
						"region_id": "21008003",
						"region_name": "覃塘区"
					},
					"21008004": {
						"region_id": "21008004",
						"region_name": "平南县"
					},
					"21008005": {
						"region_id": "21008005",
						"region_name": "桂平市"
					}
				}
			},
			"21009": {
				"region_id": "21009",
				"region_name": "玉林市",
				"child": {
					"21009001": {
						"region_id": "21009001",
						"region_name": "玉州区"
					},
					"21009002": {
						"region_id": "21009002",
						"region_name": "容县"
					},
					"21009003": {
						"region_id": "21009003",
						"region_name": "陆川县"
					},
					"21009004": {
						"region_id": "21009004",
						"region_name": "博白县"
					},
					"21009005": {
						"region_id": "21009005",
						"region_name": "兴业县"
					},
					"21009006": {
						"region_id": "21009006",
						"region_name": "北流市"
					}
				}
			},
			"21010": {
				"region_id": "21010",
				"region_name": "崇左市",
				"child": {
					"21010001": {
						"region_id": "21010001",
						"region_name": "江州区"
					},
					"21010002": {
						"region_id": "21010002",
						"region_name": "凭祥市"
					},
					"21010003": {
						"region_id": "21010003",
						"region_name": "扶绥县"
					},
					"21010004": {
						"region_id": "21010004",
						"region_name": "宁明县"
					},
					"21010005": {
						"region_id": "21010005",
						"region_name": "龙州县"
					},
					"21010006": {
						"region_id": "21010006",
						"region_name": "大新县"
					},
					"21010007": {
						"region_id": "21010007",
						"region_name": "天等县"
					}
				}
			},
			"21011": {
				"region_id": "21011",
				"region_name": "来宾市",
				"child": {
					"21011001": {
						"region_id": "21011001",
						"region_name": "兴宾区"
					},
					"21011002": {
						"region_id": "21011002",
						"region_name": "合山市"
					},
					"21011003": {
						"region_id": "21011003",
						"region_name": "忻城县"
					},
					"21011004": {
						"region_id": "21011004",
						"region_name": "象州县"
					},
					"21011005": {
						"region_id": "21011005",
						"region_name": "武宣县"
					},
					"21011006": {
						"region_id": "21011006",
						"region_name": "金秀瑶族自治县"
					}
				}
			},
			"21012": {
				"region_id": "21012",
				"region_name": "贺州市",
				"child": {
					"21012001": {
						"region_id": "21012001",
						"region_name": "八步区"
					},
					"21012002": {
						"region_id": "21012002",
						"region_name": "昭平县"
					},
					"21012003": {
						"region_id": "21012003",
						"region_name": "钟山县"
					},
					"21012004": {
						"region_id": "21012004",
						"region_name": "富川瑶族自治县"
					}
				}
			},
			"21013": {
				"region_id": "21013",
				"region_name": "百色市",
				"child": {
					"21013001": {
						"region_id": "21013001",
						"region_name": "右江区"
					},
					"21013002": {
						"region_id": "21013002",
						"region_name": "田阳县"
					},
					"21013003": {
						"region_id": "21013003",
						"region_name": "田东县"
					},
					"21013004": {
						"region_id": "21013004",
						"region_name": "平果县"
					},
					"21013005": {
						"region_id": "21013005",
						"region_name": "德保县"
					},
					"21013006": {
						"region_id": "21013006",
						"region_name": "靖西县"
					},
					"21013007": {
						"region_id": "21013007",
						"region_name": "那坡县"
					},
					"21013008": {
						"region_id": "21013008",
						"region_name": "凌云县"
					},
					"21013009": {
						"region_id": "21013009",
						"region_name": "乐业县"
					},
					"21013010": {
						"region_id": "21013010",
						"region_name": "田林县"
					},
					"21013011": {
						"region_id": "21013011",
						"region_name": "隆林各族自治县"
					},
					"21013012": {
						"region_id": "21013012",
						"region_name": "西林县"
					}
				}
			},
			"21014": {
				"region_id": "21014",
				"region_name": "河池市",
				"child": {
					"21014001": {
						"region_id": "21014001",
						"region_name": "金城江区"
					},
					"21014002": {
						"region_id": "21014002",
						"region_name": "宜州市"
					},
					"21014003": {
						"region_id": "21014003",
						"region_name": "罗城仫佬族自治县"
					},
					"21014004": {
						"region_id": "21014004",
						"region_name": "环江毛南族自治县"
					},
					"21014005": {
						"region_id": "21014005",
						"region_name": "南丹县"
					},
					"21014006": {
						"region_id": "21014006",
						"region_name": "天峨县"
					},
					"21014007": {
						"region_id": "21014007",
						"region_name": "凤山县"
					},
					"21014008": {
						"region_id": "21014008",
						"region_name": "东兰县"
					},
					"21014009": {
						"region_id": "21014009",
						"region_name": "巴马瑶族自治县"
					},
					"21014010": {
						"region_id": "21014010",
						"region_name": "都安瑶族自治县"
					},
					"21014011": {
						"region_id": "21014011",
						"region_name": "大化瑶族自治县"
					}
				}
			}
		}
	},
	"22": {
		"region_id": "22",
		"region_name": "海南省",
		"child": {
			"22001": {
				"region_id": "22001",
				"region_name": "省直辖行政单位",
				"child": {
					"22001001": {
						"region_id": "22001001",
						"region_name": "琼海市"
					},
					"22001002": {
						"region_id": "22001002",
						"region_name": "儋州市"
					},
					"22001003": {
						"region_id": "22001003",
						"region_name": "五指山市"
					},
					"22001004": {
						"region_id": "22001004",
						"region_name": "文昌市"
					},
					"22001005": {
						"region_id": "22001005",
						"region_name": "万宁市"
					},
					"22001006": {
						"region_id": "22001006",
						"region_name": "东方市"
					},
					"22001007": {
						"region_id": "22001007",
						"region_name": "定安县"
					},
					"22001008": {
						"region_id": "22001008",
						"region_name": "屯昌县"
					},
					"22001009": {
						"region_id": "22001009",
						"region_name": "澄迈县"
					},
					"22001010": {
						"region_id": "22001010",
						"region_name": "临高县"
					},
					"22001011": {
						"region_id": "22001011",
						"region_name": "白沙黎族自治县"
					},
					"22001012": {
						"region_id": "22001012",
						"region_name": "昌江黎族自治县"
					},
					"22001013": {
						"region_id": "22001013",
						"region_name": "乐东黎族自治县"
					},
					"22001014": {
						"region_id": "22001014",
						"region_name": "陵水黎族自治县"
					},
					"22001015": {
						"region_id": "22001015",
						"region_name": "保亭黎族苗族自治县"
					},
					"22001016": {
						"region_id": "22001016",
						"region_name": "琼中黎族苗族自治县"
					},
					"22001017": {
						"region_id": "22001017",
						"region_name": "西南中沙群岛办事处"
					}
				}
			},
			"22002": {
				"region_id": "22002",
				"region_name": "海口市",
				"child": {
					"22002001": {
						"region_id": "22002001",
						"region_name": "龙华区"
					},
					"22002002": {
						"region_id": "22002002",
						"region_name": "秀英区"
					},
					"22002003": {
						"region_id": "22002003",
						"region_name": "琼山区"
					},
					"22002004": {
						"region_id": "22002004",
						"region_name": "美兰区"
					}
				}
			},
			"22003": {
				"region_id": "22003",
				"region_name": "三亚市",
				"child": {
					"22003001": {
						"region_id": "22003001",
						"region_name": "市辖区"
					}
				}
			}
		}
	},
	"23": {
		"region_id": "23",
		"region_name": "重庆市",
		"child": {
			"23001": {
				"region_id": "23001",
				"region_name": "重庆市",
				"child": {
					"23001001": {
						"region_id": "23001001",
						"region_name": "万州区"
					},
					"23001002": {
						"region_id": "23001002",
						"region_name": "涪陵区"
					},
					"23001003": {
						"region_id": "23001003",
						"region_name": "渝中区"
					},
					"23001004": {
						"region_id": "23001004",
						"region_name": "大渡口区"
					},
					"23001005": {
						"region_id": "23001005",
						"region_name": "江北区"
					},
					"23001006": {
						"region_id": "23001006",
						"region_name": "沙坪坝区"
					},
					"23001007": {
						"region_id": "23001007",
						"region_name": "九龙坡区"
					},
					"23001008": {
						"region_id": "23001008",
						"region_name": "南岸区"
					},
					"23001009": {
						"region_id": "23001009",
						"region_name": "北碚区"
					},
					"23001010": {
						"region_id": "23001010",
						"region_name": "万盛区"
					},
					"23001011": {
						"region_id": "23001011",
						"region_name": "双桥区"
					},
					"23001012": {
						"region_id": "23001012",
						"region_name": "渝北区"
					},
					"23001013": {
						"region_id": "23001013",
						"region_name": "巴南区"
					},
					"23001014": {
						"region_id": "23001014",
						"region_name": "长寿县"
					},
					"23001015": {
						"region_id": "23001015",
						"region_name": "綦江县"
					},
					"23001016": {
						"region_id": "23001016",
						"region_name": "潼南县"
					},
					"23001017": {
						"region_id": "23001017",
						"region_name": "铜梁县"
					},
					"23001018": {
						"region_id": "23001018",
						"region_name": "大足县"
					},
					"23001019": {
						"region_id": "23001019",
						"region_name": "荣昌县"
					},
					"23001020": {
						"region_id": "23001020",
						"region_name": "璧山县"
					},
					"23001021": {
						"region_id": "23001021",
						"region_name": "梁平县"
					},
					"23001022": {
						"region_id": "23001022",
						"region_name": "城口县"
					},
					"23001023": {
						"region_id": "23001023",
						"region_name": "丰都县"
					},
					"23001024": {
						"region_id": "23001024",
						"region_name": "垫江县"
					},
					"23001025": {
						"region_id": "23001025",
						"region_name": "武隆县"
					},
					"23001026": {
						"region_id": "23001026",
						"region_name": "忠县"
					},
					"23001027": {
						"region_id": "23001027",
						"region_name": "开县"
					},
					"23001028": {
						"region_id": "23001028",
						"region_name": "云阳县"
					},
					"23001029": {
						"region_id": "23001029",
						"region_name": "奉节县"
					},
					"23001030": {
						"region_id": "23001030",
						"region_name": "巫山县"
					},
					"23001031": {
						"region_id": "23001031",
						"region_name": "巫溪县"
					},
					"23001032": {
						"region_id": "23001032",
						"region_name": "黔江土家族苗族自治县"
					},
					"23001033": {
						"region_id": "23001033",
						"region_name": "石柱土家族自治县"
					},
					"23001034": {
						"region_id": "23001034",
						"region_name": "秀山土家族苗族自治县"
					},
					"23001035": {
						"region_id": "23001035",
						"region_name": "酉阳土家族苗族自治县"
					},
					"23001036": {
						"region_id": "23001036",
						"region_name": "彭水苗族土家族自治县"
					},
					"23001037": {
						"region_id": "23001037",
						"region_name": "江津市"
					},
					"23001038": {
						"region_id": "23001038",
						"region_name": "合川市"
					},
					"23001039": {
						"region_id": "23001039",
						"region_name": "永川市"
					},
					"23001040": {
						"region_id": "23001040",
						"region_name": "南川市"
					}
				}
			}
		}
	},
	"24": {
		"region_id": "24",
		"region_name": "四川省",
		"child": {
			"24001": {
				"region_id": "24001",
				"region_name": "成都市",
				"child": {
					"24001001": {
						"region_id": "24001001",
						"region_name": "锦江区"
					},
					"24001002": {
						"region_id": "24001002",
						"region_name": "青羊区"
					},
					"24001003": {
						"region_id": "24001003",
						"region_name": "金牛区"
					},
					"24001004": {
						"region_id": "24001004",
						"region_name": "武侯区"
					},
					"24001005": {
						"region_id": "24001005",
						"region_name": "成华区"
					},
					"24001006": {
						"region_id": "24001006",
						"region_name": "龙泉驿区"
					},
					"24001007": {
						"region_id": "24001007",
						"region_name": "青白江区"
					},
					"24001008": {
						"region_id": "24001008",
						"region_name": "金堂县"
					},
					"24001009": {
						"region_id": "24001009",
						"region_name": "双流县"
					},
					"24001010": {
						"region_id": "24001010",
						"region_name": "温江县"
					},
					"24001011": {
						"region_id": "24001011",
						"region_name": "郫县"
					},
					"24001012": {
						"region_id": "24001012",
						"region_name": "新都县"
					},
					"24001013": {
						"region_id": "24001013",
						"region_name": "大邑县"
					},
					"24001014": {
						"region_id": "24001014",
						"region_name": "蒲江县"
					},
					"24001015": {
						"region_id": "24001015",
						"region_name": "新津县"
					},
					"24001016": {
						"region_id": "24001016",
						"region_name": "都江堰市"
					},
					"24001017": {
						"region_id": "24001017",
						"region_name": "彭州市"
					},
					"24001018": {
						"region_id": "24001018",
						"region_name": "邛崃市"
					},
					"24001019": {
						"region_id": "24001019",
						"region_name": "崇州市"
					}
				}
			},
			"24002": {
				"region_id": "24002",
				"region_name": "自贡市",
				"child": {
					"24002001": {
						"region_id": "24002001",
						"region_name": "自流井区"
					},
					"24002002": {
						"region_id": "24002002",
						"region_name": "贡井区"
					},
					"24002003": {
						"region_id": "24002003",
						"region_name": "大安区"
					},
					"24002004": {
						"region_id": "24002004",
						"region_name": "沿滩区"
					},
					"24002005": {
						"region_id": "24002005",
						"region_name": "荣县"
					},
					"24002006": {
						"region_id": "24002006",
						"region_name": "富顺县"
					}
				}
			},
			"24003": {
				"region_id": "24003",
				"region_name": "攀枝花市",
				"child": {
					"24003001": {
						"region_id": "24003001",
						"region_name": "东区"
					},
					"24003002": {
						"region_id": "24003002",
						"region_name": "西区"
					},
					"24003003": {
						"region_id": "24003003",
						"region_name": "仁和区"
					},
					"24003004": {
						"region_id": "24003004",
						"region_name": "米易县"
					},
					"24003005": {
						"region_id": "24003005",
						"region_name": "盐边县"
					}
				}
			},
			"24004": {
				"region_id": "24004",
				"region_name": "泸州市",
				"child": {
					"24004001": {
						"region_id": "24004001",
						"region_name": "江阳区"
					},
					"24004002": {
						"region_id": "24004002",
						"region_name": "纳溪区"
					},
					"24004003": {
						"region_id": "24004003",
						"region_name": "龙马潭区"
					},
					"24004004": {
						"region_id": "24004004",
						"region_name": "泸县"
					},
					"24004005": {
						"region_id": "24004005",
						"region_name": "合江县"
					},
					"24004006": {
						"region_id": "24004006",
						"region_name": "叙永县"
					},
					"24004007": {
						"region_id": "24004007",
						"region_name": "古蔺县"
					}
				}
			},
			"24005": {
				"region_id": "24005",
				"region_name": "德阳市",
				"child": {
					"24005001": {
						"region_id": "24005001",
						"region_name": "旌阳区"
					},
					"24005002": {
						"region_id": "24005002",
						"region_name": "中江县"
					},
					"24005003": {
						"region_id": "24005003",
						"region_name": "罗江县"
					},
					"24005004": {
						"region_id": "24005004",
						"region_name": "广汉市"
					},
					"24005005": {
						"region_id": "24005005",
						"region_name": "什邡市"
					},
					"24005006": {
						"region_id": "24005006",
						"region_name": "绵竹市"
					}
				}
			},
			"24006": {
				"region_id": "24006",
				"region_name": "绵阳市",
				"child": {
					"24006001": {
						"region_id": "24006001",
						"region_name": "涪城区"
					},
					"24006002": {
						"region_id": "24006002",
						"region_name": "游仙区"
					},
					"24006003": {
						"region_id": "24006003",
						"region_name": "三台县"
					},
					"24006004": {
						"region_id": "24006004",
						"region_name": "盐亭县"
					},
					"24006005": {
						"region_id": "24006005",
						"region_name": "安县"
					},
					"24006006": {
						"region_id": "24006006",
						"region_name": "梓潼县"
					},
					"24006007": {
						"region_id": "24006007",
						"region_name": "北川县"
					},
					"24006008": {
						"region_id": "24006008",
						"region_name": "平武县"
					},
					"24006009": {
						"region_id": "24006009",
						"region_name": "江油市"
					}
				}
			},
			"24007": {
				"region_id": "24007",
				"region_name": "广元市",
				"child": {
					"24007001": {
						"region_id": "24007001",
						"region_name": "利州区"
					},
					"24007002": {
						"region_id": "24007002",
						"region_name": "元坝区"
					},
					"24007003": {
						"region_id": "24007003",
						"region_name": "朝天区"
					},
					"24007004": {
						"region_id": "24007004",
						"region_name": "旺苍县"
					},
					"24007005": {
						"region_id": "24007005",
						"region_name": "青川县"
					},
					"24007006": {
						"region_id": "24007006",
						"region_name": "剑阁县"
					},
					"24007007": {
						"region_id": "24007007",
						"region_name": "苍溪县"
					}
				}
			},
			"24008": {
				"region_id": "24008",
				"region_name": "遂宁市",
				"child": {
					"24008001": {
						"region_id": "24008001",
						"region_name": "船山区"
					},
					"24008002": {
						"region_id": "24008002",
						"region_name": "　安居区"
					},
					"24008003": {
						"region_id": "24008003",
						"region_name": "蓬溪县"
					},
					"24008004": {
						"region_id": "24008004",
						"region_name": "射洪县"
					},
					"24008005": {
						"region_id": "24008005",
						"region_name": "大英县"
					}
				}
			},
			"24009": {
				"region_id": "24009",
				"region_name": "内江市",
				"child": {
					"24009001": {
						"region_id": "24009001",
						"region_name": "市中区"
					},
					"24009002": {
						"region_id": "24009002",
						"region_name": "东兴区"
					},
					"24009003": {
						"region_id": "24009003",
						"region_name": "威远县"
					},
					"24009004": {
						"region_id": "24009004",
						"region_name": "资中县"
					},
					"24009005": {
						"region_id": "24009005",
						"region_name": "隆昌县"
					}
				}
			},
			"24010": {
				"region_id": "24010",
				"region_name": "乐山市",
				"child": {
					"24010001": {
						"region_id": "24010001",
						"region_name": "市中区"
					},
					"24010002": {
						"region_id": "24010002",
						"region_name": "沙湾区"
					},
					"24010003": {
						"region_id": "24010003",
						"region_name": "五通桥区"
					},
					"24010004": {
						"region_id": "24010004",
						"region_name": "金口河区"
					},
					"24010005": {
						"region_id": "24010005",
						"region_name": "犍为县"
					},
					"24010006": {
						"region_id": "24010006",
						"region_name": "井研县"
					},
					"24010007": {
						"region_id": "24010007",
						"region_name": "夹江县"
					},
					"24010008": {
						"region_id": "24010008",
						"region_name": "沐川县"
					},
					"24010009": {
						"region_id": "24010009",
						"region_name": "峨边彝族自治县"
					},
					"24010010": {
						"region_id": "24010010",
						"region_name": "马边彝族自治县"
					},
					"24010011": {
						"region_id": "24010011",
						"region_name": "峨眉山市"
					}
				}
			},
			"24011": {
				"region_id": "24011",
				"region_name": "南充市",
				"child": {
					"24011001": {
						"region_id": "24011001",
						"region_name": "顺庆区"
					},
					"24011002": {
						"region_id": "24011002",
						"region_name": "高坪区"
					},
					"24011003": {
						"region_id": "24011003",
						"region_name": "嘉陵区"
					},
					"24011004": {
						"region_id": "24011004",
						"region_name": "南部县"
					},
					"24011005": {
						"region_id": "24011005",
						"region_name": "营山县"
					},
					"24011006": {
						"region_id": "24011006",
						"region_name": "蓬安县"
					},
					"24011007": {
						"region_id": "24011007",
						"region_name": "仪陇县"
					},
					"24011008": {
						"region_id": "24011008",
						"region_name": "西充县"
					},
					"24011009": {
						"region_id": "24011009",
						"region_name": "阆中市"
					}
				}
			},
			"24012": {
				"region_id": "24012",
				"region_name": "宜宾市",
				"child": {
					"24012001": {
						"region_id": "24012001",
						"region_name": "翠屏区"
					},
					"24012002": {
						"region_id": "24012002",
						"region_name": "宜宾县"
					},
					"24012003": {
						"region_id": "24012003",
						"region_name": "南溪县"
					},
					"24012004": {
						"region_id": "24012004",
						"region_name": "江安县"
					},
					"24012005": {
						"region_id": "24012005",
						"region_name": "长宁县"
					},
					"24012006": {
						"region_id": "24012006",
						"region_name": "高县"
					},
					"24012007": {
						"region_id": "24012007",
						"region_name": "珙县"
					},
					"24012008": {
						"region_id": "24012008",
						"region_name": "筠连县"
					},
					"24012009": {
						"region_id": "24012009",
						"region_name": "兴文县"
					},
					"24012010": {
						"region_id": "24012010",
						"region_name": "屏山县"
					}
				}
			},
			"24013": {
				"region_id": "24013",
				"region_name": "广安市",
				"child": {
					"24013001": {
						"region_id": "24013001",
						"region_name": "广安区"
					},
					"24013002": {
						"region_id": "24013002",
						"region_name": "岳池县"
					},
					"24013003": {
						"region_id": "24013003",
						"region_name": "武胜县"
					},
					"24013004": {
						"region_id": "24013004",
						"region_name": "邻水县"
					},
					"24013005": {
						"region_id": "24013005",
						"region_name": "华莹市"
					}
				}
			},
			"24014": {
				"region_id": "24014",
				"region_name": "达川地区",
				"child": {
					"24014001": {
						"region_id": "24014001",
						"region_name": "达川市"
					},
					"24014002": {
						"region_id": "24014002",
						"region_name": "万源市"
					},
					"24014003": {
						"region_id": "24014003",
						"region_name": "达县"
					},
					"24014004": {
						"region_id": "24014004",
						"region_name": "宣汉县"
					},
					"24014005": {
						"region_id": "24014005",
						"region_name": "开江县"
					},
					"24014006": {
						"region_id": "24014006",
						"region_name": "大竹县"
					},
					"24014007": {
						"region_id": "24014007",
						"region_name": "渠县"
					}
				}
			},
			"24015": {
				"region_id": "24015",
				"region_name": "雅安地区",
				"child": {
					"24015001": {
						"region_id": "24015001",
						"region_name": "雅安市"
					},
					"24015002": {
						"region_id": "24015002",
						"region_name": "名山县"
					},
					"24015003": {
						"region_id": "24015003",
						"region_name": "荥经县"
					},
					"24015004": {
						"region_id": "24015004",
						"region_name": "汉源县"
					},
					"24015005": {
						"region_id": "24015005",
						"region_name": "石棉县"
					},
					"24015006": {
						"region_id": "24015006",
						"region_name": "天全县"
					},
					"24015007": {
						"region_id": "24015007",
						"region_name": "芦山县"
					},
					"24015008": {
						"region_id": "24015008",
						"region_name": "宝兴县"
					}
				}
			},
			"24016": {
				"region_id": "24016",
				"region_name": "阿坝藏族羌族自治州",
				"child": {
					"24016001": {
						"region_id": "24016001",
						"region_name": "汶川县"
					},
					"24016002": {
						"region_id": "24016002",
						"region_name": "理县"
					},
					"24016003": {
						"region_id": "24016003",
						"region_name": "茂县"
					},
					"24016004": {
						"region_id": "24016004",
						"region_name": "松潘县"
					},
					"24016005": {
						"region_id": "24016005",
						"region_name": "九寨沟县"
					},
					"24016006": {
						"region_id": "24016006",
						"region_name": "金川县"
					},
					"24016007": {
						"region_id": "24016007",
						"region_name": "小金县"
					},
					"24016008": {
						"region_id": "24016008",
						"region_name": "黑水县"
					},
					"24016009": {
						"region_id": "24016009",
						"region_name": "马尔康县"
					},
					"24016010": {
						"region_id": "24016010",
						"region_name": "壤塘县"
					},
					"24016011": {
						"region_id": "24016011",
						"region_name": "阿坝县"
					},
					"24016012": {
						"region_id": "24016012",
						"region_name": "若尔盖县"
					},
					"24016013": {
						"region_id": "24016013",
						"region_name": "红原县"
					}
				}
			},
			"24017": {
				"region_id": "24017",
				"region_name": "甘孜藏族自治州",
				"child": {
					"24017001": {
						"region_id": "24017001",
						"region_name": "康定县"
					},
					"24017002": {
						"region_id": "24017002",
						"region_name": "泸定县"
					},
					"24017003": {
						"region_id": "24017003",
						"region_name": "丹巴县"
					},
					"24017004": {
						"region_id": "24017004",
						"region_name": "九龙县"
					},
					"24017005": {
						"region_id": "24017005",
						"region_name": "雅江县"
					},
					"24017006": {
						"region_id": "24017006",
						"region_name": "道孚县"
					},
					"24017007": {
						"region_id": "24017007",
						"region_name": "炉霍县"
					},
					"24017008": {
						"region_id": "24017008",
						"region_name": "甘孜县"
					},
					"24017009": {
						"region_id": "24017009",
						"region_name": "新龙县"
					},
					"24017010": {
						"region_id": "24017010",
						"region_name": "德格县"
					},
					"24017011": {
						"region_id": "24017011",
						"region_name": "白玉县"
					},
					"24017012": {
						"region_id": "24017012",
						"region_name": "石渠县"
					},
					"24017013": {
						"region_id": "24017013",
						"region_name": "色达县"
					},
					"24017014": {
						"region_id": "24017014",
						"region_name": "理塘县"
					},
					"24017015": {
						"region_id": "24017015",
						"region_name": "巴塘县"
					},
					"24017016": {
						"region_id": "24017016",
						"region_name": "乡城县"
					},
					"24017017": {
						"region_id": "24017017",
						"region_name": "稻城县"
					},
					"24017018": {
						"region_id": "24017018",
						"region_name": "得荣县"
					}
				}
			},
			"24018": {
				"region_id": "24018",
				"region_name": "凉山彝族自治州",
				"child": {
					"24018001": {
						"region_id": "24018001",
						"region_name": "西昌市"
					},
					"24018002": {
						"region_id": "24018002",
						"region_name": "木里藏族自治县"
					},
					"24018003": {
						"region_id": "24018003",
						"region_name": "盐源县"
					},
					"24018004": {
						"region_id": "24018004",
						"region_name": "德昌县"
					},
					"24018005": {
						"region_id": "24018005",
						"region_name": "会理县"
					},
					"24018006": {
						"region_id": "24018006",
						"region_name": "会东县"
					},
					"24018007": {
						"region_id": "24018007",
						"region_name": "宁南县"
					},
					"24018008": {
						"region_id": "24018008",
						"region_name": "普格县"
					},
					"24018009": {
						"region_id": "24018009",
						"region_name": "布拖县"
					},
					"24018010": {
						"region_id": "24018010",
						"region_name": "金阳县"
					},
					"24018011": {
						"region_id": "24018011",
						"region_name": "昭觉县"
					},
					"24018012": {
						"region_id": "24018012",
						"region_name": "喜德县"
					},
					"24018013": {
						"region_id": "24018013",
						"region_name": "冕宁县"
					},
					"24018014": {
						"region_id": "24018014",
						"region_name": "越西县"
					},
					"24018015": {
						"region_id": "24018015",
						"region_name": "甘洛县"
					},
					"24018016": {
						"region_id": "24018016",
						"region_name": "美姑县"
					},
					"24018017": {
						"region_id": "24018017",
						"region_name": "雷波县"
					}
				}
			},
			"24019": {
				"region_id": "24019",
				"region_name": "巴中地区",
				"child": {
					"24019001": {
						"region_id": "24019001",
						"region_name": "巴中市"
					},
					"24019002": {
						"region_id": "24019002",
						"region_name": "通江县"
					},
					"24019003": {
						"region_id": "24019003",
						"region_name": "南江县"
					},
					"24019004": {
						"region_id": "24019004",
						"region_name": "平昌县"
					}
				}
			},
			"24020": {
				"region_id": "24020",
				"region_name": "眉山地区",
				"child": {
					"24020001": {
						"region_id": "24020001",
						"region_name": "眉山县"
					},
					"24020002": {
						"region_id": "24020002",
						"region_name": "仁寿县"
					},
					"24020003": {
						"region_id": "24020003",
						"region_name": "彭山县"
					},
					"24020004": {
						"region_id": "24020004",
						"region_name": "洪雅县"
					},
					"24020005": {
						"region_id": "24020005",
						"region_name": "丹棱县"
					},
					"24020006": {
						"region_id": "24020006",
						"region_name": "青神县"
					}
				}
			},
			"24021": {
				"region_id": "24021",
				"region_name": "资阳地区",
				"child": {
					"24021001": {
						"region_id": "24021001",
						"region_name": "资阳市"
					},
					"24021002": {
						"region_id": "24021002",
						"region_name": "简阳市"
					},
					"24021003": {
						"region_id": "24021003",
						"region_name": "安岳县"
					},
					"24021004": {
						"region_id": "24021004",
						"region_name": "乐至县"
					}
				}
			}
		}
	},
	"25": {
		"region_id": "25",
		"region_name": "贵州省",
		"child": {
			"25001": {
				"region_id": "25001",
				"region_name": "贵阳市",
				"child": {
					"25001001": {
						"region_id": "25001001",
						"region_name": "南明区"
					},
					"25001002": {
						"region_id": "25001002",
						"region_name": "云岩区"
					},
					"25001003": {
						"region_id": "25001003",
						"region_name": "花溪区"
					},
					"25001004": {
						"region_id": "25001004",
						"region_name": "乌当区"
					},
					"25001005": {
						"region_id": "25001005",
						"region_name": "白云区"
					},
					"25001006": {
						"region_id": "25001006",
						"region_name": "小河区"
					},
					"25001007": {
						"region_id": "25001007",
						"region_name": "开阳县"
					},
					"25001008": {
						"region_id": "25001008",
						"region_name": "息烽县"
					},
					"25001009": {
						"region_id": "25001009",
						"region_name": "修文县"
					},
					"25001010": {
						"region_id": "25001010",
						"region_name": "清镇市"
					}
				}
			},
			"25002": {
				"region_id": "25002",
				"region_name": "六盘水市",
				"child": {
					"25002001": {
						"region_id": "25002001",
						"region_name": "钟山区"
					},
					"25002002": {
						"region_id": "25002002",
						"region_name": "盘县特区"
					},
					"25002003": {
						"region_id": "25002003",
						"region_name": "六枝特区"
					},
					"25002004": {
						"region_id": "25002004",
						"region_name": "水城县"
					}
				}
			},
			"25003": {
				"region_id": "25003",
				"region_name": "遵义市",
				"child": {
					"25003001": {
						"region_id": "25003001",
						"region_name": "红花岗区"
					},
					"25003002": {
						"region_id": "25003002",
						"region_name": "汇川区"
					},
					"25003003": {
						"region_id": "25003003",
						"region_name": "遵义县"
					},
					"25003004": {
						"region_id": "25003004",
						"region_name": "桐梓县"
					},
					"25003005": {
						"region_id": "25003005",
						"region_name": "绥阳县"
					},
					"25003006": {
						"region_id": "25003006",
						"region_name": "正安县"
					},
					"25003007": {
						"region_id": "25003007",
						"region_name": "道真仡佬族苗族自治县"
					},
					"25003008": {
						"region_id": "25003008",
						"region_name": "务川仡佬族苗族自治县"
					},
					"25003009": {
						"region_id": "25003009",
						"region_name": "凤冈县"
					},
					"25003010": {
						"region_id": "25003010",
						"region_name": "湄潭县"
					},
					"25003011": {
						"region_id": "25003011",
						"region_name": "余庆县"
					},
					"25003012": {
						"region_id": "25003012",
						"region_name": "习水县"
					},
					"25003013": {
						"region_id": "25003013",
						"region_name": "赤水市"
					},
					"25003014": {
						"region_id": "25003014",
						"region_name": "仁怀市"
					}
				}
			},
			"25004": {
				"region_id": "25004",
				"region_name": "铜仁地区",
				"child": {
					"25004001": {
						"region_id": "25004001",
						"region_name": "铜仁市"
					},
					"25004002": {
						"region_id": "25004002",
						"region_name": "江口县"
					},
					"25004003": {
						"region_id": "25004003",
						"region_name": "玉屏侗族自治县"
					},
					"25004004": {
						"region_id": "25004004",
						"region_name": "石阡县"
					},
					"25004005": {
						"region_id": "25004005",
						"region_name": "思南县"
					},
					"25004006": {
						"region_id": "25004006",
						"region_name": "印江土家族苗族自治县"
					},
					"25004007": {
						"region_id": "25004007",
						"region_name": "德江县"
					},
					"25004008": {
						"region_id": "25004008",
						"region_name": "沿河土家族自治县"
					},
					"25004009": {
						"region_id": "25004009",
						"region_name": "松桃苗族自治县"
					},
					"25004010": {
						"region_id": "25004010",
						"region_name": "万山特区"
					}
				}
			},
			"25005": {
				"region_id": "25005",
				"region_name": "黔西南布依族苗族自治州",
				"child": {
					"25005001": {
						"region_id": "25005001",
						"region_name": "兴义市"
					},
					"25005002": {
						"region_id": "25005002",
						"region_name": "兴仁县"
					},
					"25005003": {
						"region_id": "25005003",
						"region_name": "普安县"
					},
					"25005004": {
						"region_id": "25005004",
						"region_name": "晴隆县"
					},
					"25005005": {
						"region_id": "25005005",
						"region_name": "贞丰县"
					},
					"25005006": {
						"region_id": "25005006",
						"region_name": "望谟县"
					},
					"25005007": {
						"region_id": "25005007",
						"region_name": "册亨县"
					},
					"25005008": {
						"region_id": "25005008",
						"region_name": "安龙县"
					}
				}
			},
			"25006": {
				"region_id": "25006",
				"region_name": "毕节地区",
				"child": {
					"25006001": {
						"region_id": "25006001",
						"region_name": "毕节市"
					},
					"25006002": {
						"region_id": "25006002",
						"region_name": "大方县"
					},
					"25006003": {
						"region_id": "25006003",
						"region_name": "黔西县"
					},
					"25006004": {
						"region_id": "25006004",
						"region_name": "金沙县"
					},
					"25006005": {
						"region_id": "25006005",
						"region_name": "织金县"
					},
					"25006006": {
						"region_id": "25006006",
						"region_name": "纳雍县"
					},
					"25006007": {
						"region_id": "25006007",
						"region_name": "威宁彝族回族苗族自治县"
					},
					"25006008": {
						"region_id": "25006008",
						"region_name": "赫章县"
					}
				}
			},
			"25007": {
				"region_id": "25007",
				"region_name": "安顺地区",
				"child": {
					"25007001": {
						"region_id": "25007001",
						"region_name": "安顺市"
					},
					"25007002": {
						"region_id": "25007002",
						"region_name": "平坝县"
					},
					"25007003": {
						"region_id": "25007003",
						"region_name": "普定县"
					},
					"25007004": {
						"region_id": "25007004",
						"region_name": "关岭布依族苗族自治县"
					},
					"25007005": {
						"region_id": "25007005",
						"region_name": "镇宁布依族苗族自治县"
					},
					"25007006": {
						"region_id": "25007006",
						"region_name": "紫云苗族布依族自治县"
					}
				}
			},
			"25008": {
				"region_id": "25008",
				"region_name": "黔东南苗族侗族自治州",
				"child": {
					"25008001": {
						"region_id": "25008001",
						"region_name": "凯里市"
					},
					"25008002": {
						"region_id": "25008002",
						"region_name": "黄平县"
					},
					"25008003": {
						"region_id": "25008003",
						"region_name": "施秉县"
					},
					"25008004": {
						"region_id": "25008004",
						"region_name": "三穗县"
					},
					"25008005": {
						"region_id": "25008005",
						"region_name": "镇远县"
					},
					"25008006": {
						"region_id": "25008006",
						"region_name": "岑巩县"
					},
					"25008007": {
						"region_id": "25008007",
						"region_name": "天柱县"
					},
					"25008008": {
						"region_id": "25008008",
						"region_name": "锦屏县"
					},
					"25008009": {
						"region_id": "25008009",
						"region_name": "剑河县"
					},
					"25008010": {
						"region_id": "25008010",
						"region_name": "台江县"
					},
					"25008011": {
						"region_id": "25008011",
						"region_name": "黎平县"
					},
					"25008012": {
						"region_id": "25008012",
						"region_name": "榕江县"
					},
					"25008013": {
						"region_id": "25008013",
						"region_name": "从江县"
					},
					"25008014": {
						"region_id": "25008014",
						"region_name": "雷山县"
					},
					"25008015": {
						"region_id": "25008015",
						"region_name": "麻江县"
					},
					"25008016": {
						"region_id": "25008016",
						"region_name": "丹寨县"
					}
				}
			},
			"25009": {
				"region_id": "25009",
				"region_name": "黔南布依族苗族自治州",
				"child": {
					"25009001": {
						"region_id": "25009001",
						"region_name": "都匀市"
					},
					"25009002": {
						"region_id": "25009002",
						"region_name": "福泉市"
					},
					"25009003": {
						"region_id": "25009003",
						"region_name": "荔波县"
					},
					"25009004": {
						"region_id": "25009004",
						"region_name": "贵定县"
					},
					"25009005": {
						"region_id": "25009005",
						"region_name": "瓮安县"
					},
					"25009006": {
						"region_id": "25009006",
						"region_name": "独山县"
					},
					"25009007": {
						"region_id": "25009007",
						"region_name": "平塘县"
					},
					"25009008": {
						"region_id": "25009008",
						"region_name": "罗甸县"
					},
					"25009009": {
						"region_id": "25009009",
						"region_name": "长顺县"
					},
					"25009010": {
						"region_id": "25009010",
						"region_name": "龙里县"
					},
					"25009011": {
						"region_id": "25009011",
						"region_name": "惠水县"
					},
					"25009012": {
						"region_id": "25009012",
						"region_name": "三都水族自治县"
					}
				}
			}
		}
	},
	"26": {
		"region_id": "26",
		"region_name": "云南省",
		"child": {
			"26001": {
				"region_id": "26001",
				"region_name": "昆明市",
				"child": {
					"26001001": {
						"region_id": "26001001",
						"region_name": "五华区"
					},
					"26001002": {
						"region_id": "26001002",
						"region_name": "盘龙区"
					},
					"26001003": {
						"region_id": "26001003",
						"region_name": "官渡区"
					},
					"26001004": {
						"region_id": "26001004",
						"region_name": "西山区"
					},
					"26001005": {
						"region_id": "26001005",
						"region_name": "东川区"
					},
					"26001006": {
						"region_id": "26001006",
						"region_name": "呈贡县"
					},
					"26001007": {
						"region_id": "26001007",
						"region_name": "晋宁县"
					},
					"26001008": {
						"region_id": "26001008",
						"region_name": "富民县"
					},
					"26001009": {
						"region_id": "26001009",
						"region_name": "宜良县"
					},
					"26001010": {
						"region_id": "26001010",
						"region_name": "石林彝族自治县"
					},
					"26001011": {
						"region_id": "26001011",
						"region_name": "嵩明县"
					},
					"26001012": {
						"region_id": "26001012",
						"region_name": "禄劝彝族苗族自治县"
					},
					"26001013": {
						"region_id": "26001013",
						"region_name": "寻甸回族彝族自治县"
					},
					"26001014": {
						"region_id": "26001014",
						"region_name": "安宁市"
					}
				}
			},
			"26002": {
				"region_id": "26002",
				"region_name": "曲靖市",
				"child": {
					"26002001": {
						"region_id": "26002001",
						"region_name": "麒麟区"
					},
					"26002002": {
						"region_id": "26002002",
						"region_name": "马龙县"
					},
					"26002003": {
						"region_id": "26002003",
						"region_name": "陆良县"
					},
					"26002004": {
						"region_id": "26002004",
						"region_name": "师宗县"
					},
					"26002005": {
						"region_id": "26002005",
						"region_name": "罗平县"
					},
					"26002006": {
						"region_id": "26002006",
						"region_name": "富源县"
					},
					"26002007": {
						"region_id": "26002007",
						"region_name": "会泽县"
					},
					"26002008": {
						"region_id": "26002008",
						"region_name": "沾益县"
					},
					"26002009": {
						"region_id": "26002009",
						"region_name": "宣威市"
					}
				}
			},
			"26003": {
				"region_id": "26003",
				"region_name": "玉溪市",
				"child": {
					"26003001": {
						"region_id": "26003001",
						"region_name": "红塔区"
					},
					"26003002": {
						"region_id": "26003002",
						"region_name": "江川县"
					},
					"26003003": {
						"region_id": "26003003",
						"region_name": "澄江县"
					},
					"26003004": {
						"region_id": "26003004",
						"region_name": "通海县"
					},
					"26003005": {
						"region_id": "26003005",
						"region_name": "华宁县"
					},
					"26003006": {
						"region_id": "26003006",
						"region_name": "易门县"
					},
					"26003007": {
						"region_id": "26003007",
						"region_name": "峨山彝族自治县"
					},
					"26003008": {
						"region_id": "26003008",
						"region_name": "新平彝族傣族自治县"
					},
					"26003009": {
						"region_id": "26003009",
						"region_name": "元江哈尼族彝族傣族自治县"
					}
				}
			},
			"26004": {
				"region_id": "26004",
				"region_name": "昭通地区",
				"child": {
					"26004001": {
						"region_id": "26004001",
						"region_name": "昭通市"
					},
					"26004002": {
						"region_id": "26004002",
						"region_name": "鲁甸县"
					},
					"26004003": {
						"region_id": "26004003",
						"region_name": "巧家县"
					},
					"26004004": {
						"region_id": "26004004",
						"region_name": "盐津县"
					},
					"26004005": {
						"region_id": "26004005",
						"region_name": "大关县"
					},
					"26004006": {
						"region_id": "26004006",
						"region_name": "永善县"
					},
					"26004007": {
						"region_id": "26004007",
						"region_name": "绥江县"
					},
					"26004008": {
						"region_id": "26004008",
						"region_name": "镇雄县"
					},
					"26004009": {
						"region_id": "26004009",
						"region_name": "彝良县"
					},
					"26004010": {
						"region_id": "26004010",
						"region_name": "威信县"
					},
					"26004011": {
						"region_id": "26004011",
						"region_name": "水富县"
					}
				}
			},
			"26005": {
				"region_id": "26005",
				"region_name": "楚雄彝族自治州",
				"child": {
					"26005001": {
						"region_id": "26005001",
						"region_name": "楚雄市"
					},
					"26005002": {
						"region_id": "26005002",
						"region_name": "双柏县"
					},
					"26005003": {
						"region_id": "26005003",
						"region_name": "牟定县"
					},
					"26005004": {
						"region_id": "26005004",
						"region_name": "南华县"
					},
					"26005005": {
						"region_id": "26005005",
						"region_name": "姚安县"
					},
					"26005006": {
						"region_id": "26005006",
						"region_name": "大姚县"
					},
					"26005007": {
						"region_id": "26005007",
						"region_name": "永仁县"
					},
					"26005008": {
						"region_id": "26005008",
						"region_name": "元谋县"
					},
					"26005009": {
						"region_id": "26005009",
						"region_name": "武定县"
					},
					"26005010": {
						"region_id": "26005010",
						"region_name": "禄丰县"
					}
				}
			},
			"26006": {
				"region_id": "26006",
				"region_name": "红河哈尼族彝族自治州",
				"child": {
					"26006001": {
						"region_id": "26006001",
						"region_name": "个旧市"
					},
					"26006002": {
						"region_id": "26006002",
						"region_name": "开远市"
					},
					"26006003": {
						"region_id": "26006003",
						"region_name": "蒙自县"
					},
					"26006004": {
						"region_id": "26006004",
						"region_name": "屏边苗族自治县"
					},
					"26006005": {
						"region_id": "26006005",
						"region_name": "建水县"
					},
					"26006006": {
						"region_id": "26006006",
						"region_name": "石屏县"
					},
					"26006007": {
						"region_id": "26006007",
						"region_name": "弥勒县"
					},
					"26006008": {
						"region_id": "26006008",
						"region_name": "泸西县"
					},
					"26006009": {
						"region_id": "26006009",
						"region_name": "元阳县"
					},
					"26006010": {
						"region_id": "26006010",
						"region_name": "红河县"
					},
					"26006011": {
						"region_id": "26006011",
						"region_name": "金平苗族瑶族傣族自治县"
					},
					"26006012": {
						"region_id": "26006012",
						"region_name": "绿春县"
					},
					"26006013": {
						"region_id": "26006013",
						"region_name": "河口瑶族自治县"
					}
				}
			},
			"26007": {
				"region_id": "26007",
				"region_name": "文山壮族苗族自治州",
				"child": {
					"26007001": {
						"region_id": "26007001",
						"region_name": "文山县"
					},
					"26007002": {
						"region_id": "26007002",
						"region_name": "砚山县"
					},
					"26007003": {
						"region_id": "26007003",
						"region_name": "西畴县"
					},
					"26007004": {
						"region_id": "26007004",
						"region_name": "麻栗坡县"
					},
					"26007005": {
						"region_id": "26007005",
						"region_name": "马关县"
					},
					"26007006": {
						"region_id": "26007006",
						"region_name": "丘北县"
					},
					"26007007": {
						"region_id": "26007007",
						"region_name": "广南县"
					},
					"26007008": {
						"region_id": "26007008",
						"region_name": "富宁县"
					}
				}
			},
			"26008": {
				"region_id": "26008",
				"region_name": "思茅市",
				"child": {
					"26008001": {
						"region_id": "26008001",
						"region_name": "思茅市"
					},
					"26008002": {
						"region_id": "26008002",
						"region_name": "普洱哈尼族彝族自治县"
					},
					"26008003": {
						"region_id": "26008003",
						"region_name": "墨江哈尼族自治县"
					},
					"26008004": {
						"region_id": "26008004",
						"region_name": "景东彝族自治县"
					},
					"26008005": {
						"region_id": "26008005",
						"region_name": "景谷傣族彝族自治县"
					},
					"26008006": {
						"region_id": "26008006",
						"region_name": "镇沅彝族哈尼族拉祜族自治县"
					},
					"26008007": {
						"region_id": "26008007",
						"region_name": "江城哈尼族彝族自治县"
					},
					"26008008": {
						"region_id": "26008008",
						"region_name": "孟连傣族拉祜族佤族自治县"
					},
					"26008009": {
						"region_id": "26008009",
						"region_name": "澜沧拉祜族自治县"
					},
					"26008010": {
						"region_id": "26008010",
						"region_name": "西盟佤族自治县"
					}
				}
			},
			"26009": {
				"region_id": "26009",
				"region_name": "西双版纳傣族自治州",
				"child": {
					"26009001": {
						"region_id": "26009001",
						"region_name": "景洪市"
					},
					"26009002": {
						"region_id": "26009002",
						"region_name": "勐海县"
					},
					"26009003": {
						"region_id": "26009003",
						"region_name": "勐腊县"
					}
				}
			},
			"26010": {
				"region_id": "26010",
				"region_name": "大理白族自治州",
				"child": {
					"26010001": {
						"region_id": "26010001",
						"region_name": "大理市"
					},
					"26010002": {
						"region_id": "26010002",
						"region_name": "漾濞彝族自治县"
					},
					"26010003": {
						"region_id": "26010003",
						"region_name": "祥云县"
					},
					"26010004": {
						"region_id": "26010004",
						"region_name": "宾川县"
					},
					"26010005": {
						"region_id": "26010005",
						"region_name": "弥渡县"
					},
					"26010006": {
						"region_id": "26010006",
						"region_name": "南涧彝族自治县"
					},
					"26010007": {
						"region_id": "26010007",
						"region_name": "巍山彝族回族自治县"
					},
					"26010008": {
						"region_id": "26010008",
						"region_name": "永平县"
					},
					"26010009": {
						"region_id": "26010009",
						"region_name": "云龙县"
					},
					"26010010": {
						"region_id": "26010010",
						"region_name": "洱源县"
					},
					"26010011": {
						"region_id": "26010011",
						"region_name": "剑川县"
					},
					"26010012": {
						"region_id": "26010012",
						"region_name": "鹤庆县"
					}
				}
			},
			"26011": {
				"region_id": "26011",
				"region_name": "保山地区",
				"child": {
					"26011001": {
						"region_id": "26011001",
						"region_name": "保山市"
					},
					"26011002": {
						"region_id": "26011002",
						"region_name": "施甸县"
					},
					"26011003": {
						"region_id": "26011003",
						"region_name": "腾冲县"
					},
					"26011004": {
						"region_id": "26011004",
						"region_name": "龙陵县"
					},
					"26011005": {
						"region_id": "26011005",
						"region_name": "昌宁县"
					}
				}
			},
			"26012": {
				"region_id": "26012",
				"region_name": "德宏傣族景颇族自治州",
				"child": {
					"26012001": {
						"region_id": "26012001",
						"region_name": "畹町市"
					},
					"26012002": {
						"region_id": "26012002",
						"region_name": "瑞丽市"
					},
					"26012003": {
						"region_id": "26012003",
						"region_name": "潞西市"
					},
					"26012004": {
						"region_id": "26012004",
						"region_name": "梁河县"
					},
					"26012005": {
						"region_id": "26012005",
						"region_name": "盈江县"
					},
					"26012006": {
						"region_id": "26012006",
						"region_name": "陇川县"
					}
				}
			},
			"26013": {
				"region_id": "26013",
				"region_name": "丽江地区",
				"child": {
					"26013001": {
						"region_id": "26013001",
						"region_name": "丽江纳西族自治县"
					},
					"26013002": {
						"region_id": "26013002",
						"region_name": "永胜县"
					},
					"26013003": {
						"region_id": "26013003",
						"region_name": "华坪县"
					},
					"26013004": {
						"region_id": "26013004",
						"region_name": "宁蒗彝族自治县"
					}
				}
			},
			"26014": {
				"region_id": "26014",
				"region_name": "怒江傈僳族自治州",
				"child": {
					"26014001": {
						"region_id": "26014001",
						"region_name": "泸水县"
					},
					"26014002": {
						"region_id": "26014002",
						"region_name": "福贡县"
					},
					"26014003": {
						"region_id": "26014003",
						"region_name": "贡山独龙族怒族自治县"
					},
					"26014004": {
						"region_id": "26014004",
						"region_name": "兰坪白族普米族自治县"
					}
				}
			},
			"26015": {
				"region_id": "26015",
				"region_name": "迪庆藏族自治州",
				"child": {
					"26015001": {
						"region_id": "26015001",
						"region_name": "中甸县"
					},
					"26015002": {
						"region_id": "26015002",
						"region_name": "德钦县"
					},
					"26015003": {
						"region_id": "26015003",
						"region_name": "维西傈僳族自治县"
					}
				}
			},
			"26016": {
				"region_id": "26016",
				"region_name": "临沧地区",
				"child": {
					"26016001": {
						"region_id": "26016001",
						"region_name": "临沧县"
					},
					"26016002": {
						"region_id": "26016002",
						"region_name": "凤庆县"
					},
					"26016003": {
						"region_id": "26016003",
						"region_name": "云县"
					},
					"26016004": {
						"region_id": "26016004",
						"region_name": "永德县"
					},
					"26016005": {
						"region_id": "26016005",
						"region_name": "镇康县"
					},
					"26016006": {
						"region_id": "26016006",
						"region_name": "双江拉祜族佤族布朗族傣族自治县"
					},
					"26016007": {
						"region_id": "26016007",
						"region_name": "耿马傣族佤族自治县"
					},
					"26016008": {
						"region_id": "26016008",
						"region_name": "沧源佤族自治县"
					}
				}
			}
		}
	},
	"27": {
		"region_id": "27",
		"region_name": "西藏省",
		"child": {
			"27001": {
				"region_id": "27001",
				"region_name": "拉萨市",
				"child": {
					"27001001": {
						"region_id": "27001001",
						"region_name": "城关区"
					},
					"27001002": {
						"region_id": "27001002",
						"region_name": "林周县"
					},
					"27001003": {
						"region_id": "27001003",
						"region_name": "当雄县"
					},
					"27001004": {
						"region_id": "27001004",
						"region_name": "尼木县"
					},
					"27001005": {
						"region_id": "27001005",
						"region_name": "曲水县"
					},
					"27001006": {
						"region_id": "27001006",
						"region_name": "堆龙德庆县"
					},
					"27001007": {
						"region_id": "27001007",
						"region_name": "达孜县"
					},
					"27001008": {
						"region_id": "27001008",
						"region_name": "墨竹工卡县"
					}
				}
			},
			"27002": {
				"region_id": "27002",
				"region_name": "昌都地区",
				"child": {
					"27002001": {
						"region_id": "27002001",
						"region_name": "昌都县"
					},
					"27002002": {
						"region_id": "27002002",
						"region_name": "江达县"
					},
					"27002003": {
						"region_id": "27002003",
						"region_name": "贡觉县"
					},
					"27002004": {
						"region_id": "27002004",
						"region_name": "类乌齐县"
					},
					"27002005": {
						"region_id": "27002005",
						"region_name": "丁青县"
					},
					"27002006": {
						"region_id": "27002006",
						"region_name": "察雅县"
					},
					"27002007": {
						"region_id": "27002007",
						"region_name": "八宿县"
					},
					"27002008": {
						"region_id": "27002008",
						"region_name": "左贡县"
					},
					"27002009": {
						"region_id": "27002009",
						"region_name": "芒康县"
					},
					"27002010": {
						"region_id": "27002010",
						"region_name": "洛隆县"
					},
					"27002011": {
						"region_id": "27002011",
						"region_name": "边坝县"
					}
				}
			},
			"27003": {
				"region_id": "27003",
				"region_name": "山南地区",
				"child": {
					"27003001": {
						"region_id": "27003001",
						"region_name": "乃东县"
					},
					"27003002": {
						"region_id": "27003002",
						"region_name": "扎囊县"
					},
					"27003003": {
						"region_id": "27003003",
						"region_name": "贡嘎县"
					},
					"27003004": {
						"region_id": "27003004",
						"region_name": "桑日县"
					},
					"27003005": {
						"region_id": "27003005",
						"region_name": "琼结县"
					},
					"27003006": {
						"region_id": "27003006",
						"region_name": "曲松县"
					},
					"27003007": {
						"region_id": "27003007",
						"region_name": "措美县"
					},
					"27003008": {
						"region_id": "27003008",
						"region_name": "洛扎县"
					},
					"27003009": {
						"region_id": "27003009",
						"region_name": "加查县"
					},
					"27003010": {
						"region_id": "27003010",
						"region_name": "隆子县"
					},
					"27003011": {
						"region_id": "27003011",
						"region_name": "错那县"
					},
					"27003012": {
						"region_id": "27003012",
						"region_name": "浪卡子县"
					}
				}
			},
			"27004": {
				"region_id": "27004",
				"region_name": "日喀则地区",
				"child": {
					"27004001": {
						"region_id": "27004001",
						"region_name": "日喀则市"
					},
					"27004002": {
						"region_id": "27004002",
						"region_name": "南木林县"
					},
					"27004003": {
						"region_id": "27004003",
						"region_name": "江孜县"
					},
					"27004004": {
						"region_id": "27004004",
						"region_name": "定日县"
					},
					"27004005": {
						"region_id": "27004005",
						"region_name": "萨迦县"
					},
					"27004006": {
						"region_id": "27004006",
						"region_name": "拉孜县"
					},
					"27004007": {
						"region_id": "27004007",
						"region_name": "昂仁县"
					},
					"27004008": {
						"region_id": "27004008",
						"region_name": "谢通门县"
					},
					"27004009": {
						"region_id": "27004009",
						"region_name": "白朗县"
					},
					"27004010": {
						"region_id": "27004010",
						"region_name": "仁布县"
					},
					"27004011": {
						"region_id": "27004011",
						"region_name": "康马县"
					},
					"27004012": {
						"region_id": "27004012",
						"region_name": "定结县"
					},
					"27004013": {
						"region_id": "27004013",
						"region_name": "仲巴县"
					},
					"27004014": {
						"region_id": "27004014",
						"region_name": "亚东县"
					},
					"27004015": {
						"region_id": "27004015",
						"region_name": "吉隆县"
					},
					"27004016": {
						"region_id": "27004016",
						"region_name": "聂拉木县"
					},
					"27004017": {
						"region_id": "27004017",
						"region_name": "萨嘎县"
					},
					"27004018": {
						"region_id": "27004018",
						"region_name": "岗巴县"
					}
				}
			},
			"27005": {
				"region_id": "27005",
				"region_name": "那曲地区",
				"child": {
					"27005001": {
						"region_id": "27005001",
						"region_name": "那曲县"
					},
					"27005002": {
						"region_id": "27005002",
						"region_name": "嘉黎县"
					},
					"27005003": {
						"region_id": "27005003",
						"region_name": "比如县"
					},
					"27005004": {
						"region_id": "27005004",
						"region_name": "聂荣县"
					},
					"27005005": {
						"region_id": "27005005",
						"region_name": "安多县"
					},
					"27005006": {
						"region_id": "27005006",
						"region_name": "申扎县"
					},
					"27005007": {
						"region_id": "27005007",
						"region_name": "索县"
					},
					"27005008": {
						"region_id": "27005008",
						"region_name": "班戈县"
					},
					"27005009": {
						"region_id": "27005009",
						"region_name": "巴青县"
					},
					"27005010": {
						"region_id": "27005010",
						"region_name": "尼玛县"
					}
				}
			},
			"27006": {
				"region_id": "27006",
				"region_name": "阿里地区",
				"child": {
					"27006001": {
						"region_id": "27006001",
						"region_name": "普兰县"
					},
					"27006002": {
						"region_id": "27006002",
						"region_name": "札达县"
					},
					"27006003": {
						"region_id": "27006003",
						"region_name": "噶尔县"
					},
					"27006004": {
						"region_id": "27006004",
						"region_name": "日土县"
					},
					"27006005": {
						"region_id": "27006005",
						"region_name": "革吉县"
					},
					"27006006": {
						"region_id": "27006006",
						"region_name": "改则县"
					},
					"27006007": {
						"region_id": "27006007",
						"region_name": "措勤县"
					}
				}
			},
			"27007": {
				"region_id": "27007",
				"region_name": "林芝地区",
				"child": {
					"27007001": {
						"region_id": "27007001",
						"region_name": "林芝县"
					},
					"27007002": {
						"region_id": "27007002",
						"region_name": "工布江达县"
					},
					"27007003": {
						"region_id": "27007003",
						"region_name": "米林县"
					},
					"27007004": {
						"region_id": "27007004",
						"region_name": "墨脱县"
					},
					"27007005": {
						"region_id": "27007005",
						"region_name": "波密县"
					},
					"27007006": {
						"region_id": "27007006",
						"region_name": "察隅县"
					},
					"27007007": {
						"region_id": "27007007",
						"region_name": "朗县"
					}
				}
			}
		}
	},
	"28": {
		"region_id": "28",
		"region_name": "陕西省",
		"child": {
			"28001": {
				"region_id": "28001",
				"region_name": "西安市",
				"child": {
					"28001001": {
						"region_id": "28001001",
						"region_name": "新城区"
					},
					"28001002": {
						"region_id": "28001002",
						"region_name": "碑林区"
					},
					"28001003": {
						"region_id": "28001003",
						"region_name": "莲湖区"
					},
					"28001004": {
						"region_id": "28001004",
						"region_name": "灞桥区"
					},
					"28001005": {
						"region_id": "28001005",
						"region_name": "未央区"
					},
					"28001006": {
						"region_id": "28001006",
						"region_name": "雁塔区"
					},
					"28001007": {
						"region_id": "28001007",
						"region_name": "阎良区"
					},
					"28001008": {
						"region_id": "28001008",
						"region_name": "临潼区"
					},
					"28001009": {
						"region_id": "28001009",
						"region_name": "长安县"
					},
					"28001010": {
						"region_id": "28001010",
						"region_name": "蓝田县"
					},
					"28001011": {
						"region_id": "28001011",
						"region_name": "周至县"
					},
					"28001012": {
						"region_id": "28001012",
						"region_name": "户县"
					},
					"28001013": {
						"region_id": "28001013",
						"region_name": "高陵县"
					}
				}
			},
			"28002": {
				"region_id": "28002",
				"region_name": "铜川市",
				"child": {
					"28002001": {
						"region_id": "28002001",
						"region_name": "城区"
					},
					"28002002": {
						"region_id": "28002002",
						"region_name": "郊区"
					},
					"28002003": {
						"region_id": "28002003",
						"region_name": "耀县"
					},
					"28002004": {
						"region_id": "28002004",
						"region_name": "宜君县"
					}
				}
			},
			"28003": {
				"region_id": "28003",
				"region_name": "宝鸡市",
				"child": {
					"28003001": {
						"region_id": "28003001",
						"region_name": "渭滨区"
					},
					"28003002": {
						"region_id": "28003002",
						"region_name": "金台区"
					},
					"28003003": {
						"region_id": "28003003",
						"region_name": "宝鸡县"
					},
					"28003004": {
						"region_id": "28003004",
						"region_name": "凤翔县"
					},
					"28003005": {
						"region_id": "28003005",
						"region_name": "岐山县"
					},
					"28003006": {
						"region_id": "28003006",
						"region_name": "扶风县"
					},
					"28003007": {
						"region_id": "28003007",
						"region_name": "眉县"
					},
					"28003008": {
						"region_id": "28003008",
						"region_name": "陇县"
					},
					"28003009": {
						"region_id": "28003009",
						"region_name": "千阳县"
					},
					"28003010": {
						"region_id": "28003010",
						"region_name": "麟游县"
					},
					"28003011": {
						"region_id": "28003011",
						"region_name": "凤县"
					},
					"28003012": {
						"region_id": "28003012",
						"region_name": "太白县"
					}
				}
			},
			"28004": {
				"region_id": "28004",
				"region_name": "咸阳市",
				"child": {
					"28004001": {
						"region_id": "28004001",
						"region_name": "秦都区"
					},
					"28004002": {
						"region_id": "28004002",
						"region_name": "杨陵区"
					},
					"28004003": {
						"region_id": "28004003",
						"region_name": "渭城区"
					},
					"28004004": {
						"region_id": "28004004",
						"region_name": "三原县"
					},
					"28004005": {
						"region_id": "28004005",
						"region_name": "泾阳县"
					},
					"28004006": {
						"region_id": "28004006",
						"region_name": "乾县"
					},
					"28004007": {
						"region_id": "28004007",
						"region_name": "礼泉县"
					},
					"28004008": {
						"region_id": "28004008",
						"region_name": "永寿县"
					},
					"28004009": {
						"region_id": "28004009",
						"region_name": "彬县"
					},
					"28004010": {
						"region_id": "28004010",
						"region_name": "长武县"
					},
					"28004011": {
						"region_id": "28004011",
						"region_name": "旬邑县"
					},
					"28004012": {
						"region_id": "28004012",
						"region_name": "淳化县"
					},
					"28004013": {
						"region_id": "28004013",
						"region_name": "武功县"
					},
					"28004014": {
						"region_id": "28004014",
						"region_name": "兴平市"
					}
				}
			},
			"28005": {
				"region_id": "28005",
				"region_name": "渭南市",
				"child": {
					"28005001": {
						"region_id": "28005001",
						"region_name": "临渭区"
					},
					"28005002": {
						"region_id": "28005002",
						"region_name": "华县"
					},
					"28005003": {
						"region_id": "28005003",
						"region_name": "潼关县"
					},
					"28005004": {
						"region_id": "28005004",
						"region_name": "大荔县"
					},
					"28005005": {
						"region_id": "28005005",
						"region_name": "合阳县"
					},
					"28005006": {
						"region_id": "28005006",
						"region_name": "澄城县"
					},
					"28005007": {
						"region_id": "28005007",
						"region_name": "蒲城县"
					},
					"28005008": {
						"region_id": "28005008",
						"region_name": "白水县"
					},
					"28005009": {
						"region_id": "28005009",
						"region_name": "富平县"
					},
					"28005010": {
						"region_id": "28005010",
						"region_name": "韩城市"
					},
					"28005011": {
						"region_id": "28005011",
						"region_name": "华阴市"
					}
				}
			},
			"28006": {
				"region_id": "28006",
				"region_name": "延安市",
				"child": {
					"28006001": {
						"region_id": "28006001",
						"region_name": "宝塔区"
					},
					"28006002": {
						"region_id": "28006002",
						"region_name": "延长县"
					},
					"28006003": {
						"region_id": "28006003",
						"region_name": "延川县"
					},
					"28006004": {
						"region_id": "28006004",
						"region_name": "子长县"
					},
					"28006005": {
						"region_id": "28006005",
						"region_name": "安塞县"
					},
					"28006006": {
						"region_id": "28006006",
						"region_name": "志丹县"
					},
					"28006007": {
						"region_id": "28006007",
						"region_name": "吴旗县"
					},
					"28006008": {
						"region_id": "28006008",
						"region_name": "甘泉县"
					},
					"28006009": {
						"region_id": "28006009",
						"region_name": "富县"
					},
					"28006010": {
						"region_id": "28006010",
						"region_name": "洛川县"
					},
					"28006011": {
						"region_id": "28006011",
						"region_name": "宜川县"
					},
					"28006012": {
						"region_id": "28006012",
						"region_name": "黄龙县"
					},
					"28006013": {
						"region_id": "28006013",
						"region_name": "黄陵县"
					}
				}
			},
			"28007": {
				"region_id": "28007",
				"region_name": "汉中市",
				"child": {
					"28007001": {
						"region_id": "28007001",
						"region_name": "汉台区"
					},
					"28007002": {
						"region_id": "28007002",
						"region_name": "南郑县"
					},
					"28007003": {
						"region_id": "28007003",
						"region_name": "城固县"
					},
					"28007004": {
						"region_id": "28007004",
						"region_name": "洋县"
					},
					"28007005": {
						"region_id": "28007005",
						"region_name": "西乡县"
					},
					"28007006": {
						"region_id": "28007006",
						"region_name": "勉县"
					},
					"28007007": {
						"region_id": "28007007",
						"region_name": "宁强县"
					},
					"28007008": {
						"region_id": "28007008",
						"region_name": "略阳县"
					},
					"28007009": {
						"region_id": "28007009",
						"region_name": "镇巴县"
					},
					"28007010": {
						"region_id": "28007010",
						"region_name": "留坝县"
					},
					"28007011": {
						"region_id": "28007011",
						"region_name": "佛坪县"
					}
				}
			},
			"28008": {
				"region_id": "28008",
				"region_name": "安康地区",
				"child": {
					"28008001": {
						"region_id": "28008001",
						"region_name": "安康市"
					},
					"28008002": {
						"region_id": "28008002",
						"region_name": "汉阴县"
					},
					"28008003": {
						"region_id": "28008003",
						"region_name": "石泉县"
					},
					"28008004": {
						"region_id": "28008004",
						"region_name": "宁陕县"
					},
					"28008005": {
						"region_id": "28008005",
						"region_name": "紫阳县"
					},
					"28008006": {
						"region_id": "28008006",
						"region_name": "岚皋县"
					},
					"28008007": {
						"region_id": "28008007",
						"region_name": "平利县"
					},
					"28008008": {
						"region_id": "28008008",
						"region_name": "镇坪县"
					},
					"28008009": {
						"region_id": "28008009",
						"region_name": "旬阳县"
					},
					"28008010": {
						"region_id": "28008010",
						"region_name": "白河县"
					}
				}
			},
			"28009": {
				"region_id": "28009",
				"region_name": "商洛地区",
				"child": {
					"28009001": {
						"region_id": "28009001",
						"region_name": "商州市"
					},
					"28009002": {
						"region_id": "28009002",
						"region_name": "洛南县"
					},
					"28009003": {
						"region_id": "28009003",
						"region_name": "丹凤县"
					},
					"28009004": {
						"region_id": "28009004",
						"region_name": "商南县"
					},
					"28009005": {
						"region_id": "28009005",
						"region_name": "山阳县"
					},
					"28009006": {
						"region_id": "28009006",
						"region_name": "镇安县"
					},
					"28009007": {
						"region_id": "28009007",
						"region_name": "柞水县"
					}
				}
			},
			"28010": {
				"region_id": "28010",
				"region_name": "榆林地区",
				"child": {
					"28010001": {
						"region_id": "28010001",
						"region_name": "榆林市"
					},
					"28010002": {
						"region_id": "28010002",
						"region_name": "神木县"
					},
					"28010003": {
						"region_id": "28010003",
						"region_name": "府谷县"
					},
					"28010004": {
						"region_id": "28010004",
						"region_name": "横山县"
					},
					"28010005": {
						"region_id": "28010005",
						"region_name": "靖边县"
					},
					"28010006": {
						"region_id": "28010006",
						"region_name": "定边县"
					},
					"28010007": {
						"region_id": "28010007",
						"region_name": "绥德县"
					},
					"28010008": {
						"region_id": "28010008",
						"region_name": "米脂县"
					},
					"28010009": {
						"region_id": "28010009",
						"region_name": "佳县"
					},
					"28010010": {
						"region_id": "28010010",
						"region_name": "吴堡县"
					},
					"28010011": {
						"region_id": "28010011",
						"region_name": "清涧县"
					},
					"28010012": {
						"region_id": "28010012",
						"region_name": "子洲县"
					}
				}
			}
		}
	},
	"29": {
		"region_id": "29",
		"region_name": "甘肃省",
		"child": {
			"29001": {
				"region_id": "29001",
				"region_name": "兰州市",
				"child": {
					"29001001": {
						"region_id": "29001001",
						"region_name": "城关区"
					},
					"29001002": {
						"region_id": "29001002",
						"region_name": "七里河区"
					},
					"29001003": {
						"region_id": "29001003",
						"region_name": "西固区"
					},
					"29001004": {
						"region_id": "29001004",
						"region_name": "安宁区"
					},
					"29001005": {
						"region_id": "29001005",
						"region_name": "红古区"
					},
					"29001006": {
						"region_id": "29001006",
						"region_name": "永登县"
					},
					"29001007": {
						"region_id": "29001007",
						"region_name": "皋兰县"
					},
					"29001008": {
						"region_id": "29001008",
						"region_name": "榆中县"
					}
				}
			},
			"29002": {
				"region_id": "29002",
				"region_name": "嘉峪关市",
				"child": {
					"29002001": {
						"region_id": "29002001",
						"region_name": "市辖区"
					}
				}
			},
			"29003": {
				"region_id": "29003",
				"region_name": "金昌市",
				"child": {
					"29003001": {
						"region_id": "29003001",
						"region_name": "金川区"
					},
					"29003002": {
						"region_id": "29003002",
						"region_name": "永昌县"
					}
				}
			},
			"29004": {
				"region_id": "29004",
				"region_name": "白银市",
				"child": {
					"29004001": {
						"region_id": "29004001",
						"region_name": "白银区"
					},
					"29004002": {
						"region_id": "29004002",
						"region_name": "平川区"
					},
					"29004003": {
						"region_id": "29004003",
						"region_name": "靖远县"
					},
					"29004004": {
						"region_id": "29004004",
						"region_name": "会宁县"
					},
					"29004005": {
						"region_id": "29004005",
						"region_name": "景泰县"
					}
				}
			},
			"29005": {
				"region_id": "29005",
				"region_name": "天水市",
				"child": {
					"29005001": {
						"region_id": "29005001",
						"region_name": "秦城区"
					},
					"29005002": {
						"region_id": "29005002",
						"region_name": "北道区"
					},
					"29005003": {
						"region_id": "29005003",
						"region_name": "清水县"
					},
					"29005004": {
						"region_id": "29005004",
						"region_name": "秦安县"
					},
					"29005005": {
						"region_id": "29005005",
						"region_name": "甘谷县"
					},
					"29005006": {
						"region_id": "29005006",
						"region_name": "武山县"
					},
					"29005007": {
						"region_id": "29005007",
						"region_name": "张家川回族自治县"
					}
				}
			},
			"29006": {
				"region_id": "29006",
				"region_name": "酒泉地区",
				"child": {
					"29006001": {
						"region_id": "29006001",
						"region_name": "玉门市"
					},
					"29006002": {
						"region_id": "29006002",
						"region_name": "酒泉市"
					},
					"29006003": {
						"region_id": "29006003",
						"region_name": "敦煌市"
					},
					"29006004": {
						"region_id": "29006004",
						"region_name": "金塔县"
					},
					"29006005": {
						"region_id": "29006005",
						"region_name": "肃北蒙古族自治县"
					},
					"29006006": {
						"region_id": "29006006",
						"region_name": "阿克塞哈萨克族自治县"
					},
					"29006007": {
						"region_id": "29006007",
						"region_name": "安西县"
					}
				}
			},
			"29007": {
				"region_id": "29007",
				"region_name": "张掖地区",
				"child": {
					"29007001": {
						"region_id": "29007001",
						"region_name": "张掖市"
					},
					"29007002": {
						"region_id": "29007002",
						"region_name": "肃南裕固族自治县"
					},
					"29007003": {
						"region_id": "29007003",
						"region_name": "民乐县"
					},
					"29007004": {
						"region_id": "29007004",
						"region_name": "临泽县"
					},
					"29007005": {
						"region_id": "29007005",
						"region_name": "高台县"
					},
					"29007006": {
						"region_id": "29007006",
						"region_name": "山丹县"
					}
				}
			},
			"29008": {
				"region_id": "29008",
				"region_name": "武威地区",
				"child": {
					"29008001": {
						"region_id": "29008001",
						"region_name": "武威市"
					},
					"29008002": {
						"region_id": "29008002",
						"region_name": "民勤县"
					},
					"29008003": {
						"region_id": "29008003",
						"region_name": "古浪县"
					},
					"29008004": {
						"region_id": "29008004",
						"region_name": "天祝藏族自治县"
					}
				}
			},
			"29009": {
				"region_id": "29009",
				"region_name": "定西地区",
				"child": {
					"29009001": {
						"region_id": "29009001",
						"region_name": "定西县"
					},
					"29009002": {
						"region_id": "29009002",
						"region_name": "通渭县"
					},
					"29009003": {
						"region_id": "29009003",
						"region_name": "陇西县"
					},
					"29009004": {
						"region_id": "29009004",
						"region_name": "渭源县"
					},
					"29009005": {
						"region_id": "29009005",
						"region_name": "临洮县"
					},
					"29009006": {
						"region_id": "29009006",
						"region_name": "漳县"
					},
					"29009007": {
						"region_id": "29009007",
						"region_name": "岷县"
					}
				}
			},
			"29010": {
				"region_id": "29010",
				"region_name": "陇南地区",
				"child": {
					"29010001": {
						"region_id": "29010001",
						"region_name": "武都县"
					},
					"29010002": {
						"region_id": "29010002",
						"region_name": "宕昌县"
					},
					"29010003": {
						"region_id": "29010003",
						"region_name": "成县"
					},
					"29010004": {
						"region_id": "29010004",
						"region_name": "康县"
					},
					"29010005": {
						"region_id": "29010005",
						"region_name": "文县"
					},
					"29010006": {
						"region_id": "29010006",
						"region_name": "西和县"
					},
					"29010007": {
						"region_id": "29010007",
						"region_name": "礼县"
					},
					"29010008": {
						"region_id": "29010008",
						"region_name": "两当县"
					},
					"29010009": {
						"region_id": "29010009",
						"region_name": "徽县"
					}
				}
			},
			"29011": {
				"region_id": "29011",
				"region_name": "平凉地区",
				"child": {
					"29011001": {
						"region_id": "29011001",
						"region_name": "平凉市"
					},
					"29011002": {
						"region_id": "29011002",
						"region_name": "泾川县"
					},
					"29011003": {
						"region_id": "29011003",
						"region_name": "灵台县"
					},
					"29011004": {
						"region_id": "29011004",
						"region_name": "崇信县"
					},
					"29011005": {
						"region_id": "29011005",
						"region_name": "华亭县"
					},
					"29011006": {
						"region_id": "29011006",
						"region_name": "庄浪县"
					},
					"29011007": {
						"region_id": "29011007",
						"region_name": "静宁县"
					}
				}
			},
			"29012": {
				"region_id": "29012",
				"region_name": "庆阳地区",
				"child": {
					"29012001": {
						"region_id": "29012001",
						"region_name": "西峰市"
					},
					"29012002": {
						"region_id": "29012002",
						"region_name": "庆阳县"
					},
					"29012003": {
						"region_id": "29012003",
						"region_name": "环县"
					},
					"29012004": {
						"region_id": "29012004",
						"region_name": "华池县"
					},
					"29012005": {
						"region_id": "29012005",
						"region_name": "合水县"
					},
					"29012006": {
						"region_id": "29012006",
						"region_name": "正宁县"
					},
					"29012007": {
						"region_id": "29012007",
						"region_name": "宁县"
					},
					"29012008": {
						"region_id": "29012008",
						"region_name": "镇原县"
					}
				}
			},
			"29013": {
				"region_id": "29013",
				"region_name": "临夏回族自治州",
				"child": {
					"29013001": {
						"region_id": "29013001",
						"region_name": "临夏市"
					},
					"29013002": {
						"region_id": "29013002",
						"region_name": "临夏县"
					},
					"29013003": {
						"region_id": "29013003",
						"region_name": "康乐县"
					},
					"29013004": {
						"region_id": "29013004",
						"region_name": "永靖县"
					},
					"29013005": {
						"region_id": "29013005",
						"region_name": "广河县"
					},
					"29013006": {
						"region_id": "29013006",
						"region_name": "和政县"
					},
					"29013007": {
						"region_id": "29013007",
						"region_name": "东乡族自治县"
					},
					"29013008": {
						"region_id": "29013008",
						"region_name": "积石山保安族东乡族撒拉族自治县"
					}
				}
			},
			"29014": {
				"region_id": "29014",
				"region_name": "甘南藏族自治州",
				"child": {
					"29014001": {
						"region_id": "29014001",
						"region_name": "合作市"
					},
					"29014002": {
						"region_id": "29014002",
						"region_name": "临潭县"
					},
					"29014003": {
						"region_id": "29014003",
						"region_name": "卓尼县"
					},
					"29014004": {
						"region_id": "29014004",
						"region_name": "舟曲县"
					},
					"29014005": {
						"region_id": "29014005",
						"region_name": "迭部县"
					},
					"29014006": {
						"region_id": "29014006",
						"region_name": "玛曲县"
					},
					"29014007": {
						"region_id": "29014007",
						"region_name": "碌曲县"
					},
					"29014008": {
						"region_id": "29014008",
						"region_name": "夏河县"
					}
				}
			}
		}
	},
	"30": {
		"region_id": "30",
		"region_name": "青海省",
		"child": {
			"30001": {
				"region_id": "30001",
				"region_name": "西宁市",
				"child": {
					"30001001": {
						"region_id": "30001001",
						"region_name": "城东区"
					},
					"30001002": {
						"region_id": "30001002",
						"region_name": "城中区"
					},
					"30001003": {
						"region_id": "30001003",
						"region_name": "城西区"
					},
					"30001004": {
						"region_id": "30001004",
						"region_name": "城北区"
					},
					"30001005": {
						"region_id": "30001005",
						"region_name": "大通回族土族自治县"
					}
				}
			},
			"30002": {
				"region_id": "30002",
				"region_name": "海东地区",
				"child": {
					"30002001": {
						"region_id": "30002001",
						"region_name": "平安县"
					},
					"30002002": {
						"region_id": "30002002",
						"region_name": "民和回族土族自治县"
					},
					"30002003": {
						"region_id": "30002003",
						"region_name": "乐都县"
					},
					"30002004": {
						"region_id": "30002004",
						"region_name": "湟中县"
					},
					"30002005": {
						"region_id": "30002005",
						"region_name": "湟源县"
					},
					"30002006": {
						"region_id": "30002006",
						"region_name": "互助土族自治县"
					},
					"30002007": {
						"region_id": "30002007",
						"region_name": "化隆回族自治县"
					},
					"30002008": {
						"region_id": "30002008",
						"region_name": "循化撒拉族自治县"
					}
				}
			},
			"30003": {
				"region_id": "30003",
				"region_name": "海北藏族自治州",
				"child": {
					"30003001": {
						"region_id": "30003001",
						"region_name": "门源回族自治县"
					},
					"30003002": {
						"region_id": "30003002",
						"region_name": "祁连县"
					},
					"30003003": {
						"region_id": "30003003",
						"region_name": "海晏县"
					},
					"30003004": {
						"region_id": "30003004",
						"region_name": "刚察县"
					}
				}
			},
			"30004": {
				"region_id": "30004",
				"region_name": "黄南藏族自治州",
				"child": {
					"30004001": {
						"region_id": "30004001",
						"region_name": "同仁县"
					},
					"30004002": {
						"region_id": "30004002",
						"region_name": "尖扎县"
					},
					"30004003": {
						"region_id": "30004003",
						"region_name": "泽库县"
					},
					"30004004": {
						"region_id": "30004004",
						"region_name": "河南蒙古族自治县"
					}
				}
			},
			"30005": {
				"region_id": "30005",
				"region_name": "海南藏族自治州",
				"child": {
					"30005001": {
						"region_id": "30005001",
						"region_name": "共和县"
					},
					"30005002": {
						"region_id": "30005002",
						"region_name": "同德县"
					},
					"30005003": {
						"region_id": "30005003",
						"region_name": "贵德县"
					},
					"30005004": {
						"region_id": "30005004",
						"region_name": "兴海县"
					},
					"30005005": {
						"region_id": "30005005",
						"region_name": "贵南县"
					}
				}
			},
			"30006": {
				"region_id": "30006",
				"region_name": "果洛藏族自治州",
				"child": {
					"30006001": {
						"region_id": "30006001",
						"region_name": "玛沁县"
					},
					"30006002": {
						"region_id": "30006002",
						"region_name": "班玛县"
					},
					"30006003": {
						"region_id": "30006003",
						"region_name": "甘德县"
					},
					"30006004": {
						"region_id": "30006004",
						"region_name": "达日县"
					},
					"30006005": {
						"region_id": "30006005",
						"region_name": "久治县"
					},
					"30006006": {
						"region_id": "30006006",
						"region_name": "玛多县"
					}
				}
			},
			"30007": {
				"region_id": "30007",
				"region_name": "玉树藏族自治州",
				"child": {
					"30007001": {
						"region_id": "30007001",
						"region_name": "玉树县"
					},
					"30007002": {
						"region_id": "30007002",
						"region_name": "杂多县"
					},
					"30007003": {
						"region_id": "30007003",
						"region_name": "称多县"
					},
					"30007004": {
						"region_id": "30007004",
						"region_name": "治多县"
					},
					"30007005": {
						"region_id": "30007005",
						"region_name": "囊谦县"
					},
					"30007006": {
						"region_id": "30007006",
						"region_name": "曲麻莱县"
					}
				}
			},
			"30008": {
				"region_id": "30008",
				"region_name": "海西蒙古族藏族自治州",
				"child": {
					"30008001": {
						"region_id": "30008001",
						"region_name": "格尔木市"
					},
					"30008002": {
						"region_id": "30008002",
						"region_name": "德令哈市"
					},
					"30008003": {
						"region_id": "30008003",
						"region_name": "乌兰县"
					},
					"30008004": {
						"region_id": "30008004",
						"region_name": "都兰县"
					},
					"30008005": {
						"region_id": "30008005",
						"region_name": "天峻县"
					}
				}
			}
		}
	},
	"31": {
		"region_id": "31",
		"region_name": "宁夏省",
		"child": {
			"31001": {
				"region_id": "31001",
				"region_name": "银川市",
				"child": {
					"31001001": {
						"region_id": "31001001",
						"region_name": "兴庆区"
					},
					"31001002": {
						"region_id": "31001002",
						"region_name": "西夏区"
					},
					"31001003": {
						"region_id": "31001003",
						"region_name": "金凤区"
					},
					"31001004": {
						"region_id": "31001004",
						"region_name": "永宁县"
					},
					"31001005": {
						"region_id": "31001005",
						"region_name": "贺兰县"
					},
					"31001006": {
						"region_id": "31001006",
						"region_name": "灵武市"
					}
				}
			},
			"31002": {
				"region_id": "31002",
				"region_name": "石嘴山市",
				"child": {
					"31002001": {
						"region_id": "31002001",
						"region_name": "大武口区"
					},
					"31002002": {
						"region_id": "31002002",
						"region_name": "惠农区"
					},
					"31002003": {
						"region_id": "31002003",
						"region_name": "平罗县"
					}
				}
			},
			"31003": {
				"region_id": "31003",
				"region_name": "吴忠市",
				"child": {
					"31003001": {
						"region_id": "31003001",
						"region_name": "利通区"
					},
					"31003002": {
						"region_id": "31003002",
						"region_name": "　红寺堡区"
					},
					"31003003": {
						"region_id": "31003003",
						"region_name": "盐池县"
					},
					"31003004": {
						"region_id": "31003004",
						"region_name": "同心县"
					},
					"31003005": {
						"region_id": "31003005",
						"region_name": "青铜峡市"
					}
				}
			},
			"31004": {
				"region_id": "31004",
				"region_name": "固原地区",
				"child": {
					"31004001": {
						"region_id": "31004001",
						"region_name": "原州区"
					},
					"31004002": {
						"region_id": "31004002",
						"region_name": "西吉县"
					},
					"31004003": {
						"region_id": "31004003",
						"region_name": "隆德县"
					},
					"31004004": {
						"region_id": "31004004",
						"region_name": "泾源县"
					},
					"31004005": {
						"region_id": "31004005",
						"region_name": "彭阳县"
					}
				}
			},
			"31005": {
				"region_id": "31005",
				"region_name": "中卫市",
				"child": {
					"31005001": {
						"region_id": "31005001",
						"region_name": "沙坡头区"
					},
					"31005002": {
						"region_id": "31005002",
						"region_name": "中宁县"
					},
					"31005003": {
						"region_id": "31005003",
						"region_name": "海原县"
					}
				}
			}
		}
	},
	"32": {
		"region_id": "32",
		"region_name": "新疆省",
		"child": {
			"32001": {
				"region_id": "32001",
				"region_name": "乌鲁木齐市",
				"child": {
					"32001001": {
						"region_id": "32001001",
						"region_name": "天山区"
					},
					"32001002": {
						"region_id": "32001002",
						"region_name": "沙依巴克区"
					},
					"32001003": {
						"region_id": "32001003",
						"region_name": "新市区"
					},
					"32001004": {
						"region_id": "32001004",
						"region_name": "水磨沟区"
					},
					"32001005": {
						"region_id": "32001005",
						"region_name": "头屯河区"
					},
					"32001006": {
						"region_id": "32001006",
						"region_name": "南山矿区"
					},
					"32001007": {
						"region_id": "32001007",
						"region_name": "东山区"
					},
					"32001008": {
						"region_id": "32001008",
						"region_name": "乌鲁木齐县"
					}
				}
			},
			"32002": {
				"region_id": "32002",
				"region_name": "克拉玛依市",
				"child": {
					"32002001": {
						"region_id": "32002001",
						"region_name": "独山子区"
					},
					"32002002": {
						"region_id": "32002002",
						"region_name": "克拉玛依区"
					},
					"32002003": {
						"region_id": "32002003",
						"region_name": "白碱滩区"
					},
					"32002004": {
						"region_id": "32002004",
						"region_name": "乌尔禾区"
					}
				}
			},
			"32003": {
				"region_id": "32003",
				"region_name": "吐鲁番地区",
				"child": {
					"32003001": {
						"region_id": "32003001",
						"region_name": "吐鲁番市"
					},
					"32003002": {
						"region_id": "32003002",
						"region_name": "鄯善县"
					},
					"32003003": {
						"region_id": "32003003",
						"region_name": "托克逊县"
					}
				}
			},
			"32004": {
				"region_id": "32004",
				"region_name": "哈密地区",
				"child": {
					"32004001": {
						"region_id": "32004001",
						"region_name": "哈密市"
					},
					"32004002": {
						"region_id": "32004002",
						"region_name": "巴里坤哈萨克自治县"
					},
					"32004003": {
						"region_id": "32004003",
						"region_name": "伊吾县"
					}
				}
			},
			"32005": {
				"region_id": "32005",
				"region_name": "昌吉回族自治州",
				"child": {
					"32005001": {
						"region_id": "32005001",
						"region_name": "昌吉市"
					},
					"32005002": {
						"region_id": "32005002",
						"region_name": "阜康市"
					},
					"32005003": {
						"region_id": "32005003",
						"region_name": "呼图壁县"
					},
					"32005004": {
						"region_id": "32005004",
						"region_name": "玛纳斯县"
					},
					"32005005": {
						"region_id": "32005005",
						"region_name": "奇台县"
					},
					"32005006": {
						"region_id": "32005006",
						"region_name": "吉木萨尔县"
					},
					"32005007": {
						"region_id": "32005007",
						"region_name": "木垒哈萨克自治县"
					}
				}
			},
			"32006": {
				"region_id": "32006",
				"region_name": "博尔塔拉蒙古自治州",
				"child": {
					"32006001": {
						"region_id": "32006001",
						"region_name": "博乐市"
					},
					"32006002": {
						"region_id": "32006002",
						"region_name": "精河县"
					},
					"32006003": {
						"region_id": "32006003",
						"region_name": "温泉县"
					}
				}
			},
			"32007": {
				"region_id": "32007",
				"region_name": "巴音郭楞蒙古自治州",
				"child": {
					"32007001": {
						"region_id": "32007001",
						"region_name": "库尔勒市"
					},
					"32007002": {
						"region_id": "32007002",
						"region_name": "轮台县"
					},
					"32007003": {
						"region_id": "32007003",
						"region_name": "尉犁县"
					},
					"32007004": {
						"region_id": "32007004",
						"region_name": "若羌县"
					},
					"32007005": {
						"region_id": "32007005",
						"region_name": "且末县"
					},
					"32007006": {
						"region_id": "32007006",
						"region_name": "焉耆回族自治县"
					},
					"32007007": {
						"region_id": "32007007",
						"region_name": "和静县"
					},
					"32007008": {
						"region_id": "32007008",
						"region_name": "和硕县"
					},
					"32007009": {
						"region_id": "32007009",
						"region_name": "博湖县"
					}
				}
			},
			"32008": {
				"region_id": "32008",
				"region_name": "阿克苏地区",
				"child": {
					"32008001": {
						"region_id": "32008001",
						"region_name": "阿克苏市"
					},
					"32008002": {
						"region_id": "32008002",
						"region_name": "温宿县"
					},
					"32008003": {
						"region_id": "32008003",
						"region_name": "库车县"
					},
					"32008004": {
						"region_id": "32008004",
						"region_name": "沙雅县"
					},
					"32008005": {
						"region_id": "32008005",
						"region_name": "新和县"
					},
					"32008006": {
						"region_id": "32008006",
						"region_name": "拜城县"
					},
					"32008007": {
						"region_id": "32008007",
						"region_name": "乌什县"
					},
					"32008008": {
						"region_id": "32008008",
						"region_name": "阿瓦提县"
					},
					"32008009": {
						"region_id": "32008009",
						"region_name": "柯坪县"
					}
				}
			},
			"32009": {
				"region_id": "32009",
				"region_name": "克孜勒苏柯尔克孜自治州",
				"child": {
					"32009001": {
						"region_id": "32009001",
						"region_name": "阿图什市"
					},
					"32009002": {
						"region_id": "32009002",
						"region_name": "阿克陶县"
					},
					"32009003": {
						"region_id": "32009003",
						"region_name": "阿合奇县"
					},
					"32009004": {
						"region_id": "32009004",
						"region_name": "乌恰县"
					}
				}
			},
			"32010": {
				"region_id": "32010",
				"region_name": "喀什地区",
				"child": {
					"32010001": {
						"region_id": "32010001",
						"region_name": "喀什市"
					},
					"32010002": {
						"region_id": "32010002",
						"region_name": "疏附县"
					},
					"32010003": {
						"region_id": "32010003",
						"region_name": "疏勒县"
					},
					"32010004": {
						"region_id": "32010004",
						"region_name": "英吉沙县"
					},
					"32010005": {
						"region_id": "32010005",
						"region_name": "泽普县"
					},
					"32010006": {
						"region_id": "32010006",
						"region_name": "莎车县"
					},
					"32010007": {
						"region_id": "32010007",
						"region_name": "叶城县"
					},
					"32010008": {
						"region_id": "32010008",
						"region_name": "麦盖提县"
					},
					"32010009": {
						"region_id": "32010009",
						"region_name": "岳普湖县"
					},
					"32010010": {
						"region_id": "32010010",
						"region_name": "伽师县"
					},
					"32010011": {
						"region_id": "32010011",
						"region_name": "巴楚县"
					},
					"32010012": {
						"region_id": "32010012",
						"region_name": "塔什库尔干塔吉克自治县"
					}
				}
			},
			"32011": {
				"region_id": "32011",
				"region_name": "和田地区",
				"child": {
					"32011001": {
						"region_id": "32011001",
						"region_name": "和田市"
					},
					"32011002": {
						"region_id": "32011002",
						"region_name": "和田县"
					},
					"32011003": {
						"region_id": "32011003",
						"region_name": "墨玉县"
					},
					"32011004": {
						"region_id": "32011004",
						"region_name": "皮山县"
					},
					"32011005": {
						"region_id": "32011005",
						"region_name": "洛浦县"
					},
					"32011006": {
						"region_id": "32011006",
						"region_name": "策勒县"
					},
					"32011007": {
						"region_id": "32011007",
						"region_name": "于田县"
					},
					"32011008": {
						"region_id": "32011008",
						"region_name": "民丰县"
					}
				}
			},
			"32012": {
				"region_id": "32012",
				"region_name": "伊犁哈萨克自治州",
				"child": {
					"32012001": {
						"region_id": "32012001",
						"region_name": "奎屯市"
					},
					"32012002": {
						"region_id": "32012002",
						"region_name": "伊犁地区"
					},
					"32012003": {
						"region_id": "32012003",
						"region_name": "伊宁市"
					},
					"32012004": {
						"region_id": "32012004",
						"region_name": "伊宁县"
					},
					"32012005": {
						"region_id": "32012005",
						"region_name": "察布查尔锡伯自治县"
					},
					"32012006": {
						"region_id": "32012006",
						"region_name": "霍城县"
					},
					"32012007": {
						"region_id": "32012007",
						"region_name": "巩留县"
					},
					"32012008": {
						"region_id": "32012008",
						"region_name": "新源县"
					},
					"32012009": {
						"region_id": "32012009",
						"region_name": "昭苏县"
					},
					"32012010": {
						"region_id": "32012010",
						"region_name": "特克斯县"
					},
					"32012011": {
						"region_id": "32012011",
						"region_name": "尼勒克县"
					}
				}
			},
			"32013": {
				"region_id": "32013",
				"region_name": "塔城地区",
				"child": {
					"32013001": {
						"region_id": "32013001",
						"region_name": "塔城市"
					},
					"32013002": {
						"region_id": "32013002",
						"region_name": "乌苏市"
					},
					"32013003": {
						"region_id": "32013003",
						"region_name": "额敏县"
					},
					"32013004": {
						"region_id": "32013004",
						"region_name": "沙湾县"
					},
					"32013005": {
						"region_id": "32013005",
						"region_name": "托里县"
					},
					"32013006": {
						"region_id": "32013006",
						"region_name": "裕民县"
					},
					"32013007": {
						"region_id": "32013007",
						"region_name": "和布克赛尔蒙古自治县"
					}
				}
			},
			"32014": {
				"region_id": "32014",
				"region_name": "阿勒泰地区",
				"child": {
					"32014001": {
						"region_id": "32014001",
						"region_name": "阿勒泰市"
					},
					"32014002": {
						"region_id": "32014002",
						"region_name": "布尔津县"
					},
					"32014003": {
						"region_id": "32014003",
						"region_name": "富蕴县"
					},
					"32014004": {
						"region_id": "32014004",
						"region_name": "福海县"
					},
					"32014005": {
						"region_id": "32014005",
						"region_name": "哈巴河县"
					},
					"32014006": {
						"region_id": "32014006",
						"region_name": "青河县"
					},
					"32014007": {
						"region_id": "32014007",
						"region_name": "吉木乃县"
					}
				}
			},
			"32015": {
				"region_id": "32015",
				"region_name": "省直辖行政单位",
				"child": {
					"32015001": {
						"region_id": "32015001",
						"region_name": "石河子市"
					},
					"32015002": {
						"region_id": "32015002",
						"region_name": "阿拉尔市"
					},
					"32015003": {
						"region_id": "32015003",
						"region_name": "图木舒克市"
					},
					"32015004": {
						"region_id": "32015004",
						"region_name": "五家渠市"
					}
				}
			}
		}
	}
}


export default region
